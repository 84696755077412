import styled from "styled-components";
import colors from "../../../config/colors";

export const Paragraph = styled.p`
  color: ${colors.primary};
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  padding: 1rem;
  margin: 0;
  letter-spacing: -0.012em;
  text-align: initial;
`;
