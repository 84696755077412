import React from "react";
import styled from "styled-components";

const BoldText = styled.b`
  font-family: "SimpliSans Bold";
`;

const FullTerms = () => {
  return (
    <>
      <p>
        This SimpliSafe Outdoor Monitoring Participant Agreement (the
        “Agreement”) is made as of the date of execution below (“Effective
        Date”) by and between SimpliSafe, Inc., a Delaware corporation that
        maintains a corporate office located at 100 Summer Street, Suite 300,
        Boston, MA 02110 (“SimpliSafe”, “we”, “us” or “our”) and the undersigned
        individual (“you” or “your”).
      </p>
      <p></p>
      <p>
        <BoldText>WHEREAS</BoldText>, you have been identified as a participant
        in a proof-of-concept test, which will include the testing of outdoor
        monitoring services (the “Features”). Your participation will also
        include the installation of a prototype audio enhancer device (the
        “Equipment”) for use on your existing SimpliSafe outdoor audiovisual
        cameras (“Camera” or “Cameras”). For this test, the Cameras will collect
        video that will be processed by artificial intelligence (“AI”) services,
        including facial recognition and computer vision models (“CV”), and may
        feature SimpliSafe Live Guard Outdoor Monitoring services with video
        verification, two-way audio and other verification and interactive
        processes involving human agents, which is an additional feature/service
        available exclusively through this Program and only to current
        Interactive Monitoring plan subscribers (“Monitoring Services”). These
        Features are distinctive from the indoor monitoring services to which
        you already subscribe. The test is intended to generate a variety of
        video data, AI data, and outdoor interactive monitoring video samples to
        aid SimpliSafe in developing cameras and services for future SimpliSafe
        product offerings (all of the foregoing collectively referred to as the
        “Program”);
      </p>
      <p></p>
      <p>
        <BoldText>WHEREAS</BoldText>, you acknowledge by execution of this
        Agreement that you understand the Features and/or Equipment used in this
        Program may be new product offerings which may or may not function as
        intended. You understand that SimpliSafe may need to update certain
        Features or aspects of any Features or Equipment during the Program. As
        such, the Features and prototype equipment are being offered to you with
        no representations or warranties as described further below; and
      </p>
      <p></p>
      <p>
        <BoldText>WHEREAS</BoldText>, you agree to participate in this Program
        subject to the terms of this Agreement for the time period beginning on
        the Effective Date to the date you opt-out of the Program or the date of
        SimpliSafe’s notice of termination of the Program (“Collection Period”),
        whichever is earlier. You understand that participation in this
        proof-of-concept test requires you to have been individually invited to
        participate by SimpliSafe and to have purchased a Camera(s). Merely
        receiving a copy of this Agreement is not an invitation to join the
        Program.
      </p>
      <p></p>
      <p>
        <BoldText>NOW THEREFORE</BoldText>, for good and valuable consideration,
        which includes early access to new home security Features along with
        cables, fixed camera mount and the Equipment for use with your existing
        Camera(s), the receipt and adequacy of which hereby is acknowledged, the
        parties hereto agree as follows:
      </p>
      <p></p>
      <p>
        <BoldText>1.</BoldText> <BoldText>Relocation of Cameras.</BoldText> In
        the event that you decide to move or otherwise relocate a Camera(s)
        during participation in the Program, you agree to relocate the camera(s)
        to another location on your property and nowhere else. By signing this
        Agreement, you are representing that you are authorized to make
        installation modifications to your home’s exterior as needed to
        participate in the Program and, as such, you agree to hold SimpliSafe
        harmless in any potential disputes with a co-owner, landlord, or other
        potentially interested party regarding the installations/modifications
        to your home.
      </p>
      <p></p>
      <p>
        SimpliSafe makes no representations or warranties about the installation
        or your right to re-install the Cameras. SimpliSafe disclaims any and
        all liability arising, directly or indirectly, from your removal and
        reinstallation of Camera(s).
      </p>
      <p></p>
      <p>
        <BoldText>
          If you decide to move, reposition or relocate a camera, you further
          agree not to collect information from adjacent private properties or
          non-public areas in which others may have a reasonable expectation of
          privacy.
        </BoldText>{" "}
        SimpliSafe disclaims any and all liability arising out of or connected
        with the Camera’s scope of view, and you agree that any claim, damage,
        or issue arising therefrom is between you and any third party and you
        agree not to pursue SimpliSafe for any such damage, claim, or other
        liability.
      </p>
      <p></p>
      <p>
        <BoldText>
          You acknowledge and agree you will not use the facial recognition
          feature on the Cameras in Maryland, Illinois, Washington, Texas or
          outside of the United States. This feature can be turned off in the
          location settings in your Pre Crime Services mobile app (“PCS app”).
          Note, the facial recognition feature must be turned off for each
          monitored location. If your monitored premises are located in any of
          the above-listed states or outside of the U.S., you are not eligible
          to participate in this Program.
        </BoldText>
      </p>
      <p></p>
      <p>
        <BoldText>2.</BoldText>{" "}
        <BoldText>Video and Other Data Collection.</BoldText> Cameras may
        collect videos of occupants, strangers, and guests (which may include,
        without limitation, postal workers, delivery persons, friends, family
        members, and unknown individuals) who enter into the field of view of
        the Cameras. The Cameras may collect videos of pets, objects delivered
        to your property (e.g., packages), and other objects (e.g., pets,
        automobiles, etc.) that are in the field of view of the Cameras.
      </p>
      <p></p>
      <p>
        AI processing of the video collected by Cameras will attempt to
        distinguish whether images are of humans, pets, or other objects,
        including but not limited to packages.
      </p>
      <p></p>
      <p>
        AI processing of the video collected will attempt to leverage facial
        recognition technologies for purposes of recognizing known or familiar
        occupants of or visitors to the property to assist with monitoring
        services.
      </p>
      <p></p>
      <p>
        Participation in the Program will also involve collection of data
        related to device functionality, mobile app usage and feedback about the
        Program itself.
      </p>
      <p></p>
      <p>
        <BoldText>3.</BoldText> <BoldText>Privacy.</BoldText> The Program
        involves the collection and processing of identifiers (e.g., names,
        addresses, and device identifiers associated with Cameras and accounts);
        video and audio data, such as images, likenesses, facial features,
        facial embeddings, and other information related to individuals who
        enter Camera fields of view, including but not limited to voice
        recordings, and images of personal property (e.g., name tags, vehicles,
        license plates, motion, face detection, face recognition, object
        detection and reidentification); biometrics for purposes of recognizing
        individuals by image or voice; and inferences based on such information
        (such as whether an individual is a frequent visitor or a delivery
        person). You will have the ability to define and name individual faces
        under the Program and you agree you have all necessary consents to
        provide such information. The Program also collects demographic data,
        such as age or gender; internet activity information, such as
        information about your use of our websites or mobile applications in
        association with the Program; interactions between Equipment, SimpliSafe
        Customer Service, and/or SimpliSafe Monitoring Services and subjects
        interacting with said Equipment; and information you provide in surveys
        or interviews about the Program.
      </p>
      <p></p>
      <p>
        SimpliSafe will share such information with service providers acting on
        SimpliSafe’s behalf, such as data storage providers, survey providers,
        analytics and AI services, and data processing services. In addition,
        SimpliSafe may share personal information in association with legal
        proceedings and sales or changes of control of our business, as
        described in our privacy policy
        ([https://simplisafe.com/privacy-policy](https://simplisafe.com/privacy-policy)).
      </p>
      <p></p>
      <p>
        You hereby consent to SimpliSafe’s collection, distribution, sharing,
        and processing of such information in accordance with the terms of this
        Agreement, including but not limited to facial recognition, voice
        recognition, or other AI processing performed by SimpliSafe or its third
        party vendors.
      </p>
      <p></p>
      <p>
        <span style={{ textDecoration: "underline" }}> </span>
      </p>
      <p></p>
      <p>
        <span style={{ textDecoration: "underline" }}>
          You agree that it is your sole responsibility to comply with
          applicable laws regarding the collection of video, audio, and
          biometric information via Cameras and the sharing of such information
          with SimpliSafe for processing in accordance with the terms of this
          Agreement. You agree that with respect to individuals who enter into
          the fields of view of Cameras, it is your sole responsibility to
          provide all notices and obtain all consents necessary to authorize the
          processing of information collected by Cameras as described in this
          Agreement, and you agree that you are signing this Agreement with the
          authorization of and on behalf of all cohabitants of your home.{" "}
        </span>
      </p>
      <p></p>
      <p>
        If you are a resident of California, please see the California Privacy
        Notice section of our privacy policy
        ([https://simplisafe.com/privacy-policy](https://simplisafe.com/privacy-policy))
        for additional information, including information about rights you may
        have with respect to your information.
      </p>
      <p></p>
      <p>
        Should SimpliSafe request that you or others sign a separate consent
        form for certain processing activities, you agree to review and/or share
        with others such consent form. If you or others refuse to sign the
        consent form, you agree to cease participation in the Program and notify
        SimpliSafe of your cessation in accordance with Section 9 herein.
      </p>
      <p></p>
      <p>
        <BoldText>4.</BoldText>{" "}
        <BoldText>SimpliSafe Processing of Data.</BoldText> SimpliSafe will
        process the data collected via Cameras and the data generated by AI for
        the purposes of:
      </p>
      <p></p>
      <p>
        * tagging objects in Camera fields of view (i.e., indicating whether an
        object is a person, package, animal, or other object)
      </p>
      <p></p>
      <p>
        * identifying whether an individual, as opposed to a pet or a moving
        object, has entered into or moved within the Camera field of view;
      </p>
      <p></p>
      <p>* identifying whether an individual may be loitering in an area;</p>
      <p></p>
      <p>
        * distinguishing between vehicles associated with deliveries to the
        property and other vehicles;
      </p>
      <p></p>
      <p>
        * recognizing individuals who commonly enter the Camera fields of view;
      </p>
      <p></p>
      <p>
        * alerting SimpliSafe personnel to the presence of objects or
        individuals in Camera fields of view;
      </p>
      <p></p>
      <p>* developing new equipment, features or offerings;</p>
      <p></p>
      <p>* improving existing equipment, features or offerings; and</p>
      <p></p>
      <p>
        * training and testing various logics, AI, and computer vision (CV)
        models, approaches, and features that SimpliSafe may use in future
        products, services, or enhancements
      </p>
      <p></p>
      <p>
        <BoldText>5.</BoldText> <BoldText>Agent Monitoring.</BoldText> The
        Program may be designed to alert trained Monitoring Services agents of
        an individual being on your property. Agent monitoring is only performed
        based on the settings you choose for cameras, and for this Program is
        only when your Camera is in “armed” mode during the hours stated in
        Section 6. If your Camera is armed, agents will be able to remotely
        access Camera(s) live video, or frames of video related to, and during,
        events. Agents may engage with individuals by speaking to individuals,
        triggering sirens, triggering pre-recorded audio file recordings,
        turning on the Camera’s spotlight, listening to live audio, or taking
        other actions supported by the Cameras and applications.
      </p>
      <p></p>
      <p>
        You consent to agents accessing video and audio data from Cameras via
        SimpliSafe’s and/or third party vendors’ software. You further consent
        to agents responding by taking steps such as, but not limited to, those
        listed above.
      </p>
      <p></p>
      <p>
        <BoldText>6.</BoldText>{" "}
        <BoldText>
          No Emergency Service Alert, Action, or Attempt to Dispatch Will Be
          Taken.
        </BoldText>{" "}
        You need to arm your main SimpliSafe system in order to receive your
        usual services you are contracted for with SimpliSafe outside of the
        Program. So long as your main SimpliSafe system is armed, then except
        for the Camera and the Monitoring Services under the Program, you will
        continue to receive the level of monitoring or service you are signed up
        for with SimpliSafe under your account services.
      </p>
      <p></p>
      <p>
        You will need to ensure that your Cameras are set up per the Live Guard
        Outdoor Camera Kit installation guide, that you’ve selected in the PCS
        app within location settings to allow the Monitoring Services’ agent
        access to the Camera, that your main SimpliSafe system is armed and that
        the Monitoring Services are enabled in the PCS app to participate in
        this Program. Depending on the Monitoring Services monthly subscription
        plan you select for this Program, the Camera and the Monitoring Services
        under the Program will be supported either 24 hours a day, 7 days a week
        or from 8:00 PM to 6:00 AM, 7 days a week, unless you are notified
        otherwise by a representative from the SimpliSafe Program.
      </p>
      <p></p>
      <p>
        You acknowledge, agree and understand that **
        <span style={{ textDecoration: "underline" }}>
          no emergency response action will be taken by the Camera, the
          Monitoring Services or the Monitoring Services’ agent during the
          Program, even when the Camera and Monitoring Services are enabled and
          inarmed mode. This means that no emergency alerts, actions or attempts
          to dispatch emergency response services will occur and you
          acknowledge, agree and understand that Monitoring Services for this
          Program only permit the Monitoring Services’ agents to interact with
          or speak to individuals on your property through the Camera, to turn
          on audible sirens and/or spotlights as a deterrence, and that the
          Monitoring Services’ agent duties do not include any additional
          actions, such as alerting police, fire, or ambulance. Even if it is
          determined that an individual is in fact an intruder or a threat,
          Monitoring Services’ agents will take no further action and no
          emergency dispatch will occur under this Program
        </span>
        <BoldText>
          . For this reason, and as stated in the preceding paragraphs,{" "}
        </BoldText>
        it is advised you enable and arm your main SimpliSafe system to ensure
        that you continue to receive your usual indoor monitoring and emergency
        dispatch services to which you subscribe and contract for with
        SimpliSafe outside of this Program.
      </p>
      <p></p>
      <p>
        <BoldText>7.</BoldText>{" "}
        <BoldText>Confidentiality Obligations.</BoldText> As the data collection
        and video processing performed under this Agreement relates to
        confidential product developments projects, you may{" "}
        <BoldText>
          <span style={{ textDecoration: "underline" }}>not</span>
        </BoldText>{" "}
        share any details of the Program, including this testing or collection,
        or details of the prototype Features and Equipment, with anyone outside
        of those at SimpliSafe, except to the extent necessary to comply with
        your obligations set forth in Section 3 of this Agreement, or where
        specifically sponsored and permitted by SimpliSafe, such as in a company
        sponsored private test user forum that SimpliSafe invites you to join
        for this Program.
      </p>
      <p></p>
      <p>
        You may not post any pictures or other information about the Program,
        any equipment used in the program (including the Equipment) or the
        collection of information associated with the Program with anyone else
        on social media or any other medium of communication channel unless
        specifically permitted to do so by SimpliSafe.{" "}
      </p>
      <p></p>
      <p>
        “Confidential Information” includes, but is not limited to: the nature
        of this video collection and testing, the details of the Program, the
        terms of this Agreement and data processing described herein, feedback
        from you, either online or any included in surveys or other requests for
        information pertaining to your video collection and your responses to
        each.
      </p>
      <p></p>
      <p>
        You agree to{" "}
        <BoldText>
          <span style={{ textDecoration: "underline" }}>not</span>
        </BoldText>{" "}
        share any Confidential Information with any person or entity outside of
        SimpliSafe (except as you may be requested by SimpliSafe to participate
        in any private forum) or make use of it for any purpose, without the
        express written permission of SimpliSafe. This includes, but is not
        limited to, your agreement not to publish, discuss or otherwise
        disseminate any information about this video collection project (this
        includes, without limitation, on any social media platforms).
      </p>
      <p></p>
      <p>
        <BoldText>8.</BoldText>{" "}
        <BoldText>
          SimpliSafe Ownership of Video / License Grant to SimpliSafe to Use
          Video.
        </BoldText>{" "}
        You understand and hereby agree that SimpliSafe shall be the sole owner
        of any videos and/or audio collected by the Camera. However, to the
        extent you would be deemed the owner or any videos or audio, you hereby
        grant SimpliSafe and its licensees an unlimited, irrevocable, fully paid
        and royalty-free, perpetual, worldwide right and license to use, re-use,
        distribute, store, share, delete, translate, copy, modify, display,
        create derivative works from, use for any research, development, testing
        and analytical purposes, and otherwise exploit such any video or audio
        collected or provided in association with this prototype test program
        for any lawful purpose and in any media formats in any media channels
        without any additional compensation to you both during and after the
        Collection Period and whether by SimpliSafe or as such video or audio
        recordings are provided to third party vendors. For the avoidance of
        doubt, such permitted and licensed uses include, without limitation, any
        tagging/processing for AI/CV model generation, data analysis and through
        any manner, medium or format, electronic or otherwise, either directly
        or using one or more third party vendors. You further agree that any
        shared video or audio content that you provide to SimpliSafe under this
        prototype test program will be subject to the SimpliSafe Terms, Consent
        & Release for Video Sharing Non-Crime Related Videos
        ([https://simplisafe.com/legal/video_sharing/non_crime](https://simplisafe.com/legal/video_sharing/non_crime)).
      </p>
      <p></p>
      <p>
        <BoldText>9.</BoldText> <BoldText>Termination.</BoldText> Should you
        desire to leave the Program at any time, you may send seven days’
        advance written notice requesting for your participation in the Program
        to cease. Notice may be given via an in-app feedback form available
        using our mobile application or in writing via email to
        pcs@simplisafe.com. Upon termination, the test features of the camera,
        such as some of the AI features, will no longer be available for
        use.SimpliSafe may terminate the Program at any time, with or without
        prior notice.{" "}
      </p>
      <p></p>
      <p>
        The following sections will survive termination of this Agreement: 7
        (Confidentiality Obligations), 8 (SimpliSafe Ownership of Data), 10
        (Program Feedback), 11 (indemnification), 12 (No Representations or
        Warranty), 13 (Limitation of Liability), 14 (Disputes), and 18
        (Miscellaneous).
      </p>
      <p></p>
      <p>
        Upon SimpliSafe’s confirmation of your termination request, you may
        still retain publicly offered home security monitoring services with
        your SimpliSafe Home security system <BoldText>**BUT**</BoldText> Agent
        Monitoring (as defined in paragraph 5, above) will no longer be able to
        directly monitor your outdoor camera(s) and the test features of that
        camera may no longer be offered or available. Once the features are made
        generally available to the public you may purchase services from
        SimpliSafe again for this camera(s).
      </p>
      <p></p>
      <p>
        <BoldText>10.</BoldText> <BoldText>Program Feedbacks.</BoldText> To
        enable our ability to collect your important input and sentiments, we
        may direct you to (i) complete surveys and other feedback prompts and/or
        (ii) an online forum for prototype testers where you may be asked to
        provide feedback.
      </p>
      <p></p>
      <p>
        * You agree to complete such tasks on a timely basis to the best of your
        ability.
      </p>
      <p></p>
      <p>
        *{" "}
        <BoldText>
          You understand and agree that SimpliSafe shall own all rights in and
          to all feedback and information you provide and shall have the right
          to use such information for any commercial purpose without restriction
          or further obligation. Feedback shall include any suggestion or idea
          about the Camera, theeEquipment, the Features, or other SimpliSafe
          products or services.
        </BoldText>
      </p>
      <p></p>
      <p>
        You understand and agree that you do not have rights to, and shall not
        attempt to, sell, lend or otherwise transfer the Equipment to anyone
        during the Program. The Equipment is for use at your primary home or
        dwelling and nowhere else. You further agree that you will not, and will
        not permit any third party to, reverse engineer or disassemble the
        Equipment, or examine any other materials or objects received as part of
        this program.<BoldText>****</BoldText>
      </p>
      <p></p>
      <p>
        Upon our determination that you fully participated in the Program, you
        may acquire title to the Equipment at our discretion. If upon completion
        of the Program you decide not to keep the Equipment, then it can be sent
        back to us free of charge.
      </p>
      <p></p>
      <p>
        You understand and agree that you do not obtain or acquire any ownership
        rights or title in or to any software or firmware installed on, or used
        with, the Camera. In exchange for participating in this program,
        SimpliSafe agrees to grant you a limited non-transferable license to use
        the software and firmware installed on the Camera solely during the
        Program. You may use such software only in connection with the Camera in
        which it is embedded during the Program, and may not modify, distribute,
        copy, decompile, or reverse engineer such software.
      </p>
      <p></p>
      <p>
        Subject to the terms of this Agreement, and to the extent applicable use
        of the Camera and monitoring services will at all times be subject to
        the SimpliSafe Terms of Service
        ([http://simplisafe.com/terms-of-service)](http://simplisafe.com/terms-of-service)
        which have been accepted in connection with the activation of the
        Camera. Use of the information you provide during the test will be
        subject to the terms of this Agreement, and to the extent applicable to
        the SimpliSafe Terms of Use
        ([https://simplisafe.com/terms-of-use)](https://simplisafe.com/terms-of-use)
        and Privacy Policy
        ([https://simplisafe.com/privacy-policy](https://simplisafe.com/privacy-policy)).
      </p>
      <p></p>
      <p>
        You specifically acknowledge that the AI features of the Camera and the
        monitoring services are prototype products, and as such the products or
        certain features or functions thereof may or may not perform or operate
        in an optimal manner. As such, you agree to use and evaluate the
        products solely at your own risk, and to release and hold SimpliSafe and
        its shareholders, officers, directors and employees, and those of its
        affiliates, harmless from any damages arising from their performance,
        installation, removal, or use.
      </p>
      <p></p>
      <p>
        <BoldText>11.</BoldText> <BoldText>Indemnification.</BoldText> You agree
        to indemnify, defend and hold harmless SimpliSafe and our parent,
        subsidiaries, affiliates, partners, shareholders, members, officers,
        directors, managers, agents, contractors, licensors, service providers,
        subcontractors, suppliers, interns and employees, from any claim or
        demand, including reasonable attorneys’ fees, made by any third-party
        due to or arising out of your breach of his Agreement or the documents
        they incorporate by reference, or your violation of any law or the
        rights of a third-party.
      </p>
      <p></p>
      <p>
        <BoldText>12.</BoldText>{" "}
        <BoldText>NO REPRESENTATIONS OR WARRANTY.</BoldText> ALTHOUGH SIMPLISAFE
        RESERVES THE RIGHT TO CORRECT ANY ERRORS, OMISSIONS, OR INACCURACIES,
        YOU ACKNOWLEDGE THAT THE PROGRAM AND ALL INFORMATION WITH RESPECT
        THERETO IS PROVIDED ON AN “AS IS,” AS AVAILABLE” BASIS WITHOUT
        REPRESENTATIONS OR WARRANTIES OF ANY KIND AND THAT YOUR PARTICIPATION IN
        THE PROGRAM IS AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMISSIBLE BY
        APPLICABLE LAW, DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED INCLUDING,
        BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
        FOR A PARTICULAR PURPOSE.
      </p>
      <p></p>
      <p>
        <BoldText>13.</BoldText> <BoldText>LIMITATION OF LIABILITY.</BoldText>{" "}
        WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, YOU AGREE THAT
        SIMPLISAFE AND AFFILIATES WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND
        ARISING FROM THE PROGRAM; THE INABILITY TO PARTICIPATE IN THE PROGRAM;
        OR ANY INFORMATION CONTENT OR FUNCTIONALITY OFFERED IN CONNECTION WITH
        THE PROGRAM. THESE LIMITATIONS APPLY TO ALL LOSSES AND DAMAGES OF ANY
        KIND, INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL,
        PUNITIVE, AND CONSEQUENTIAL DAMAGES. IF YOU ARE DISSATISFIED WITH THE
        PROGRAM, YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP PARTICIPATING IN THE
        PROGRAM.
      </p>
      <p></p>
      <p>
        APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OF LIABILITY SET FORTH
        ABOVE, SO THIS LIMITATION OF LIABILITY MAY NOT APPLY TO YOU. IF ANY PART
        OF THIS LIMITATION OF LIABILITY IS FOUND TO BE INVALID OR UNENFORCEABLE
        FOR ANY REASON, THEN THE AGGREGATE LIABILITY OF SIMPLISAFE UNDER SUCH
        CIRCUMSTANCES FOR LIABILITIES THAT OTHERWISE WOULD HAVE BEEN LIMITED
        SHALL NOT EXCEED ONE HUNDRED DOLLARS ($100).
      </p>
      <p></p>
      <p>
        <BoldText>14.</BoldText> <BoldText>DISPUTES:</BoldText>
      </p>
      <p></p>
      <p>
        <BoldText>
          Informal Dispute Resolution: Prior to initiating any arbitration or
          bringing a claim in small claims court (as set forth below), you must
          first present any dispute to SimpliSafe by contacting us at
          pcs@simplisafe.com to allow for an opportunity to resolve the dispute.
          You or we may initiate arbitration if your dispute cannot be resolved
          within 60 days.
        </BoldText>
      </p>
      <p></p>
      <p>
        PLEASE READ THIS SECTION CAREFULLY AS IT MAY SIGNIFICANTLY AFFECT YOUR
        LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT AND TO
        HAVE A JURY HEAR YOUR CLAIMS. IT CONTAINS PROCEDURES FOR MANDATORY
        BINDING ARBITRATION AND A CLASS ACTION WAIVER.
      </p>
      <p></p>
      <p>
        ANY CLAIM THAT EITHER OF US MAY HAVE ARISING OUT OF OR RELATING TO THIS
        AGREEMENT (INCLUDING FORMATION, PERFORMANCE, OR BREACH OF THEM), OUR
        RELATIONSHIP WITH EACH OTHER, OR USE OF THE SERVICES MUST BE RESOLVED
        THROUGH BINDING INDIVIDUAL ARBITRATION IN ACCORDANCE WITH THE AMERICAN
        ARBITRATION ASSOCIATION (AAA)’S CONSUMER ARBITRATION RULES, AVAILABLE
        WWW.ADR.ORG. ARBITRATION HEARINGS WILL BE HELD IN BOSTON, MA. IF THIS
        LOCATION IS NOT CONVENIENT FOR YOU, PLEASE LET US KNOW AND WE WILL WORK
        TO DETERMINE A MUTUALLY CONVENIENT LOCATION. ANY DISAGREEMENTS REGARDING
        THE FORUM FOR ARBITRATION WILL BE SETTLED BY THE ARBITRATOR.
      </p>
      <p></p>
      <p>
        THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN
        ARBITRATION AWARD IS LIMITED. HOWEVER, AN ARBITRATOR CAN AWARD ON AN
        INDIVIDUAL BASIS THE SAME DAMAGES AND RELIEF AS A COURT (INCLUDING
        INJUNCTIVE AND DECLARATORY RELIEF OR STATUTORY DAMAGES), AND MUST FOLLOW
        THE TERMS OF THESE TERMS AS A COURT WOULD.
      </p>
      <p></p>
      <p>
        AS AN EXCEPTION TO THIS ARBITRATION PROVISION, EITHER YOU OR SIMPLISAFE
        MAY PURSUE IN SMALL CLAIMS COURT ANY CLAIM THAT IS WITHIN THAT COURT’S
        JURISDICTION SO LONG AS SUCH CLAIMS ARE BROUGHT ONLY ON AN INDIVIDUAL
        BASIS. NOTHING HEREIN SHALL BE CONSTRUED TO PRECLUDE ANY PARTY FROM
        SEEKING INJUNCTIVE RELIEF IN THE STATE AND FEDERAL COURTS LOCATED IN
        MASSACHUSETTS, IN ORDER TO PROTECT ITS RIGHTS PENDING AN OUTCOME IN
        ARBITRATION.
      </p>
      <p></p>
      <p>
        YOU AND SIMPLISAFE ALSO AGREE TO ARBITRATE IN EACH OF YOUR AND OUR
        INDIVIDUAL CAPACITIES ONLY, NOT AS A REPRESENTATIVE OR MEMBER OF A
        CLASS, AND YOU AND WE EXPRESSLY WAIVE ANY RIGHT TO FILE A CLASS ACTION
        OR SEEK RELIEF ON A CLASS BASIS.
      </p>
      <p></p>
      <p>
        IF YOU INITIATE ARBITRATION, YOUR ARBITRATION FEES WILL BE LIMITED TO
        THE FILING FEE SET FORTH IN AAA’S CONSUMER ARBITRATION RULES. REGARDLESS
        OF WHO INITIATES ARBITRATION, SIMPLISAFE WILL PAY YOUR SHARE OF
        ARBITRATION FEES (NOT INCLUDING YOUR ATTORNEYS’ FEES) UP TO A MAXIMUM OF
        $2,500. IF THE ARBITRATOR RULES AGAINST SIMPLISAFE, IN ADDITION TO
        ACCEPTING WHATEVER RESPONSIBILITY IS ORDERED BY THE ARBITRATOR,
        SIMPLISAFE WILL REIMBURSE YOUR REASONABLE ATTORNEYS’ FEES AND COSTS UP
        TO A MAXIMUM OF $5,000, REGARDLESS OF WHO INITIATED THE ARBITRATION,
        UNLESS THE ARBITRATOR FINDS SOME OR ALL OF YOUR CLAIMS TO BE FRIVOLOUS
        OR TO HAVE BEEN BROUGHT IN BAD FAITH. IN ADDITION, IF THE ARBITRATOR
        RULES IN SIMPLISAFE’S FAVOR, IT WILL NOT SEEK REIMBURSEMENT OF OUR
        ATTORNEYS’ FEES AND COSTS, REGARDLESS OF WHO INITIATED THE ARBITRATION,
        UNLESS THE ARBITRATOR FINDS SOME OR ALL OF YOUR CLAIMS TO BE FRIVOLOUS
        OR TO HAVE BEEN BROUGHT IN BAD FAITH.
      </p>
      <p></p>
      <p>
        To help resolve any issues between us promptly and directly, you and
        SimpliSafe agree to begin any arbitration within one year after a claim
        arises; otherwise, the claim is waived.
      </p>
      <p></p>
      <p>
        It is important that you understand that by entering into this
        agreement, we are each waiving the right to a trial by jury or to
        participate in a class action against the other party for claims that
        are covered by this section. The arbitrator’s decision will be
        conclusive and binding and may be entered as a judgment in any court of
        competent jurisdiction.
      </p>
      <p></p>
      <p>
        <BoldText>15.</BoldText>{" "}
        <BoldText>Terms of the Agreement Control.</BoldText> You agree that your
        participation in the Program will be governed by our [Terms of
        Service](https://simplisafe.com/terms-of-service) (available at
        [www.simplisafe.com/terms-of-service](https://simplisafe.com/terms-of-service))
        as well as the terms of this Agreement. You further acknowledge and
        agree that If there is a conflict between our Terms of Service and the
        terms provided herein, then the terms of this Agreement shall apply. Any
        changes made to the Terms of Service during your participation in this
        Test will also apply to your Services to the extent they are applicable.
      </p>
      <p></p>
      <p>
        <BoldText>16.</BoldText> <BoldText>Service Adjustments.</BoldText> We
        may from time to time make adjustments (e.g., software updates, camera
        resolution or image quality setting changes etc.) to the Features and/or
        Equipment for evaluation, testing, troubleshooting, and/or performance
        purposes. You hereby give us authorization to make such adjustments to
        the Features and/or Equipment at our sole discretion.
      </p>
      <p></p>
      <p>
        <BoldText>17.</BoldText>{" "}
        <BoldText>Amendments to the Agreement.</BoldText> We may amend this
        Agreement from time to time by sending a new version to you at the email
        address associated with your SimpliSafe account. You agree that
        SimpliSafe allowing you to continue to participate is a consideration
        for any such amendment. Such amendment will become effective 15 days
        after such notice (unless you terminate this Agreement pursuant to
        Section 10, Termination, during such 15-day period). Your continued use
        of the Features after such a 15-day period will confirm your consent to
        such amendment. This Agreement may not be amended in any other way
        except through a written agreement by Authorized Representatives of each
        Party.
      </p>
      <p></p>
      <p>
        <BoldText>18.</BoldText> <BoldText>Miscellaneous.</BoldText> This
        Agreement constitutes the entire agreement between the parties with
        respect to the subject matter hereof. This Agreement is governed by the
        laws of the Commonwealth of Massachusetts without regard to its rules
        concerning conflicts of laws. The invalidity or unenforceability of any
        provision of this Agreement shall not affect the validity or
        enforceability of any other provision of this Agreement. You agree that
        you may not assign this Agreement without the written consent of
        SimpliSafe. You agree and understand the use of the word “video” herein
        includes audio recordings since the videos being taken are both audio
        and visual recordings.
      </p>
      <p></p>
      <p>
        <BoldText>19.</BoldText> <BoldText>Term.</BoldText> The Term of this
        Agreement shall be six (6) months beginning on March 1, 2023 through
        July 30, 2023. Beginning on March 1, 2023 and on the first of each
        consecutive month thereafter through July 1, 2023, you shall be billed
        $39.99 per month for the monthly subscription plan under this Program
        that provides the Monitoring Services for 24 hours a day, 7 days a week;
        or $19.99 per month for the monthly subscription plan under this Program
        that provides the Monitoring Services from 8:00 PM to 6:00 AM, 7 days a
        week, unless you are notified otherwise by a representative from the
        SimpliSafe Program that the Program duration has been changed. In the
        event you terminate this Agreement, monthly subscription charges under
        this Program will not be pro-rated, but the Monitoring Services will
        remain available to you through the last day of the month for which you
        paid for these services and the terms of this Agreement will continue to
        apply through that same date, as set forth herein.
      </p>
      <p></p>
      <p>
        <BoldText>20.</BoldText> If you wish to cancel your participation in
        this Program, you must notify us at
        [pcs@simplisafe.com](mailto:pcs.beta@simplisafe.com) no later than the
        20th day of the month prior to the next billing cycle date. If you
        notify us of your decision to cancel after this date, your cancellation
        will not occur until the following month’s billing cycle. You further
        agree that the Term of this Agreement can be extended up to another six
        (6) months beyond the initial expiration date of the Term of this
        Agreement at our discretion and without further consideration or
        compensation to you upon email notice to you via the email address
        provided from your account information.
      </p>
      <p></p>
      <p>
        When you open the Pre Crime Services mobile app and are presented with
        the terms of this Agreement, by clicking the “Agree to the SimpliSafe
        Outdoor Monitoring Participant Agreement” check box in the Pre Crime
        Services mobile app and then clicking the "Submit" button, you are
        confirming you have read, understand and agree to this Agreement.
      </p>
    </>
  );
};

export default FullTerms;
