import styled from "styled-components";
import colors from "../../../config/colors";
import Card from "../../templates/Card";
import SectionTitle from "../../templates/SectionTitle";

export const Container = styled(Card)`
  background: #fff;
  margin-top: 4rem;
  margin-bottom: 4rem;

  @media (max-width: 480px) {
    margin: 1rem 0;
  }
`;

export const List = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0 3rem;
  @media(max-width: 800px) {
    padding: 0;
  }
`;

export const Title = styled(SectionTitle)`
  text-align: left;
  font-family: "ArizonaSans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 57px;
  letter-spacing: -0.012em;
  margin: 0 4rem;
  @media (max-width: 820px) {
    padding: 0 2rem;
    margin: 1rem;
    padding: 1rem;
  }
  @media (max-width: 480px) {
    margin: 0;
    font-size: 38px;
    line-height: 2.5rem;
  }
`;

export const QuestionParagraph = styled.p`
  font-family: "SimpliSans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  background: #fff;
  border: 2px solid ${colors.action};
  cursor: pointer;
  color: ${colors.action};
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  padding: 1rem;
  width: 150px;
  margin-top: 1rem;
  border-radius: 8px;
`;
