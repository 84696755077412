import { S3_MEDIA_URL } from "./environments";

export const TextToLeftAndImageFullBackground1 = {
  title: `Don’t become a victim of crime.`,
  actionButton: "Join Now",
  text: `Your Outdoor Camera is your first line of defense when someone is lurking outside. But today, it’s not equipped to prevent an intruder from getting in. Now, that’s changing with the launch of our latest innovation:  <strong>Live Guard Outdoor Protection.</strong>`,
  textBreakLine: `Join our exclusive early access program today and see how we can help stop crime before it happens.`,
  imageUrl: `${S3_MEDIA_URL}v1/images/new-hero-2.png`,
  imageSmallUrl: `${S3_MEDIA_URL}v1/images/new-hero-2-xs.png`,
  imageDescription: "Your Outdoor Camera is your first line of defense when someone is lurking outside. But today, it’s not equipped to prevent an intruder from getting in. Now, that’s changing with the launch of our latest innovation:  Live Guard Outdoor Protection.",
};

// export const TextToLeftAndVideoLoop1 = {
//   title: "NEW Live Guard Outdoor Protection. Prevent crime before it happens.",
//   text: `Prevent crime before it happens when you are away or unavailable.  Live guards monitor outdoor cameras when human activity is detected and respond to unwanted events.  Apply to the pilot program to experience this breakthrough technology.`,
//   footerNotes: `Pricing is per system. Subscription is billed monthly based on 30 days`,
//   videoUrl:
//     "https://trameparis.com/media/videos/covers/Trame-Granada-Long-Edit.mp4",
//   videoDescription: "SimpliSafe® video sample with sample description",
//   detailCards: [
//     {
//       title: "$39.99/mo",
//       items: [`24-hour`, `Monitoring`],
//     },
//     {
//       title: "$19.99/mo",
//       items: [`Overnight`, `Monitoring`],
//     },
//   ],
// };

export const CenteredText1 = {
  text: `Prevent crime before it happens when you are away or unavailable. Live guards can monitor outdoor cameras when human activity is detected and respond to unwanted events. Apply to the pilot program to experience this breakthrough technology.`,
};

export const TitleAndTopicNumbers1 = {
  title: "The next layer of protection for outside your home.",
  topics: [
    {
      iconSrc: "v1/icons/sound-blue.svg",
      title: "Helps stop crime before it happens",
      text: `When your outdoor monitoring service is active, agents can see and speak to intruders through your Outdoor Cameras using live, 2-way agent audio - sending them running.`,
      minWidth: 300,
    },
    {
      iconSrc: "icons/siren-icon.svg",
      title: "Instant updates & follow-up",
      text: `Get fast phone alerts, watch live step-by-step agent activity - and receive email summaries when someone approaches your home.`,
      minWidth: 280,
    },
    {
      iconSrc: "icons/AI-Icon.svg",
      title: "Smart motion detection & agents reduce unwanted notifications",
      text: `Smart motion detection backed by highly-trained monitoring agents distinguish people from pets, cars and more. Create profiles for friends and family to help live agents differentiate people you know from strangers.`,
      minWidth: 300,
    },
    {
      iconSrc: "icons/shield-blue2.svg",
      title: "Peace of mind. Full control.",
      text: `Choose which cameras you want live agents to monitor and when to turn on outdoor monitoring.`,
      minWidth: 250,
    }
  ],
};

export const Reviews1 = {
  mainReviewText: `With everything going on and how busy people can be, it's just nice to have something that I don't have to make a decision on.  Somebody who is trained and trustworthy can help make that decision!`,
  mainReviewReferenceText: '- Grace',
  mainReviewStars: 5,
  otherReviews: [
    {
      reviewText: `I'm glad I had [Outdoor Monitoring].  Who knows what could have happened!`,
      reviewStars: 5,
      reviewAuthor: "-Kyley",
    },
    {
      reviewText: `I would love to see this rolled out!`,
      reviewStars: 5,
      reviewAuthor: "-Jeffery",
    },
    {
      reviewText: `The Pre Crime Service app has been great.   I know that if something pops up from that, then it is something that I definitely need to look at.`,
      reviewStars: 5,
      reviewAuthor: "-Grace",
    },
  ],
};

export const TwoDetailsCards1 = {
  title: 'Outdoor monitoring plans',
  card2: {
    title: "24/7 Monitoring",
    text: "Live agents protect your home any time of day when your security system is armed. Only work with your existing Fast Protect™ Monitoring subscription.",
    textBold: undefined,
    actionButton: "Shop Now",
    priceValue: "$39.99",
    pricePeriod: "/mo addtl",
    earlyAccessPromotion: "$9.99/month additional*",
  },
  card1: {
    title: "Overnight Monitoring",
    text: "Outdoor home protection from 8pm-6am when your SimpliSafe system is armed. Only work with your existing requires Fast Protect™ Monitoring subscription.",
    textBold: undefined,
    actionButton: "Shop Now",
    priceValue: "$19.99",
    pricePeriod: "/mo addtl",
    earlyAccessPromotion: "$4.99/month additional*",
  },
  notes: "*Requires maintaining your Fast Protect™ monitoring subscription at $29.99/mo. Participants will be expected to use their security system and provide feedback for the duration of this program (6 months).  SimpliSafe will collect video and other data for product development purposes. See FAQs for more details.",
};

export const TextToRightAndImage1 = {
  id: "features",
  title: "Detects people.",
  titleBreakLine: "Ignores distractions.",
  text: `Live Outdoor Monitoring uses smart motion detection so it can tell when an alert was created by an unfamiliar person. Our agents review the event and decide the best course of action. That way, you can be confident a notification of potential threat is real.`,
  imageUrl: `${S3_MEDIA_URL}v1/images/detects-ppl-ignores-distraction.png`,
  imageDescription:
    "Live Outdoor Monitoring uses smart motion detection so it can tell when an alert was created by an unfamiliar person. Our agents review the event and decide the best course of action. That way, you can be confident a notification of potential threat is real.",
};

export const TextToLeftAndImage1 = {
  title: "Protect your privacy",
  text: `You choose which cameras our agents monitor—and when you want them to be monitored.`,
  textBreakLine: `Our agents can only access your camera’s microphone and video feed during an event on your property. Plus, the camera’s LED light flashes any time a monitoring agent is accessing your camera. So you always know when we’re protecting you.`,
  imageUrl: `${S3_MEDIA_URL}v1/images/camera-window.png`,
  imageDescription: "Protect your privacy",
};

export const TextToRightAndImage2 = {
  title: "Your eyes and ears",
  text: `This breakthrough technology allows our monitoring agents to see and speak to potential threats outside your home using two-way audio. That means our agents can quickly help deter unwanted people or activity.`,
  imageUrl: `${S3_MEDIA_URL}v1/images/your-eyes-and-ears.png`,
  imageDescription: "Your eyes and ears",
};

export const TextToLeftAndImage2 = {
  title: "Privacy by design. You’re in control.",
  titleBreakLine: undefined,
  text: `Exclusive access to the SimpliSafe’s Pre-Crime Service (PCS) mobile app lets you choose a plan and manage your camera(s) preferences and even create profiles for friendly faces.`,
  imageUrl: `${S3_MEDIA_URL}v1/images/app-img-v2.png`,
  imageDescription: "Privacy by design. You’re in control.",
  topics: [
    {
      iconSrc: "icons/zoom2.svg",
      text: "View Live activity & event summaries",
    },
    {
      iconSrc: "icons/AI-Icon.svg",
      text: "Advanced AI reduces unwanted notifications",
    },
    {
      iconSrc: "icons/profile-icon.svg",
      text: "Create friendly profiles for face recognition",
    },
    {
      iconSrc: "icons/shield-blue2.svg",
      text: "Cancel or “snooze” agent activity",
    },
  ],
};

export const TextToLeftAndImageFullBackground2 = {
  title: "Join our exclusive early access program for Live Guard Outdoor Protection today!",
  text: `Get exclusive access to the Pre Crime Service mobile app and free Outdoor Camera Accessory Kits, and experience live outdoor monitoring.`,
  actionButton: "Finish sign up",
  secondaryButton: "Not Now",
  imageUrl: `${S3_MEDIA_URL}v1/images/sign-up-not-now.png`,
  imageSmallUrl: `${S3_MEDIA_URL}v1/images/sign-up-not-now-xs.png`,
  imageDescription: "Join our exclusive early access program for Live Guard Outdoor Protection today!",
};

export const TextToLeftAndImage4 = {
  title: "Outdoor Camera",
  titleBreakLine: "Accessory Kit",
  imageButton: "See features and requirements",
  modalLeftTitle: "What's in the box?",
  imageExtraText: "*Camera NOT included",
  modalLeftTopics: [
    "Audio Enhancer",
    "25ft Power Cable and Adapter",
    "Fixed wall mount/stand",
  ],
  modalLeftTitle2: "Features",
  modalLeftTopics2: [
    "80dB siren",
    "Night vision",
    "Two-way audio",
    "Instant motion alerts",
    "Built-in smart motion detector",
    "Vision AI Technology",
    "Recording and 30-day storage with select monitoring plans",
  ],
  modalRightTitle: "Requirements",
  modalRightTopics: [
    "Outdoor Camera Required (sold separately)",
    "Wi-Fi connection (2.4GHz)",
    "SimpliSafe® Gen 3 system",
    "iOS 13 or later",
  ],
  modalRightTitle2: "OR",
  modalRightTopics2: ["Android 8 or later"],
  topics: [
    {
      iconSrc: "v1/icons/audio-dev-icon.svg",
      text: "Audio Enhancer",
      description:
        "Optimizes audio for agent communications and siren activation during an event.",
    },
    {
      iconSrc: "v1/icons/plug-dev-icon-blue.svg",
      text: "Power Cable",
      description:
        "Ensures constant power to your camera for fast detection and agent response.  Continuous power is required for Live Outdoor Monitoring participation.",
    },
    {
      iconSrc: "v1/icons/house-protect-icon-blue.svg",
      text: "Fixed Mount",
      description:
        "Enjoy peace of mind that your camera position is set with a mount that helps prevent tampering.",
    },
  ],
  imageUrl: `${S3_MEDIA_URL}v1/images/camera-kit-fd-12pct-lines.png`,
  imageDescription: "SimpliSafe® image sample with sample description",
};

export const TitleAndTopicNumbers2 = {
  title: "Here’s what to expect",
  topics: [
    {
      text: `Join the program today. We'll send your Outdoor Camera Accessory Kit(s) and an email with information on how to download the Pre Crime mobile app.`,
    },
    {
      text: `Download the Pre Crime mobile app and follow the steps to activate your service and agree to the Participant Agreement.`,
    },
    {
      text: `When your kit(s) arrives, follow the simple setup guide to complete installation. A software update will be applied to the outdoor cameras you select for use for live outdoor monitoring.`,
    },
    {
      text: `Outdoor monitoring subscription billing starts when you activate your service in the Pre Crime mobile app. Feedback may be requested, or can be provided at any time using the mobile app.`,
    },
  ],
};

// export const TwoActionButtons2 = {
//   label1: "Apply",
//   label2: "Opt-out",
// };

export const FrequentlyAskedQuestions1 = {
  mostAskedQuestions: [
    {
      question:
        "What is live outdoor monitoring?",
      answer:
        "Live outdoor monitoring is a new service concept, designed to help stop crime before it happens, outside your home. Outdoor monitoring is making home security proactive by combining innovative technology with trained SimpliSafe® monitoring agents.",
    },
    {
      question: "How long will the early access to the program last?",
      answer:
        "Early access will begin in the Fall of  2023 and will continue for about six months.",
    },
    {
      question: "How do I get started?",
      answer:
        "Click “Shop Now” and follow the simple steps. When prompted, download and log into the Pre Crime Service (PCS) mobile app using your SimpliSafe® login. You will need to accept the terms of our Outdoor Monitoring Participant Agreement. Following the activation and setup steps within the PCS mobile app to access all PCS features, such as the ability to choose the location(s) and camera(s) you want monitored by our live agents when your system is armed - home or away.  If you are having trouble finding or installing the PCS mobile app, please email PCS@simplisafe.com for help.",
    },
  ],
  allOtherQuestions: [
    {
      question: "How does it work?",
      answer:
        "When your security system is armed in Home or Away and a camera detects motion, advanced technology evaluates the video to determine if there is a person. A live, trained agent will review and determine if they should monitor all enabled outdoor cameras and doorbells at your location. The agent monitors, assists or deters people using two-way communication, sirens or flashing lights from your cameras. You may receive a notification and email from the live agent detailing the event.",
    },
    {
      question: "What is the difference between the Pre Crime and SimpliSafe® mobile apps?",
      answer:
        "Pre Crime App has new features, such as advanced AI, allows you to manage your outdoor monitoring service, arm your system and you can stream and manage all of your cameras. The SimpliSafe® App allows you to set up new, manage existing and control all of your sensors and cameras. It’s the main control center for your security system and intrusion alarms.",
    },
    {
      question: "How and when will I be billed?",
      answer:
        "Upon activating outdoor monitoring in the Pre Crime Service App, you will be charged a prorated fee for the add-on outdoor monitoring services for any remainder of that current billing cycle. After the month in which you activate outdoor monitoring, for each consecutive month, you will be billed the current rate for Fast Protect™ professional monitoring plan ($29.99) plus the add-on rate based upon the outdoor monitoring plan you select.",
    },
    {
      question: "Does this include my current SimpliSafe® monitoring plan?",
      answer:
        "Live outdoor monitoring is an additional service. You must have the Fast Protect™ professional monitoring plan ($29.99) in order to participate in this early access program.",
    },
    {
      question:
        "Do live agents monitor all of my cameras with outdoor monitoring?",
      answer:
      "No. We only monitor location(s) and camera(s) that you choose when your system is armed - home or away. You can change these settings for a location anytime in the settings menu of your Pre Crime Service mobile app.",
    },
    {
      question:
        "How does SimpliSafe® protect my privacy?",
      answer:
      "We are committed to protecting our customers' privacy and sensitive data just as vigilantly as their homes.. First, you control what cameras and when live agents monitor your location. Agents only have access to outdoor enabled cameras during a motion event that occurs during your subscription service hours when your system is armed. In addition, we use industry standard physical, technical and administrative controls to protect the confidentiality and security of biometric information which includes requiring agents to pass extensive background checks and work in a secured location.",
    },
    {
      question:
        "How does an agent know the difference between someone that does or does not belong on my property?",
      answer:
      "Agents are trained to monitor any unknown person except those with a profile  created by you for your location(s). Where permitted, PCS can use face matching to assist agents with matching faces during an event to avoid false interventions.",
    },
    {
      question:
        "Do live monitoring agents interact with people at my location?",
      answer:
      "When your system is turned on for outdoor monitoring and there is a motion event, agents are trained to observe and potentially engage with any person on your property. You can create profiles using the Pre Crime Service mobile app for friends and family for agents to ignore. The agent can watch, assist or deter people using two-way communication, sirens or flashing lights from your cameras.",
    },
    {
      question:
        "Why do I need the Outdoor Camera Accessory Kit?",
      answer:
      "The Kit is required for us to provide the service. The Audio Enhancer optimizes audio for agent communications and siren activation during an event. The Power Cable helps ensure that the camera is always on for fast detection and response.",
    },
    {
      question:
        "Do I need to install, set up or configure my camera(s) in any specific way?",
      answer:
      "Yes. Cameras that you want to be monitored should be mounted, powered and enabled for viewing within the Pre Crime Service mobile app. Review the Outdoor Camera Accessory Guide included in your shipment for more detailed information.",
    },
    {
      question:
        "Why do my cameras have to be plugged in?",
      answer:
      "Video, audio and speed are just a few factors that are important for successful security monitoring. Battery cameras are optimized for balancing performance and power savings when operating. Powered cameras are always-on and are optimized for high performance and speed. Plugging in your cameras enables us to act faster, see further and communicate better without worrying about how much battery power is left.",
    },
    {
      question:
        "How can I provide feedback?",
      answer:
      "Provide frequent feedback using the Pre Crime Service mobile app.: Tap Settings in the bottom navigation menu and tap “Feedback” to access our feedback survey to provide general feedback, feature requests or contact us for support.",
    },
  ],
};

export const FrequentlyAskedQuestions2 = {
  title: "Frequently Asked Questions",
  videoTitle: "Audio Enhancer Installation Video",
  pageTitle: "Help Center",
  videoLink: `${S3_MEDIA_URL}v1/PCS_Quickstart_Guide_v08.pdf`,
  labelLinkPDF: "Click here for the Quick Start Guide.",
  videoPoster: `${S3_MEDIA_URL}v1/images/videoframe.png`,
  videoUrl: `${S3_MEDIA_URL}v1/DaggerPuckAssembly.mp4`,
  videoDescription: "Here’s some intro to get you started",
  mostAskedQuestions: [
    {
      question:
        "What is live outdoor monitoring?",
      answer:
        "Live outdoor monitoring is a new service concept, designed to help stop crime before it happens, outside your home. Outdoor monitoring is making home security proactive by combining innovative technology with trained SimpliSafe® monitoring agents.",
    },
    {
      question: "How long will the early access to the program last?",
      answer:
        "Early access will begin in the Fall of  2023 and will continue for about six months.",
    },
    {
      question: "How do I get started?",
      answer:
        "Click “Shop Now” and follow the simple steps. When prompted, download and log into the Pre Crime Service (PCS) mobile app using your SimpliSafe® login. You will need to accept the terms of our Outdoor Monitoring Participant Agreement. Following the activation and setup steps within the PCS mobile app to access all PCS features, such as the ability to choose the location(s) and camera(s) you want monitored by our live agents when your system is armed - home or away.  If you are having trouble finding or installing the PCS mobile app, please email PCS@simplisafe.com for help.",
    },
    {
      question: `I'm trying to log in to the PCS Mobile app and see a "Something Went Wrong" error message. How do I log in?`,
      answer: `To circumvent this message, press "Use password" under the Try again button to enter your password again, or "Use another method" and select Phone to complete Two Factor Authentication using a 6-digit code. You should then be able to log in.`,
      imageUrls: [
        `${S3_MEDIA_URL}v1/images/something-went-wrong.png`,
        `${S3_MEDIA_URL}v1/images/something-went-wrong2.png`,
        `${S3_MEDIA_URL}v1/images/something-went-wrong3.png`,
      ],
      imageDescription: "Something Went Wrong sample error screenshots",
    },
  ],
  allOtherQuestions: [
    {
      question: "How does it work?",
      answer:
        "When your security system is armed in Home or Away and a camera detects motion, advanced technology evaluates the video to determine if there is a person. A live, trained agent will review and determine if they should monitor all enabled outdoor cameras and doorbells at your location. The agent monitors, assists or deters people using two-way communication, sirens or flashing lights from your cameras. You may receive a notification and email from the live agent detailing the event.",
    },
    {
      question: "What is the difference between the Pre Crime and SimpliSafe® mobile apps?",
      answer:
        "Pre Crime App has new features, such as advanced AI, allows you to manage your outdoor monitoring service, arm your system and you can stream and manage all of your cameras. The SimpliSafe® App allows you to set up new, manage existing and control all of your sensors and cameras. It’s the main control center for your security system and intrusion alarms.",
    },
    {
      question: "How and when will I be billed?",
      answer:
        "Upon activating outdoor monitoring in the Pre Crime Service App, you will be charged a prorated fee for the add-on outdoor monitoring services for any remainder of that current billing cycle. After the month in which you activate outdoor monitoring, for each consecutive month, you will be billed the current rate for Fast Protect™ professional monitoring plan ($29.99) plus the add-on rate based upon the outdoor monitoring plan you select.",
    },
    {
      question: "Does this include my current SimpliSafe® monitoring plan?",
      answer:
        "Live outdoor monitoring is an additional service. You must have the Fast Protect™ professional monitoring plan ($29.99) in order to participate in this early access program.",
    },
    {
      question:
        "Do live agents monitor all of my cameras with outdoor monitoring?",
      answer:
      "No. We only monitor location(s) and camera(s) that you choose when your system is armed - home or away. You can change these settings for a location anytime in the settings menu of your Pre Crime Service mobile app.",
    },
    {
      question:
        "How does SimpliSafe® protect my privacy?",
      answer:
      "We are committed to protecting our customers' privacy and sensitive data just as vigilantly as their homes.. First, you control what cameras and when live agents monitor your location. Agents only have access to outdoor enabled cameras during a motion event that occurs during your subscription service hours when your system is armed. In addition, we use industry standard physical, technical and administrative controls to protect the confidentiality and security of biometric information which includes requiring agents to pass extensive background checks and work in a secured location.",
    },
    {
      question:
        "How does an agent know the difference between someone that does or does not belong on my property?",
      answer:
      "Agents are trained to monitor any unknown person except those with a profile  created by you for your location(s). Where permitted, PCS can use face matching to assist agents with matching faces during an event to avoid false interventions.",
    },
    {
      question:
        "Do live monitoring agents interact with people at my location?",
      answer:
      "When your system is turned on for outdoor monitoring and there is a motion event, agents are trained to observe and potentially engage with any person on your property. You can create profiles using the Pre Crime Service mobile app for friends and family for agents to ignore. The agent can watch, assist or deter people using two-way communication, sirens or flashing lights from your cameras.",
    },
    {
      question:
        "Why do I need the Outdoor Camera Accessory Kit?",
      answer:
      "The Kit is required for us to provide the service. The Audio Enhancer optimizes audio for agent communications and siren activation during an event. The Power Cable helps ensure that the camera is always on for fast detection and response.",
    },
    {
      question:
        "Do I need to install, set up or configure my camera(s) in any specific way?",
      answer:
      "Yes. Cameras that you want to be monitored should be mounted, powered and enabled for viewing within the Pre Crime Service mobile app. Review the Outdoor Camera Accessory Guide included in your shipment for more detailed information.",
    },
    {
      question:
        "Why do my cameras have to be plugged in?",
      answer:
      "Video, audio and speed are just a few factors that are important for successful security monitoring. Battery cameras are optimized for balancing performance and power savings when operating. Powered cameras are always-on and are optimized for high performance and speed. Plugging in your cameras enables us to act faster, see further and communicate better without worrying about how much battery power is left.",
    },
    {
      question:
        "How can I provide feedback?",
      answer:
      "Provide frequent feedback using the Pre Crime Service mobile app.: Tap Settings in the bottom navigation menu and tap “Feedback” to access our feedback survey to provide general feedback, feature requests or contact us for support.",
    },
  ],
};
