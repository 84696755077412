import React from "react";
import { Button } from "./styles";
import styled from "styled-components";
import colors from "../../../config/colors";

const ButtonSpaced = styled(Button)`
  padding: 0.5rem 1rem;
  background: ${colors.action};
  color: #fff;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  min-width: fit-content;
  max-width: 400px;
  width: 100%;
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  height: 45px;
  line-height: 25px;
`;

const LoginButton = ({ onClickCallback }: { onClickCallback?: () => void }) => {
  return <ButtonSpaced onClick={onClickCallback}>Verify</ButtonSpaced>;
};

export default LoginButton;
