import styled from "styled-components";
import { ReactComponent as StarIcon } from "../../../assets/star.svg";

const Container = styled.div`
  width: fit-content;
  gap: 1rem;
  display: flex;
`;

const FiveStars = () => {
  return (
    <Container>
      <StarIcon />
      <StarIcon />
      <StarIcon />
      <StarIcon />
      <StarIcon />
    </Container>
  );
};

export default FiveStars;
