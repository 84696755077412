import styled from "styled-components";
import colors from "../../../config/colors";
import { Link } from "react-router-dom";

export const MainContainer = styled.div`
  color: ${colors.primary};
  display: flex;
  flex-direction: column;
  margin: 0 10rem;

  @media (max-width: 1200px) {
    margin: 0 1rem;
  }
  @media (max-width: 820px) {
    margin: 0 2rem;
  }
  @media (max-width: 480px) {
    margin: 0 1rem;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 3rem;
  flex: 1;

  @media (max-width: 1200px) {
    gap: 1rem;
  }

  @media (max-width: 820px) {
    flex-direction: column;
    gap: 1rem;
  }

  @media (max-width: 480px) {
    gap: 1rem;
  }
`;

export const CardContainer = styled.div`
  flex-direction: column;
  border: 2px solid #bab1aa;
  border-radius: 15px;
  height: 100%;
  flex: 1;
  padding: 2rem 2.5rem;
  min-width: 300px;
  @media (max-width: 820px) {
    padding: 1rem;
  }
  @media (max-width: 550px) {
    min-width: calc(100% - 4rem);
  }
`;

export const Title = styled.h3`
  font-family: "SimpliSans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 1.5rem;
`;

export const MainTitle = styled(Title)`
  font-size: 48px;
  color: #0f2648;
  margin-bottom: 3.5rem;

  @media (max-width: 480px) {
    font-size: 38px;
    line-height: 2.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const Paragraph = styled.div`
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
`;

export const BoldText = styled.strong`
  font-family: "SimpliSans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

export const SignUpButton = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  border: none;
  background: ${colors.action};
  border-radius: 8px;
  color: #fff;
  padding: 1rem;
  min-width: 120px;
  cursor: pointer;
`;

export const RightLabel = styled.label`
  margin-left: 2rem;
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
`;

export const SmallText = styled.p`
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4rem;
  color: ${colors.primary};
  text-align: center;
  padding: 0 2rem;

  @media (max-width: 480px) {
    padding: 0 1rem;
  }
`;

export const EarlyAccessPromotion = styled.div`
  font-family: "SimpliSans";
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  color: ${colors.action};

  div.title {
    font-style: normal;
    font-size: 13px;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
  }

  div.early-access-promotion {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin: 0;
  }
`;
