import {
  ACCEPT_AGREEMENT_VERSION,
  PARTICIPATE_API,
} from "../config/environments";
import { UserAddress } from "./simplisafeApi.service";

export const getHeaders = (token: string) => {
  const headers = new Headers();
  headers.set("Authorization", `Bearer ${token}`);
  headers.set("Access-Control-Allow-Origin", "*");
  headers.set("Content-Type", "application/x-www-form-urlencoded");
  headers.set("Accept", "application/json");
  return headers;
};

export const getHeadersJSON = (token: string) => {
  const headers = new Headers();
  headers.set("Authorization", `Bearer ${token}`);
  headers.set("Access-Control-Allow-Origin", "*");
  headers.set("Content-Type", "application/json");
  headers.set("Accept", "application/json");
  return headers;
};

export const postAcceptAgreement = ({
  token,
  uid,
}: {
  uid: string;
  token: string;
}) =>
  fetch(
    `${PARTICIPATE_API}/accept-agreement?` +
      new URLSearchParams({
        uid,
        version: ACCEPT_AGREEMENT_VERSION,
      }),
    {
      method: "POST",
      headers: getHeaders(token),
      mode: "cors",
    }
  );

export const postIsInvited = ({
  email,
  token,
}: {
  email: string;
  token: string;
}) =>
  fetch(
    `${PARTICIPATE_API}/is-invited?` +
      new URLSearchParams({
        email: email,
      }),
    {
      method: "POST",
      headers: getHeaders(token),
      mode: "cors",
    }
  );


type MonitoringPlanPostData = {
  planSelection: string;
  sku: string;
};

export const postMonitoringPlan = (postData: MonitoringPlanPostData, token: string) =>
  fetch(
    `${PARTICIPATE_API}/monitoring-plan`,
    {
      method: "POST",
      headers: getHeadersJSON(token),
      mode: "cors",
      body: JSON.stringify(postData)
    }
  );

export const postMonitoringPlanCompleted = (postData: {
  orderNumber: string;
  orderPrice: number;
  token: string;
  oneTimeCode: string;
  orderQuantity: string;
  orderAddress: UserAddress;
}) =>
  fetch(
    `${PARTICIPATE_API}/monitoring-plan/complete-checkout/${postData.oneTimeCode}`,
    {
      method: "POST",
      headers: getHeadersJSON(postData.token),
      mode: "cors",
      body: JSON.stringify({
        orderNumber: postData.orderNumber,
        orderPrice: postData.orderPrice,
        orderQuantity: parseInt(postData.orderQuantity),
        orderAddress: postData.orderAddress,
      })
    }
  );

  export const postLogEmail = (postData: {
    email: string;
    iOSPlatform: boolean;
    token: string;
  }) =>
    fetch(
      `${PARTICIPATE_API}/log-email`,
      {
        method: "POST",
        headers: getHeadersJSON(postData.token),
        mode: "cors",
        body: JSON.stringify({
          email: postData.email, 
          platform: postData.iOSPlatform ? "ios" : "android"
        })
      }
    );
  