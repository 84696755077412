import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import GoogleAnalyticsService from "../../../services/google-analytics.service";
import { dispatchMetric } from "../../../services/metrics.service";
import {
  MainContainer,
  FlexContainer,
  CardContainer,
  EarlyAccessPromotion,
  MainTitle,
  Title,
  Paragraph,
  BoldText,
  ButtonsContainer,
  SignUpButton,
  RightLabel,
  SmallText,
} from "./styles";

interface Card {
  title: string;
  text: string;
  textBold?: string;
  actionButton: string;
  priceValue: string;
  pricePeriod: string;
  earlyAccessPromotion?: string;
}

interface TwoDetailsCardsProps {
  title?: string;
  card1: Card;
  card2: Card;
  notes: string;
}

const TwoDetailsCards = ({ title, card1, card2, notes }: TwoDetailsCardsProps) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const uid = user && user["http://simplisafe.com/uid"];
  const [token, setToken] = useState("");

  useEffect(() => {
    getAccessTokenSilently().then((value) => setToken(value));
  }, [getAccessTokenSilently]);

  const onJoinClick24Hours = (event: any) => {
    onJoinClick(event, "24_hours");
  };
  const onJoinClickOvernight = (event: any) => {
    onJoinClick(event, "overnight");
  };
  const onJoinClick = (event: any, actionType: string) => {
    (window as any).qualtricsNotNow = "false";
    GoogleAnalyticsService.dispatchButtonEvent(
      "shop-now_" + actionType,
      "Shop now button"
    );
    dispatchMetric({
      action: "action_button_shop_now_" + actionType,
      uid: uid,
      token,
    });
  };

  return (
    <>
      {title ? <MainTitle>{title}</MainTitle> : null}
      <MainContainer>
        <FlexContainer>
          <CardContainer>
            <Title>{card1.title}</Title>
            <Paragraph>
              {card1.text}
              {card1.textBold && <BoldText>{card1.textBold}</BoldText>}
            </Paragraph>
            {card1.earlyAccessPromotion ? (
              <EarlyAccessPromotion>
                <div className="title">Early access promotion</div>
                <div className="early-access-promotion">{card1.earlyAccessPromotion}</div>
              </EarlyAccessPromotion>
            ) : null}
            {/* <ButtonsContainer>
            <SignUpButton to={{ pathname: "/shop", search: "?choose=24hour" }} onClick={onJoinClick24Hours}>
              {card1.actionButton}
            </SignUpButton>
            <RightLabel>
              <BoldText>{card1.priceValue}</BoldText> {card1.pricePeriod}
            </RightLabel>
          </ButtonsContainer> */}
          </CardContainer>
          <CardContainer>
            <Title>{card2.title}</Title>
            <Paragraph>
              {card2.text}
              <BoldText>{card2.textBold || ""}</BoldText>
            </Paragraph>
            {card2.earlyAccessPromotion ? (
              <EarlyAccessPromotion>
                <div className="title">Early access promotion</div>
                <div className="early-access-promotion">{card2.earlyAccessPromotion}</div>
              </EarlyAccessPromotion>
            ) : null}
          {/* <ButtonsContainer>
            <SignUpButton to={{ pathname: "/shop", search: "?choose=overnight" }} onClick={onJoinClickOvernight}>
              {card2.actionButton}
            </SignUpButton>
            <RightLabel>
              <BoldText>{card2.priceValue}</BoldText> {card2.pricePeriod}
            </RightLabel>
          </ButtonsContainer> */}
          </CardContainer>
        </FlexContainer>
        <SmallText>{notes}</SmallText>
      </MainContainer>
    </>
  );
};

export default TwoDetailsCards;
