import styled from "styled-components";
import colors from "../../../config/colors";
import Card from "../../templates/Card";
import SectionTitle from "../../templates/SectionTitle";
import { HashLink as Link } from "react-router-hash-link";

export const InputAndroidDevice = styled.input`
  padding: 1rem;
  outline: 0;
  border: none;
  color: #000;
  border-color: #fff;
  border-radius: 0.5rem;
  min-width: 230px;
  line-height: 1.5rem;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 480px) {
    padding: 1rem 0.5rem;
    font-size: 16px;
    -webkit-text-size-adjust: 100%;
  }
`;

export const ThanksAndroidStepContainer = styled.div`
  margin-bottom: 3rem;
`;

export const HorizontalCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .email-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 820px) {
      flex-direction: column;
      margin-bottom: 2rem;
      padding: 0 1rem;

      button {
        margin-top: 1rem;
      }
    }

    @media (max-width: 480px) {
      padding: 0 0.5rem;
    }
  }
`;

export const HorizontalCenterRowContainer = styled(HorizontalCenterContainer)`
  max-width: 500px;
  margin-left: 1rem;
  align-items: flex-end;
  justify-content: flex-end;
  @media (max-width: 1200px) {
    margin-left: 0;
    align-items: center;
  }
`;

export const ButtonAndroidDevice = styled.button`
  background: ${colors.action};
  border: none;
  font-size: 1rem;
  padding: .4rem 0rem;
  color: #fff;
  font-weight: bold;
  border-radius: 0.5rem;
  min-width: 123px;
  height: 50px;
  cursor: pointer;
  font-family: SimpliSans;
  margin-left: 1rem;

  @media (max-width: 820px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const TextHelp = styled.p`
  font-family: "SimpliSans";
  font-size: 1rem;
  text-align: center;

  a {
    color: ${colors.action};
    text-decoration: none;
  }
`;

export const ImageThanksComponent = styled.img`
  height: 398px;
  width: 465px;
  align-self: center;
`;

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const RightContentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const StrongBold = styled.strong`
  font-family: "SimpliSans Bold";
  font-weight: bold;

  @media (max-width: 1200px) {
    text-align: center;
    margin-top: 2rem;
  }
`;

export const InstructionsText = styled.p`
  font-family: "SimpliSans";
  font-size: 1.2rem;

  @media (max-width: 1200px) {
    text-align: center;
    margin-top: 2rem;
  }
`;

export const Container = styled(Card)`
  background: #fff;
  margin-top: 6rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(SectionTitle)`
  text-align: left;
  font-family: "ArizonaSans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 57px;
  letter-spacing: -0.012em;
  text-align: center;
  margin: 0 4rem;
  @media (max-width: 820px) {
    padding: 0 2rem;
    margin: 1rem;
    padding: 1rem;
  }
`;

export const ShoppingMainTitle = styled(Title)`
  font-family: "SimpliSans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 3rem;
  color: #000;

  @media (max-width: 480px) {
    padding: 0;
    margin: 0;
  }
`;

export const Button = styled.button`
  background: ${colors.action};
  border: none;
  font-size: 1rem;
  padding: 0.5rem;
  color: #fff;
  font-weight: bold;
  border-radius: 0.5rem;
  min-width: 130px;
  cursor: pointer;
  font-family: "SimpliSans";
  margin-left: 1.5rem;
  @media (max-width: 820px) {
    margin-left: 0;
    margin-bottom: 1rem;
  }
`;

export const ButtonAndroidStepTwo = styled(Button)`
  padding: 1rem;
  background: ${colors.action};
  color: #fff;
  align-self: flex-end;
  font-size: 1rem;
  margin-top: 1rem;
`;

export const FixedHeightNavbar = styled.div`
  height: 68px;
`;

export const MobileMenuNavbar = styled.div`
  background: ${colors.primary};
  flex-direction: column;
  display: flex;
  padding: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  display: none;
  @media (max-width: 1200px) {
    display: flex;
  }
`;

export const AppDownloadInstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
`;

export const ShoppingDetailsContent = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  font-family: "SimpliSans";
  font-style: normal;
  color: ${colors.primary};
  text-align: center;
  padding: 0 4rem;
  margin: 0 0 2rem 0;
`;

export const ShoppingDetailsContentNext = styled(ShoppingDetailsContent)`
  width: 80%;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const RightContentContainerMobile = styled(RightContentContainer)`
  display: none;
  @media (max-width: 1200px) {
    display: block;
  }
`;

export const LeftContentContainerMobile = styled(RightContentContainerMobile)`
  cursor: pointer;
`;

export const AnchorsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AnchorLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: right;
  margin-right: 100px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:visited {
    text-decoration: none;
  }
`;
export const AnchorLinkMobile = styled(AnchorLink)`
  margin-bottom: 1rem;
  margin-left: 0.5rem;
`;

export const OrderCompletedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
  border-radius: 10px;
  padding: 2rem 4rem 4rem 4rem;
  text-align: center;

  .information {
    font-weight: 400;
    margin-top: 2rem;
    line-height: 2rem;
  }
  .legal {
    font-weight: 400;
    font-size: 16px;
    margin-top: 2rem;
  }

  @media (max-width: 820px) {
    padding: 1rem 1rem 3rem 1rem;
  }
`;
