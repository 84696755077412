import styled from "styled-components";
import colors from "../../../config/colors";

export const Button = styled.button`
    font-size: 1.2rem;
    background: ${colors.action};
    color: #fff;
    line-height: 1rem;
    padding: 1rem;
    margin: 0;
    border: none;
    cursor: pointer;
`;
