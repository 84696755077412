import React from "react";
import styled from "styled-components";
import GlobalStyle from "../../../globalStyles";
import Header from "../Header";
import Logo from "../Logo";
// import Disclaimer from "../Disclaimer";

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 820px) {
    margin-left: 1rem;
  }
`;

const AppTemplate = ({
  children,
  rightContent,
  leftContent,
  cleanRightView,
}: any) => {
  return (
    <>
      <GlobalStyle />
      <div className="App">
        <header className="App-header">
          {/* <Disclaimer /> */}
          <Header
            cleanView={
              <>
                <LogoContainer>
                  {leftContent}
                  <Logo />
                </LogoContainer>
                {cleanRightView}
              </>
            }
          >
            <LogoContainer>
              {leftContent}
              <Logo />
            </LogoContainer>
            {rightContent}
          </Header>
        </header>
        {children}
      </div>
    </>
  );
};

export default AppTemplate;
