import React from "react";
import Card from "../../templates/Card";
import { TopicCard, TopicRow } from "../TextToLeftAndImage/styles";
import {
  CardDiagonalRightSideDesktop,
  Container,
  Image,
  ImageSmallDesktop,
  RightContentTitle,
  TextSpaced,
  WhiteBoxRow,
} from "./styles";

const TextToRightAndImage = ({
  text,
  imageUrl,
  imageDescription,
  title,
  titleBreakLine,
  topics = [],
  useSmallImageDesktop = false,
  titleStyle = {},
  textStyle = {},
}: any) => {
  const ImgComponent = useSmallImageDesktop ? ImageSmallDesktop : Image;

  return (
    <Container>
      <ImgComponent style={{ borderRadius: '12px' }} src={imageUrl} alt={imageDescription} />
      <CardDiagonalRightSideDesktop>
        <RightContentTitle
          style={ titleStyle }
        >
          {title}
        </RightContentTitle>
        {!titleBreakLine ? null : (
          <RightContentTitle
            className="breakline"
            style={{
              paddingTop: 0,
              ...titleStyle,
            }}
          >
            {titleBreakLine}
          </RightContentTitle>
        )}
        <TextSpaced style={textStyle}>{text}</TextSpaced>
        {!topics.length ? null : (
          <WhiteBoxRow>
            <TopicCard>
              {topics.map((topic: { icon: any; text: string }) => {
                const CustomIcon = topic.icon;

                return (
                  <TopicRow key={topic.text}>
                    <CustomIcon />
                    {topic.text}
                  </TopicRow>
                );
              })}
            </TopicCard>
          </WhiteBoxRow>
        )}
      </CardDiagonalRightSideDesktop>
    </Container>
  );
};

export default TextToRightAndImage;
