import React, { useEffect, useState } from "react";
import Footer from "../../custom/Footer";
import AppTemplate from "../../templates/AppTemplate/AppTemplate";
import {
    ShoppingMainTitle,
    Container,
    AnchorsContainer,
    AnchorLink,
    AnchorLinkMobile,
    RightContentContainer,
    ShoppingDetailsContentNext,
    LeftContentContainerMobile,
    MobileMenuNavbar,
    FixedHeightNavbar,
    AppDownloadInstructionsContainer,
    StrongBold,
    InstructionsText,
    ButtonAndroidStepTwo,
    CenterContainer,
    ImageThanksComponent,
    TextHelp,
    InputAndroidDevice,
    ButtonAndroidDevice,
    HorizontalCenterContainer,
    HorizontalCenterRowContainer,
    ThanksAndroidStepContainer,
    OrderCompletedContainer,
} from "./styles";
import { ReactComponent as MenuIcon } from "../../../assets/menu.svg";
import integrateQualtricsFeedbackButtonVisibility from "../../../utils/integrateQualtricsFeedbackButtonVisibility";
import Header from "../../templates/Header";
import useValidateInvitedEmail from "../../hooks/useValidateInvitedEmail";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../templates/Loading";
import NotAuthorized from "../../templates/NotAuthorized";
import ReactGA from "react-ga4";
import { dispatchMetric } from "../../../services/metrics.service";
import { FixedHeightBlock, FixedOneRemHeightBlock } from "../../templates/Header/styles";
import InstructionsSection from "../../custom/InstructionsSection";
import { useSearchParams } from "react-router-dom";
import {
    postLogEmail,
    postMonitoringPlanCompleted,
} from "../../../services/auth.service";
import { SHOP_SELECTED_QUANTITY_KEY, SHOP_SELECTED_ADDRESS_KEY } from "../Shop/Shop";
import { UserAddress } from "../../../services/simplisafeApi.service";

const getOrderQuantityFromStorage = () => {
    try {
        return localStorage.getItem(SHOP_SELECTED_QUANTITY_KEY) || "1";
    } catch (err) {
        return "1";
    }
};

const getOrderAddressFromStorage = (): UserAddress | undefined => {
    try {
        const address = localStorage.getItem(SHOP_SELECTED_ADDRESS_KEY);
        if (address) {
            return JSON.parse(address);
        }
    } catch (err) {
        return;
    }
};

function tryGetSearchParamsOrderId(searchParams: URLSearchParams) {
    try {
        if (searchParams.get("orderId")) {
            return searchParams.get("orderId");
        }
    } catch (err) {
        return "-1";
    }
    return "0";
}
function tryGetSearchParamsOneTimeToken(searchParams: URLSearchParams) {
    try {
        if (searchParams.get("oneTimeToken")) {
            return searchParams.get("oneTimeToken");
        }
    } catch (err) {
        return "-1";
    }
    return "0";
}

function tryGetSearchParamsOrderPrice(searchParams: URLSearchParams): number {
    try {
        if (!searchParams.get("total")) {
            return 0;
        }
        return parseFloat(searchParams.get("total") || "0");
    } catch (err) {
        return 0;
    }
}

const iosQRCode = require("../../../assets/ios_qr.png");
const imageThanks = require("../../../assets/download-thanks.png");
const shopImageIOS = require("../../../assets/ios-shop.png");

type OrderInformation = {
    userId: number;
    planSelection: string;
    sku: string;
    oneTimeCode: string;
    date: string;
    order: {
        number: string;
        price: number;
        quantity: number;
        date: string;
    };
};

const OrderCallback = () => {
    const [searchParams] = useSearchParams();
    const orderIdFromParams = tryGetSearchParamsOrderId(searchParams);
    const oneTimeTokenFromParams = tryGetSearchParamsOneTimeToken(searchParams);
    const orderPriceFromParams = tryGetSearchParamsOrderPrice(searchParams);
    const { user, getAccessTokenSilently } = useAuth0();
    const uid = user && user["http://simplisafe.com/uid"];
    const [token, setToken] = useState("");
    const [iOSInstructionsCurrentStep, setIOSInstructionsCurrentStep] = useState(1);
    const [androidInstructionsCurrentStep, setAndroidInstructionsCurrentStep] =
        useState(1);
    const [androidEmailValue, setAndroidEmailValue] = useState("");
    const [iOSEmailValue, setiOSEmailValue] = useState("");
    const [loadingOrderInfo, setLoadingOrderInfo] = useState(true);
    const [orderInformation, setOrderInformation] = useState<OrderInformation | null>(
        null
    );
    const androidEmailInputRef = React.useRef<HTMLInputElement>(null);
    const iOSEmailInputRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        getAccessTokenSilently().then((value) => setToken(value));
    }, [getAccessTokenSilently]);

    const saveEmailAsync = async (email: string, iOSPlatform: boolean) => {
        const emailResponse = await postLogEmail({
            email: email,
            iOSPlatform: iOSPlatform,
            token,
        });
        const result = await emailResponse.json();
        if (result.success) {
            dispatchMetric({
                uid: uid,
                token,
                action: "@log_email_success",
            });
        }
    };

    const { isUserMailValid, isLoading } = useValidateInvitedEmail(user?.email || "");
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const toggleShowMobileMenu = (event: any) => {
        setShowMobileMenu((previous) => !previous);
        window.scrollTo(window.scrollX, window.scrollY - 1);
        window.scrollTo(window.scrollX, window.scrollY + 1);
    };
    const onIOSContinueFromStepOne = () => {
        if (iOSEmailInputRef.current?.validity.valid) {
            saveEmailAsync(iOSEmailValue, true);
            setIOSInstructionsCurrentStep(2);
        } else {
            iOSEmailInputRef.current?.reportValidity();
        }
    };
    const onIOSContinueFromStepTwo = () => {
        setIOSInstructionsCurrentStep(3);
    };
    const onAndroidContinueFromStepOne = () => {
        if (androidEmailInputRef.current?.validity.valid) {
            saveEmailAsync(androidEmailValue, false);
            setAndroidInstructionsCurrentStep(2);
        } else {
            androidEmailInputRef.current?.reportValidity();
        }
    };

    useEffect(() => {
        (window as any).userID = user?.email;
    }, [user?.email]);

    useEffect(() => {
        integrateQualtricsFeedbackButtonVisibility(true);
        if (uid && token) {
            ReactGA.send({ hitType: "pageview", page: "/order-callback" });
            dispatchMetric({
                action: "/order-callback",
                uid: uid,
                token,
            });
        }
    }, [uid, token]);

    useEffect(() => {
        integrateQualtricsFeedbackButtonVisibility(true);
        if (uid && token && oneTimeTokenFromParams) {
            const orderQuantity = getOrderQuantityFromStorage();
            const orderAddress = getOrderAddressFromStorage();

            if (!orderAddress) {
                console.error("Error completing the order. No address found.");
                window.location.href = "/shop";
                return;
            }
            postMonitoringPlanCompleted({
                token,
                orderNumber: `${orderIdFromParams}`,
                oneTimeCode: oneTimeTokenFromParams,
                orderPrice: orderPriceFromParams,
                orderQuantity: orderQuantity,
                orderAddress: orderAddress,
            }).then((response) => {
                // clear the local storage
                localStorage.removeItem(SHOP_SELECTED_QUANTITY_KEY);
                localStorage.removeItem(SHOP_SELECTED_ADDRESS_KEY);
                if (!response.ok) {
                    console.error(
                        "Error completing the order. Status code:",
                        response.status
                    );
                    window.location.href = "/shop";
                    return;
                }
                response.json().then((data) => {
                    setOrderInformation(data);
                    setLoadingOrderInfo(false);
                });
            });
        }
    }, [uid, token, orderIdFromParams, oneTimeTokenFromParams, orderPriceFromParams]);

    if (isLoading || loadingOrderInfo) {
        return <Loading />;
    }
    if (!isUserMailValid) {
        return <NotAuthorized />;
    }

    return (
        <AppTemplate
            leftContent={
                <LeftContentContainerMobile onClick={toggleShowMobileMenu}>
                    <MenuIcon />
                </LeftContentContainerMobile>
            }
            cleanRightView={
                <RightContentContainer>
                    <AnchorsContainer>
                        <AnchorLink
                            onClick={(evt) => {
                                (window as any).window.qualtricsNotNow = "false";
                                dispatchMetric({
                                    uid: uid,
                                    token,
                                    action: "#features",
                                });
                            }}
                            to="/#features"
                        >
                            Features
                        </AnchorLink>
                        <AnchorLink
                            onClick={(evt) => {
                                (window as any).window.qualtricsNotNow = "false";
                                dispatchMetric({
                                    uid: uid,
                                    token,
                                    action: "#what-to-expect",
                                });
                            }}
                            to="/#what-to-expect"
                        >
                            What to expect
                        </AnchorLink>
                        <AnchorLink
                            onClick={(evt) => {
                                (window as any).window.qualtricsNotNow = "false";
                                dispatchMetric({
                                    uid: uid,
                                    token,
                                    action: "#faq",
                                });
                            }}
                            to="/#faq"
                        >
                            FAQ
                        </AnchorLink>
                    </AnchorsContainer>
                </RightContentContainer>
            }
            rightContent={
                <RightContentContainer>
                    <AnchorsContainer>
                        <AnchorLink
                            onClick={(evt) => {
                                (window as any).window.qualtricsNotNow = "false";
                                dispatchMetric({
                                    uid: uid,
                                    token,
                                    action: "#features",
                                });
                            }}
                            to="/#features"
                        >
                            Features
                        </AnchorLink>
                        <AnchorLink
                            onClick={(evt) => {
                                (window as any).window.qualtricsNotNow = "false";
                                dispatchMetric({
                                    uid: uid,
                                    token,
                                    action: "#what-to-expect",
                                });
                            }}
                            to="/#what-to-expect"
                        >
                            What to expect
                        </AnchorLink>
                        <AnchorLink
                            onClick={(evt) => {
                                (window as any).window.qualtricsNotNow = "false";
                                dispatchMetric({
                                    uid: uid,
                                    token,
                                    action: "#faq",
                                });
                            }}
                            to="/#faq"
                        >
                            FAQ
                        </AnchorLink>
                    </AnchorsContainer>
                </RightContentContainer>
            }
        >
            {!showMobileMenu ? null : (
                <>
                    <FixedHeightNavbar />
                    <Header containerStyle={{ height: 200 }}>
                        <MobileMenuNavbar>
                            <AnchorLinkMobile
                                to="/#features"
                                onClick={(evt) => {
                                    (window as any).window.qualtricsNotNow = "false";
                                    toggleShowMobileMenu(null);
                                    dispatchMetric({
                                        uid: uid,
                                        token,
                                        action: "#features",
                                    });
                                }}
                            >
                                Features
                            </AnchorLinkMobile>
                            <AnchorLinkMobile
                                to="/#what-to-expect"
                                onClick={(evt) => {
                                    (window as any).window.qualtricsNotNow = "false";
                                    toggleShowMobileMenu(null);
                                    dispatchMetric({
                                        uid: uid,
                                        token,
                                        action: "#what-to-expect",
                                    });
                                }}
                            >
                                What to expect
                            </AnchorLinkMobile>
                            <AnchorLinkMobile
                                to="/#faq"
                                onClick={(evt) => {
                                    (window as any).window.qualtricsNotNow = "false";
                                    toggleShowMobileMenu(null);
                                    dispatchMetric({
                                        uid: uid,
                                        token,
                                        action: "#faq",
                                    });
                                }}
                            >
                                FAQ
                            </AnchorLinkMobile>
                        </MobileMenuNavbar>
                    </Header>
                </>
            )}
            <FixedHeightBlock />

            <Container>
                <OrderCompletedContainer>
                    <ShoppingMainTitle>
                        Thanks for your Outdoor Monitoring order!
                    </ShoppingMainTitle>
                    <div className="information">
                        You’ll receive an email with your order details shortly. You have
                        selected the {orderInformation?.planSelection} outdoor monitoring
                        service plan - you can change or confirm this when you activate
                        your outdoor monitoring service in the Pre Crime Service mobile
                        app.
                    </div>
                    {/* <div className="legal">
            Requires Fast Protect™ monitoring subscription at $29.99. Your outdoor
            monitoring subscription and billing starts when you activate your service in
            the Pre Crime Service mobile app. There are no long-term contracts — start,
            stop or change plans any time.
          </div> */}
                </OrderCompletedContainer>
                <>
                    <FixedHeightBlock />
                    <ImageThanksComponent src={imageThanks} />
                    <FixedHeightBlock />
                    <CenterContainer>
                        <ShoppingMainTitle>
                            Download our mobile app to get started today!
                        </ShoppingMainTitle>
                        <FixedOneRemHeightBlock />
                        <ShoppingDetailsContentNext>
                            Please follow these steps to download the Outdoor Monitoring
                            Mobile App to activate your service. Please have your mobile
                            device handy. These instructions will also be sent to your
                            email.
                        </ShoppingDetailsContentNext>
                    </CenterContainer>
                    <FixedHeightBlock />
                    <AppDownloadInstructionsContainer>
                        <InstructionsSection
                            image={
                                iOSInstructionsCurrentStep === 3
                                    ? shopImageIOS
                                    : iOSInstructionsCurrentStep === 2
                                    ? iosQRCode
                                    : null
                            }
                            imageWidth={iOSInstructionsCurrentStep === 2 ? 250 : "auto"}
                            imageHeight={iOSInstructionsCurrentStep === 2 ? 250 : "auto"}
                            title="Click here if you have an iOS device"
                        >
                            {iOSInstructionsCurrentStep === 1 ? (
                                <HorizontalCenterContainer>
                                    <StrongBold>
                                        First, enter your iCloud email address.
                                    </StrongBold>
                                    <div className="email-input-container">
                                        <InputAndroidDevice
                                            value={iOSEmailValue}
                                            onChange={(e) =>
                                                setiOSEmailValue(e.target.value)
                                            }
                                            placeholder="enter iCloud account email"
                                            type="email"
                                            ref={iOSEmailInputRef}
                                            required
                                        />
                                        <ButtonAndroidDevice
                                            onClick={onIOSContinueFromStepOne}
                                        >
                                            Submit
                                        </ButtonAndroidDevice>
                                    </div>
                                </HorizontalCenterContainer>
                            ) : iOSInstructionsCurrentStep === 2 ? (
                                <HorizontalCenterRowContainer>
                                    <StrongBold>
                                        Next, you will need to download the free
                                        TestFlight app from Apple. This will allow you to
                                        download our Outdoor Monitoring iOS App.
                                    </StrongBold>
                                    <InstructionsText>
                                        From your phone, click this link or scan the QR
                                        Code to download TestFlight. You can also search
                                        for TestFlight in the App Store.
                                    </InstructionsText>
                                    <div>
                                        <ButtonAndroidStepTwo
                                            onClick={onIOSContinueFromStepTwo}
                                        >
                                            I have downloaded TestFlight
                                        </ButtonAndroidStepTwo>
                                    </div>
                                </HorizontalCenterRowContainer>
                            ) : (
                                <HorizontalCenterRowContainer>
                                    <StrongBold>
                                        Lastly, look out for an email from TestFlight.
                                    </StrongBold>
                                    <br />
                                    <InstructionsText>
                                        Click on the link in this email to begin
                                        downloading the Outdoor Monitoring Pre Crime
                                        Service App!
                                    </InstructionsText>
                                    <br />
                                </HorizontalCenterRowContainer>
                            )}
                        </InstructionsSection>
                        <InstructionsSection title="Click here if you have an Android device">
                            {androidInstructionsCurrentStep === 1 ? (
                                <HorizontalCenterContainer>
                                    <StrongBold>
                                        First, enter your Google Account email address.
                                    </StrongBold>
                                    <br />
                                    <div className="email-input-container">
                                        <InputAndroidDevice
                                            value={androidEmailValue}
                                            onChange={(e) =>
                                                setAndroidEmailValue(e.target.value)
                                            }
                                            placeholder="enter Google account email"
                                            type="email"
                                            ref={androidEmailInputRef}
                                            required
                                        />
                                        <ButtonAndroidDevice
                                            onClick={onAndroidContinueFromStepOne}
                                        >
                                            Submit
                                        </ButtonAndroidDevice>
                                    </div>
                                </HorizontalCenterContainer>
                            ) : (
                                <ThanksAndroidStepContainer>
                                    <StrongBold>
                                        Thanks! Further download details will be sent to
                                        your email within the next 24 hours.
                                    </StrongBold>
                                </ThanksAndroidStepContainer>
                            )}
                        </InstructionsSection>
                    </AppDownloadInstructionsContainer>
                    <TextHelp>
                        Need Help? Email{" "}
                        <a href="mailto:pcs@simplisafe.com">pcs@simplisafe.com</a>{" "}
                    </TextHelp>
                </>
            </Container>

            <Footer />
        </AppTemplate>
    );
};

export default OrderCallback;
