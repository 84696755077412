import styled from "styled-components";
import colors from "../../../config/colors";
import { Link } from "react-router-dom";

export const RightContentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const FixedHeightNavbar = styled.div`
  height: 68px;
`;

export const MobileMenuNavbar = styled.div`
  background: ${colors.primary};
  flex-direction: column;
  display: flex;
  padding: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  display: none;
  @media (max-width: 1200px) {
    display: flex;
  }
`;

export const RightContentContainerMobile = styled(RightContentContainer)`
  display: none;
  @media (max-width: 1200px) {
    display: block;
  }
`;

export const LeftContentContainerMobile = styled(RightContentContainerMobile)`
  cursor: pointer;
`;

export const AnchorsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AnchorLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: right;
  margin-right: 100px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:visited {
    text-decoration: none;
  }
`;
export const AnchorLinkMobile = styled(AnchorLink)`
  margin-bottom: 1rem;
  margin-left: 0.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1200px) {
    margin-right: 2rem;
  }
  @media (max-width: 820px) {
    margin-right: 1rem;
  }
`;

export const Button = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.action};
  border: none;
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 0.5rem;
  min-width: 150px;
  cursor: pointer;
  margin-left: 1rem;
  margin-right: 2rem;
  height: 40px;
  @media (max-width: 820px) {
    min-width: 0;
    width: 100%;
  }
`;

export const ButtonMobile = styled(Button)`
  margin-left: 0;
`;
