import styled from "styled-components";
import colors from "../../../config/colors";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  z-index: 3000000000;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  max-width: 360px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  background-color: ${colors.primary};
  padding: 1rem 0;
  img {
    height: 50px;
  }
`;

const Text = styled.p`
  font-family: "SimpliSans Bold";
  margin-bottom: 30px;
  text-align: center;

  a {
    color: ${colors.action};
    text-decoration: none;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
`;

export const Button = styled.button`
  font-family: "SimpliSans";
  font-size: 1rem;
  background: ${colors.action};
  border: none;
  padding: 0.7rem 1rem;
  color: #fff;
  border-radius: 0.5rem;
  min-width: 150px;
  cursor: pointer;

  &:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(1.1);
  }

  &:first-child {
    margin-right: 20px;
  }

  &.secondary {
    background: #fff;
    color: ${colors.disabled};
    border: 1px solid ${colors.disabled};
  }
`;

export { Overlay, Popup, LogoContainer, Text, ActionButtons };
