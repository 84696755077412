import styled from "styled-components";
import colors from "../../../config/colors";
import SectionTitle from "../../templates/SectionTitle";

export const LinkFromVideo = styled.a`
  font-size: 1.2rem;
  color: ${colors.action};
  line-height: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media (max-width: 1200px) {
    margin-bottom: 1rem;
  }
  @media (max-width: 820px) {
    margin-bottom: 2rem;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 6rem;
  @media (max-width: 820px) {
    padding: 0 1rem;
    align-items: center;
  }
`;

export const MainTitle = styled(SectionTitle)`
  margin-top: 1rem;
  text-align: initial;
  padding-left: 0;
  font-size: 42px;
  line-height: 36px;
`;

export const VideoTitle = styled(SectionTitle)`
  margin-top: 2rem;
  text-align: initial;
  padding-left: 0;
  @media (max-width: 820px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const Video = styled.video`
  max-width: 100%;
  height: 600px;
  @media (max-width: 1700px) {
    max-width: 900px;
  }
  @media (max-width: 970px) {
    max-width: 100%;
  }
  @media (max-width: 820px) {
    max-width: calc(100vw - 4rem);
    height: auto;
  }
  @media (max-width: 550px) {
    margin-bottom: 2rem;
  }
  border-radius: 0.5rem;
`;
