import React, { useEffect, useState } from "react";
import App from "../../templates/AppTemplate/AppTemplate";
import { useAuth0 } from "@auth0/auth0-react";
import NotAuthorized from "../../templates/NotAuthorized";
import Loading from "../../templates/Loading";
import integrateQualtricsFeedbackButtonVisibility from "../../../utils/integrateQualtricsFeedbackButtonVisibility";
import ReactGA from "react-ga4";
import FrequentlyAskedQuestions from "../../custom/FrequentlyAskedQuestions";
import { FrequentlyAskedQuestions2 } from "../../../config/textConfig";
import {
  Video,
  VideoContainer,
  VideoTitle,
  LinkFromVideo,
  MainTitle,
} from "./styles";
import { dispatchMetric } from "../../../services/metrics.service";
import { FixedHeightBlock } from "../../templates/Header/styles";

const Help = () => {
  const { user, isLoading, getAccessTokenSilently } = useAuth0();
  const uid = user && user["http://simplisafe.com/uid"];
  const [token, setToken] = useState("");

  useEffect(() => {
    getAccessTokenSilently().then((value) => setToken(value));
  }, [getAccessTokenSilently]);

  useEffect(() => {
    integrateQualtricsFeedbackButtonVisibility(false);
    if (uid && token) {
      ReactGA.send({ hitType: "pageview", page: "/help" });
      dispatchMetric({
        action: "/help",
        uid: uid,
        token,
      });
    }
  }, [uid, token]);

  if (isLoading) {
    return <Loading />;
  }
  if (!user || !user.email) {
    return <NotAuthorized />;
  }

  return (
    <App>
      <FixedHeightBlock />
      <VideoContainer>
        <MainTitle>{FrequentlyAskedQuestions2.pageTitle}</MainTitle>
        <LinkFromVideo href={FrequentlyAskedQuestions2.videoLink}>
          {FrequentlyAskedQuestions2.labelLinkPDF}
        </LinkFromVideo>
        <VideoTitle>{FrequentlyAskedQuestions2.videoTitle}</VideoTitle>
        <Video
          preload="auto"
          poster={FrequentlyAskedQuestions2.videoPoster}
          src={FrequentlyAskedQuestions2.videoUrl}
          aria-label={FrequentlyAskedQuestions2.videoDescription}
          controls
        />
      </VideoContainer>
      <FrequentlyAskedQuestions {...FrequentlyAskedQuestions2} />
    </App>
  );
};

export default Help;
