import { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { postAcceptAgreement } from "../../services/auth.service";
import { ACCEPT_AGREEMENT_VERSION } from "../../config/environments";

export const AGREEMENT_LOCAL_STORAGE_KEY = `accepted-agreement-${ACCEPT_AGREEMENT_VERSION}`;

const useAcceptAgreement = () => {
  const [agreementAccepted, setAgreementAccepted] = useState(() => {
    const localStorageAgreed = localStorage.getItem(AGREEMENT_LOCAL_STORAGE_KEY);
    return Boolean(localStorageAgreed);
  });
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [token, setToken] = useState("");
  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then((value) => setToken(value));
  }, [getAccessTokenSilently]);

  const acceptAgreement = useCallback(() => {
    setIsSubmiting(true);
    return postAcceptAgreement({
      uid: user?.["http://simplisafe.com/uid"],
      token,
    })
      .then((req) => req.json())
      .then((res) => {
        const newValue = Boolean(res);
        setAgreementAccepted(newValue);
        localStorage.setItem(AGREEMENT_LOCAL_STORAGE_KEY, `${newValue}`);
      })
      .catch(() => {
        setAgreementAccepted(false);
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  }, [token, user]);

  return {
    agreementAccepted,
    isSubmiting,
    acceptAgreement,
  };
};

export default useAcceptAgreement;
