import React, { useEffect, useState } from "react";
import { StickyContainer, Container, FixedHeightBlock } from "./styles";

const useStickyContainer = () => {
  const [shouldUse, setShouldUse] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = (e: any) => {
    const scrollTop = window.scrollY;
    setShouldUse(scrollTop >= 50);
  };

  return {
    shouldUse,
  };
};

const Header = ({ children, cleanView, containerStyle }: any) => {
  const stickyConfig = useStickyContainer();

  return stickyConfig.shouldUse ? (
    <FixedHeightBlock>
      <StickyContainer>{cleanView || children}</StickyContainer>
    </FixedHeightBlock>
  ) : (
    <Container style={containerStyle}>{children}</Container>
  );
};

export default Header;
