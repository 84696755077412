import styled from "styled-components";
import colors from "../../../config/colors";

export const Paragraph = styled.p`
  font-family: "SimpliSans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 30px;
  color: ${colors.primary};
  padding: 1rem;
  margin: 0;
  letter-spacing: -0.012em;
  text-align: initial;
`;
