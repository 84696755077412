import { useLocation } from "react-router-dom";
import { REDIRECT_AFTER_LOGIN_LOCAL_STORAGE_KEY } from "./Router";
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
  authenticated: boolean;
  children: any;
  redirectBackAfterLogin?: boolean;
};

const ProtectedRoute = ({
  authenticated = false,
  children,
  redirectBackAfterLogin = false,
}: Props) => {
  const { loginWithRedirect } = useAuth0();

  const currentRoute = useLocation();

  if (!authenticated) {
    if (redirectBackAfterLogin) {
      console.log(
        "Unauthorized, redirecting to login page. The user will be redirected back to this page after login"
      );
      localStorage.setItem(
        REDIRECT_AFTER_LOGIN_LOCAL_STORAGE_KEY,
        currentRoute.pathname + currentRoute.search
      );
    }
    // if current route is /logout, wait for the logout to complete before redirecting to login
    if (currentRoute.pathname === "/logout") {
      setTimeout(() => {
        loginWithRedirect();
        return null;
      }, 1000);
    } else {
      loginWithRedirect();
      return null;
    }
  }

  return children;
};

export default ProtectedRoute;
