import React from "react";
import FiveStars from "./FiveStars";
import styled from "styled-components";

const MainReviewContainer = styled.div`
  background: #0f2648;
  padding: 3rem 4rem;
  border-radius: 20px;
  @media(max-width: 1200px) {
    margin: auto 2rem;
  }

  @media(max-width: 480px) {
    display: flex;
    padding: 2rem 0.8rem 0.8rem 1.5rem;
    margin: 0;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    border-radius: 0;
  }
`;

const SecondaryReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  @media (max-width: 1200px) {
    width: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const SecondaryReviewText = styled.p`
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.245em;
  color: #000000;
`;

const OtherReviewsContainer = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 3rem;
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
`;

const MainContainer = styled.div`
  padding: 3rem;
  @media (max-width: 1200px) {
    padding: 3rem 0;
  }
`;

const VerticalLine = styled.div`
  height: 211px;
  border-right: 1px solid black;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const MainReviewText = styled.p`
  font-weight: 800;
  font-family: "SimpliSans Bold";
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.025em;
  color: #ffffff;
  mix-blend-mode: normal;
`;

const MainReviewReferenceText = styled(MainReviewText)`
  font-family: "SimpliSans";
  font-weight: 600;
`;

function addQuotes(text: string) {
  return `“${text}”`;
}

const Reviews = (props: {
  mainReviewText: string;
  mainReviewReferenceText: string;
  mainReviewStars: number;
  otherReviews: {
    reviewText: string;
    reviewStars: number;
    reviewAuthor: string;
  }[];
}) => {
  const { mainReviewText, mainReviewStars, mainReviewReferenceText, otherReviews } = props;
  const mainText = addQuotes(mainReviewText);
  const review0 = addQuotes(otherReviews[0].reviewText);
  const review1 = addQuotes(otherReviews[1].reviewText);
  const review2 = addQuotes(otherReviews[2].reviewText);

  return (
    <MainContainer>
      <MainReviewContainer>
        <FiveStars />
        <MainReviewText>{mainText}</MainReviewText>
        <MainReviewReferenceText>{mainReviewReferenceText}</MainReviewReferenceText>
      </MainReviewContainer>

      <OtherReviewsContainer>
        <SecondaryReviewContainer key={`${otherReviews[0].reviewText}_0`}>
          <FiveStars />
          <SecondaryReviewText>{review0}</SecondaryReviewText>
          <SecondaryReviewText>
            {otherReviews[0].reviewAuthor}
          </SecondaryReviewText>
        </SecondaryReviewContainer>
        <VerticalLine />
        <SecondaryReviewContainer key={`${otherReviews[1].reviewText}_1`}>
          <FiveStars />
          <SecondaryReviewText>{review1}</SecondaryReviewText>
          <SecondaryReviewText>
            {otherReviews[1].reviewAuthor}
          </SecondaryReviewText>
        </SecondaryReviewContainer>
        <VerticalLine />
        <SecondaryReviewContainer key={`${otherReviews[2].reviewText}_2`}>
          <FiveStars />
          <SecondaryReviewText>{review2}</SecondaryReviewText>
          <SecondaryReviewText>
            {otherReviews[2].reviewAuthor}
          </SecondaryReviewText>
        </SecondaryReviewContainer>
      </OtherReviewsContainer>
    </MainContainer>
  );
};

export default Reviews;
