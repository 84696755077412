import React from "react";
import { S3_MEDIA_URL } from "../../../config/environments";
import {
  CardWithLightBackground,
  TopicNumberCard,
  LegendNumber,
  TopicDescription,
  TopicTitle,
  SmallText,
  Title,
  TopicsContainer,
  SmallImgIcon,
} from "./styles";

const getIcon = ({ Icon, iconSrc }: any) => {
  if (Icon) {
    return <Icon />;
  }
  if (iconSrc) {
    return <SmallImgIcon src={`${S3_MEDIA_URL}${iconSrc}`} alt="Icon" />;
  }
  return null;
};

const TitleAndTopicNumbers = ({
  title,
  topics = [],
  titleStyle = {},
  topicsContainerStyle = {},
  descriptionStyle = {},
  backgroundColor = null,
}: any) => (
  <CardWithLightBackground style={{ background: backgroundColor || "#fff" }}>
    <Title style={titleStyle}>{title}</Title>

    <TopicsContainer style={topicsContainerStyle}>
      {topics.map(
        (
          { title, text, notes, Icon, iconSrc, minWidth }: any,
          index: number
        ) => (
          <TopicNumberCard key={`icon-${index}`}>
            <div>
              {!Icon && !iconSrc ? (
                <LegendNumber> {index + 1}</LegendNumber>
              ) : (
                getIcon({ Icon, iconSrc })
              )}
              <TopicTitle>{title}</TopicTitle>
              <TopicDescription style={{ ...descriptionStyle }}>
                {text}
              </TopicDescription>
            </div>
            {!notes ? null : <SmallText>{notes}</SmallText>}
          </TopicNumberCard>
        )
      )}
    </TopicsContainer>
  </CardWithLightBackground>
);

export default TitleAndTopicNumbers;
