import React, { PropsWithChildren, useState } from "react";
import {
  ListItem,
  ListInnerContainer,
  Paragraph,
  ImgComponent,
  ImagesContainer,
} from "./styles";
import { ReactComponent as ArrowDownIcon } from "../../../assets/arrow-down.svg";

const ExpandableListItem = ({
  children,
  expandedContent,
  imageUrl,
  imageUrls,
  imageDescription,
}: PropsWithChildren & {
  expandedContent: any;
  imageUrl?: string;
  imageUrls?: string[];
  imageDescription?: string;
}) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = (event: any) => {
    setExpanded((newValue) => !newValue);
    event.preventDefault();
  };
  const stopPropagation = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <ListItem onClick={toggleExpanded}>
      <ListInnerContainer>
        {children}
        <ArrowDownIcon
          style={
            expanded
              ? {
                  transform: "rotate(180deg)",
                }
              : {}
          }
        />
      </ListInnerContainer>
      {expanded ? (
        <>
          <Paragraph onClick={stopPropagation}>{expandedContent}</Paragraph>
          {!imageUrl ? null : (
            <ImgComponent src={imageUrl} alt={imageDescription} />
          )}
          {!imageUrls || !imageUrls.length ? null : (
            <ImagesContainer>
              {imageUrls.map(url => (
                <ImgComponent key={url} src={url} alt={imageDescription} />
              ))}
            </ImagesContainer>
          )}
        </>
      ) : null}
    </ListItem>
  );
};

export default ExpandableListItem;
