import styled from "styled-components";
import colors from "../../../config/colors";
import Card from "../../templates/Card";
import Text from "../../templates/Text";
import SectionTitle from "../../templates/SectionTitle";
import Row from "../../templates/Row";

export const Paragraph = styled(Text)`
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
`;

export const Title = styled(SectionTitle)`
  margin-bottom: 2rem;
  text-align: center;
  @media(max-width: 800px) {
    text-align: initial;
    padding: 0;
  }

  @media(max-width: 480px) {
    line-height: 2.5rem;
  }
`;

export const CardWithLightBackground = styled(Card)`
  margin: 2rem 3rem;
  padding: 2rem 0;
  border-radius: 0.5rem;
  margin-bottom: 0;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    margin: 2rem;
  }
  @media (max-width: 820px) {
  }
  @media (max-width: 480px) {
    margin: 0;
    padding: 1rem;
  }
`;

export const TopicDescription = styled(Paragraph)`
  padding-top: 0;
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  margin: 0;
  margin-top: 1rem;
  text-align: left;
  flex: 1;
  padding-left: 0;
`;

export const TopicTitle = styled(Paragraph)`
  padding: 0;
  font-family: "SimpliSans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  text-align: initial;
  flex: 1;
`;

export const SmallText = styled(TopicDescription)`
  font-size: 0.8rem;
  line-height: 1rem;
`;

export const TopicNumberCard = styled(Card)`
  flex: 1 0 21%;
  margin: 0;
  padding: 0 20px;
  @media (max-width: 1200px) {
    flex-basis: 45%;
  }
  @media (max-width: 820px) {
    flex-basis: 100%;
    padding: 0;
  }
`;

export const TopicsContainer = styled(Row)`
  margin: 0;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 2rem;

  @media (max-width: 820px) {
    padding: 0;
  }
`;

export const SmallImgIcon = styled.img`
  width: 36px;
  height: 36px;
`;

export const LegendNumber = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background: ${colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  @media (max-width: 550px) {
    margin-left: 0;
  }
`;
