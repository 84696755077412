import styled from "styled-components";
import colors from "../../../config/colors";

export const Container = styled.div`
  background: ${colors.primary};
  margin: 0;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  height: 80px;
  justify-content: space-between;
  @media (max-width: 1200px) {
    padding-left: 1rem;
  }
  @media (max-width: 820px) {
    padding-left: 0;
    height: 70px;
  }
`;

export const FixedHeightBlock = styled.div`
  height: 60px;

  @media (max-width: 820px) {
    height: 70px;
  }
`;

export const FixedOneRemHeightBlock = styled(FixedHeightBlock)`
  height: 1rem;
`;

export const StickyContainer = styled.div`
  position: fixed;
  top: 10;
  left: 0;
  width: 100vw;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  // animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
  background: ${colors.primary};
  margin: 0;
  display: flex;
  justify-content: space-between;
  // animation: fadeIn .5s ease-in;
`;

