import debounce from "lodash.debounce";

const changeFeedbackButtonVisibility = (isVisible = false) => {
  document.head.insertAdjacentHTML(
    "beforeend",
    `<style>#QSIFeedbackButton-btn { display: ${
      isVisible ? "block" : "none"
    }; }</style>`
  );
};

let intervalIDChangeFeedbackButtonVisibilityVisible: any;
const integrateQualtricsFeedbackButtonVisibility = debounce(
  (isVisible = true) => {
    if (isVisible) {
      intervalIDChangeFeedbackButtonVisibilityVisible = setTimeout(() => {
        changeFeedbackButtonVisibility(true);
      }, 1500);
    } else {
      clearInterval(intervalIDChangeFeedbackButtonVisibilityVisible);
      changeFeedbackButtonVisibility(false);
    }
  },
  500
);

export default integrateQualtricsFeedbackButtonVisibility;
