import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import colors from "../../../config/colors";
import GoogleAnalyticsService from "../../../services/google-analytics.service";
import { dispatchMetric } from "../../../services/metrics.service";
import {
    CardDiagonalLeftSideDesktop,
    Container,
    Image,
    LeftContentTitle,
    TextSpaced,
    Button,
    SecondaryButton,
    ActionButtonsContainer,
    BackgroundImage,
} from "./styles";
import { ShopButton } from "../TextToLeftAndImage/styles";

const TextToLeftAndImageFullBackground = ({
    text,
    textBreakLine = null,
    imageUrl,
    imageSmallUrl,
    imageDescription,
    actionButton = null,
    secondaryButton = null,
    title,
    secondary = false,
    darkContainer = false,
    cardStyle = {},
    titleStyle = {},
    textStyle = {},
    innerCardStyle = {},
    textToRight = false,
}: any) => {
    const { user, getAccessTokenSilently } = useAuth0();
    const uid = user && user["http://simplisafe.com/uid"];
    const [token, setToken] = useState("");

    useEffect(() => {
        getAccessTokenSilently().then((value) => setToken(value));
    }, [getAccessTokenSilently]);

    const onJoinClick = (event: any) => {
        (window as any).qualtricsNotNow = "false";
        GoogleAnalyticsService.dispatchButtonEvent("shop-now", "Shop now button");
        dispatchMetric({
            action: "action_button_shop_now",
            uid: uid,
            token,
        });
    };
    const onNotNowClick = (event: any) => {
        (window as any).window.qualtricsNotNow = "true";
        document.dispatchEvent(new CustomEvent("qualtrics_action_button_not_now"));
        GoogleAnalyticsService.dispatchButtonEvent("not-now", "Not now button");
        GoogleAnalyticsService.dispatchButtonEvent("join-now", "Join now button");
        dispatchMetric({
            action: "qualtrics_action_button_not_now",
            uid: uid,
            token,
        });
        event.preventDefault();
    };

    return (
        <>
            <Container textToRight={textToRight} className={darkContainer ? "dark" : ""}>
                <BackgroundImage src={imageUrl} alt={imageDescription} />
                <CardDiagonalLeftSideDesktop
                    style={{ ...innerCardStyle }}
                    textToRight={textToRight}
                >
                    <LeftContentTitle secondary={secondary}>{title}</LeftContentTitle>
                    <TextSpaced
                        secondary={secondary}
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                    {!textBreakLine ? null : (
                        <TextSpaced className="breakline" secondary={secondary}>
                            {textBreakLine}
                        </TextSpaced>
                    )}
                    {!actionButton ? null : (
                        <ActionButtonsContainer>
                            <ShopButton to="/shop" onClick={onJoinClick}>
                                {actionButton}
                            </ShopButton>
                            {!secondaryButton ? null : (
                                <SecondaryButton onClick={onNotNowClick}>
                                    {secondaryButton}
                                </SecondaryButton>
                            )}
                        </ActionButtonsContainer>
                    )}
                </CardDiagonalLeftSideDesktop>
            </Container>
            <Image src={imageSmallUrl} alt={imageDescription} />
        </>
    );
};

export default TextToLeftAndImageFullBackground;
