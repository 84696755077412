import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../../../config/colors";
import Card from "../../templates/Card";
import Text from "../../templates/Text";

export const FooterIconsContainer = styled.div`
  display: flex;
  min-width: 90px;
  justify-content: space-between;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  width: fit-content;
  justify-content: space-between;
`;

export const FooterIconsContainerMobile = styled(FooterIconsContainer)`
  width: 100%;
  margin-bottom: 4rem;
`;

export const Container = styled(Card)`
  background: ${colors.primary};
  color: #fff;
  padding: 3rem;
  margin 0;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
`;

export const FirstContainer = styled(Container)`
  padding-top: 4rem;
  padding-bottom: 0;
  flex-wrap: wrap;
  @media (max-width: 820px) {
    display: block;
    min-height: 30px;
  }
`;

export const BottomContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  align-items: center;
  padding-top: 0;

  #agreement {
    order: 1;
  }

  #simplisafe-copy {
    order: 2;
  }

  #extra-links {
    order: 3;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    min-height: 60px;

    #agreement {
      order: 1;
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;
    }

    #simplisafe-copy {
      order: 3;
      margin-top: 1rem;
      width: 100%;
    }

    #extra-links {
      order: 2;
      width: 100%;
    }
  }
`;

export const ImgLogo = styled.img`
  padding-left: 1.5rem;
`;

export const LogoAndLinksContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const FullWidthContainer = styled.div`
  width: 100vw;
  position: absolute;
  left: 0;
`;

export const ShopAndSupportContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 130px;
`;

export const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExternalLink = styled.a`
  font-size: 1rem;
`;

export const SmallText = styled(Text)`
  min-width: 200px;
  text-align: center;
  width: 100%;
  font-size: 0.8rem;
  color: #fff;
  padding: 0;
`;

export const BoldText = styled(Link)`
  text-decoration: none;
  font-family: SimpliSans Bold;
  font-weight: 400;
  font-size: 1rem;
  color: #fff;
`;

export const Input = styled.input`
  font-size: 0.8rem;
  padding: 0.7rem;
  padding-left: 1rem;
  border-radius: 0.5rem;
  border: none;
  margin: 0.5rem 0;
  background: #fff;
  color: ${colors.primary};
`;

export const Button = styled.button`
  font-size: 0.7rem;
  padding: 0.8rem 2rem;
  border-radius: 0.5rem;
  border: 1px solid #fff;
  margin-left: 0.5rem;
  background: ${colors.primary};
  color: #fff;
`;


export const FooterLinks = styled.a`
  text-decoration: none;
  font-family: SimpliSans Bold;
  font-weight: 400;
  font-size: 1rem;
  color: #fff;
  padding-left: 1.5rem;

  @media (max-width: 768px) {
    padding: 0;
  }
`;