import React, { useCallback, useEffect, useState } from "react";
import {
  // CenteredText1,
  TextToLeftAndImage1,
  TextToLeftAndImage2,
  TextToLeftAndImageFullBackground2,
  TextToLeftAndImage4,
  TextToLeftAndImageFullBackground1,
  // TextToLeftAndVideoLoop1,
  TextToRightAndImage1,
  TextToRightAndImage2,
  TitleAndTopicNumbers1,
  Reviews1,
  TitleAndTopicNumbers2,
  FrequentlyAskedQuestions1,
  TwoDetailsCards1,
} from "../../../config/textConfig";
// import CenteredText from "../../custom/CenteredText";
import Footer from "../../custom/Footer";
import FrequentlyAskedQuestions from "../../custom/FrequentlyAskedQuestions";
import TextToLeftAndImage from "../../custom/TextToLeftAndImage";
import TextToLeftAndImageFullBackground from "../../custom/TextToLeftAndImageFullBackground";
import TwoDetailsCards from "../../custom/TwoDetailsCards";
// import TextToLeftAndVideoLoop from "../../custom/TextToLeftAndVideoLoop";
import TextToRightAndImage from "../../custom/TextToRightAndImage";
import TitleAndTopicNumbers from "../../custom/TitleAndTopicNumbers";
import AppTemplate from "../../templates/AppTemplate/AppTemplate";

import {
  Button,
  ButtonMobile,
  ButtonContainer,
  AnchorsContainer,
  AnchorLink,
  AnchorLinkMobile,
  RightContentContainer,
  RightContentContainerMobile,
  LeftContentContainerMobile,
  MobileMenuNavbar,
  FixedHeightNavbar,
} from "./styles";
import { ReactComponent as MenuIcon } from "../../../assets/menu.svg";
import integrateQualtricsFeedbackButtonVisibility from "../../../utils/integrateQualtricsFeedbackButtonVisibility";
import Header from "../../templates/Header";
import useAcceptAgreement from "../../hooks/useAcceptAgreement";
import useValidateInvitedEmail from "../../hooks/useValidateInvitedEmail";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../templates/Loading";
import NotAuthorized from "../../templates/NotAuthorized";
import ReactGA from "react-ga4";
import { dispatchMetric } from "../../../services/metrics.service";
import Reviews from "../../custom/Reviews";
import { FixedHeightBlock } from "../../templates/Header/styles";
import ConfidentialityPopUp from "../../custom/ConfidentialityPopUp";
import GoogleAnalyticsService from "../../../services/google-analytics.service";

const Home = () => {
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const uid = user && user["http://simplisafe.com/uid"];
  const [token, setToken] = useState("");
  const { isSubmiting, agreementAccepted, acceptAgreement } = useAcceptAgreement();
  const { isUserMailValid, isLoading } = useValidateInvitedEmail(
    user?.email || ""
  );
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const onJoinClick = (event: any) => {
    (window as any).qualtricsNotNow = "false";
    dispatchMetric({
      action: "action_button_shop_now",
      uid: uid,
      token,
    });
  };
  const toggleShowMobileMenu = (event: any) => {
    setShowMobileMenu((previous) => !previous);
    // setTimeout(() => {
    window.scrollTo(window.scrollX, window.scrollY - 1);
    window.scrollTo(window.scrollX, window.scrollY + 1);
    // }, 1500);
  };

  useEffect(() => {
    getAccessTokenSilently().then((value) => setToken(value));
  }, []);

  useEffect(() => {
    (window as any).userID = user?.email;
  }, [user?.email]);

  useEffect(() => {
    integrateQualtricsFeedbackButtonVisibility(true);
    if (uid && token) {
      ReactGA.send({ hitType: "pageview", page: "/home" });
      dispatchMetric({
        action: "/home",
        uid: uid,
        token,
      });
    }
  }, [uid, token]);

  const handleEnterClick = useCallback(async () => {
    GoogleAnalyticsService.dispatchButtonEvent(
      "agreement_signed",
      "Click on Enter button"
    );
    dispatchMetric({
      action: "agreement_signed",
      uid: uid,
      token,
    });
    await acceptAgreement();
  }, [acceptAgreement, token, uid]);

  const handleNotNowClick = useCallback(async () => {
    GoogleAnalyticsService.dispatchButtonEvent(
      "agreement_not_now",
      "Click on Not Now button"
    );
    await logout();
  }, [logout]);

  if (isLoading) {
    return <Loading />;
  }
  if (!isUserMailValid) {
    return <NotAuthorized />;
  }

  return (
    <AppTemplate
      leftContent={
        <LeftContentContainerMobile onClick={toggleShowMobileMenu}>
          <MenuIcon />
        </LeftContentContainerMobile>
      }
      cleanRightView={
        <>
          <RightContentContainer>
            <AnchorsContainer>
              <AnchorLink
                onClick={(evt) => {
                  (window as any).window.qualtricsNotNow = "false";
                  dispatchMetric({ uid: uid, token, action: "#features" });
                }}
                href="#features"
              >
                Features
              </AnchorLink>
              <AnchorLink
                onClick={(evt) => {
                  (window as any).window.qualtricsNotNow = "false";
                  dispatchMetric({
                    uid: uid,
                    token,
                    action: "#what-to-expect",
                  });
                }}
                href="#what-to-expect"
              >
                What to expect
              </AnchorLink>
              <AnchorLink
                onClick={(evt) => {
                  (window as any).window.qualtricsNotNow = "false";
                  dispatchMetric({ uid: uid, token, action: "#faq" });
                }}
                href="#faq"
              >
                FAQ
              </AnchorLink>
            </AnchorsContainer>
            <ButtonContainer>
              <Button to="/shop" onClick={onJoinClick}>Shop now</Button>
            </ButtonContainer>
          </RightContentContainer>
        </>
      }
      rightContent={
        <>
          <RightContentContainer>
            <AnchorsContainer>
              <AnchorLink
                onClick={(evt) => {
                  (window as any).window.qualtricsNotNow = "false";
                  dispatchMetric({ uid: uid, token, action: "#features" });
                }}
                href="#features"
              >
                Features
              </AnchorLink>
              <AnchorLink
                onClick={(evt) => {
                  (window as any).window.qualtricsNotNow = "false";
                  dispatchMetric({
                    uid: uid,
                    token,
                    action: "#what-to-expect",
                  });
                }}
                href="#what-to-expect"
              >
                What to expect
              </AnchorLink>
              <AnchorLink
                onClick={(evt) => {
                  (window as any).window.qualtricsNotNow = "false";
                  dispatchMetric({ uid: uid, token, action: "#faq" });
                }}
                href="#faq"
              >
                FAQ
              </AnchorLink>
            </AnchorsContainer>
            <ButtonContainer>
              <Button to="/shop" onClick={onJoinClick}>Shop now</Button>
            </ButtonContainer>
          </RightContentContainer>
        </>
      }
    >
      {!showMobileMenu ? null : (
        <>
          <FixedHeightNavbar />
          <Header containerStyle={{ height: 200 }}>
            <MobileMenuNavbar>
              <AnchorLinkMobile
                href="#features"
                onClick={(evt) => {
                  (window as any).window.qualtricsNotNow = "false";
                  toggleShowMobileMenu(null);
                  dispatchMetric({ uid: uid, token, action: "#features" });
                }}
              >
                Features
              </AnchorLinkMobile>
              <AnchorLinkMobile
                href="#what-to-expect"
                onClick={(evt) => {
                  (window as any).window.qualtricsNotNow = "false";
                  toggleShowMobileMenu(null);
                  dispatchMetric({
                    uid: uid,
                    token,
                    action: "#what-to-expect",
                  });
                }}
              >
                What to expect
              </AnchorLinkMobile>
              <AnchorLinkMobile
                href="#faq"
                onClick={(evt) => {
                  (window as any).window.qualtricsNotNow = "false";
                  toggleShowMobileMenu(null);
                  dispatchMetric({ uid: uid, token, action: "#faq" });
                }}
              >
                FAQ
              </AnchorLinkMobile>
              <ButtonMobile to="/shop" onClick={onJoinClick}>Shop now</ButtonMobile>
            </MobileMenuNavbar>
          </Header>
        </>
      )}
      {/* <TextToLeftAndVideoLoop {...TextToLeftAndVideoLoop1} /> */}

      <FixedHeightBlock />

      <TextToLeftAndImageFullBackground
        {...TextToLeftAndImageFullBackground1}
        secondary
      />

      {/* <CenteredText>{CenteredText1.text}</CenteredText> */}

      <TitleAndTopicNumbers {...TitleAndTopicNumbers1} />

      <Reviews {...Reviews1} />

      {/* Monitoring Plans */}
      <TwoDetailsCards {...TwoDetailsCards1} />

      {/* Your eyes and ears */}
      <TextToRightAndImage
        {...TextToRightAndImage2}
        titleStyle={{
          fontSize: "55px",
          lineHeight: "57px",
        }}
        textStyle={{
          fontSize: "18px",
          lineHeight: "28px",
          maxWidth: "488px",
        }}
        useSmallImageDesktop
      />

      <TextToLeftAndImage
        {...TextToLeftAndImage1}
        useMainCardPadding
        backgroundColor={"#1d1d1f"}
        textColor={"#fff"}
        textStyle={{
          fontSize: "18px",
          lineHeight: "28px",
        }}
        titleStyle={{
          fontSize: "55px",
          lineHeight: "57px",
        }}
        cardStyle={{
          minHeight: "fit-content",
        }}
        containerStyle={{
          minHeight: "fit-content",
        }}
        imageStyle={{
          minHeight: "auto",
        }}
      />

      {/* Detects people panel */}
      <TextToRightAndImage
        {...TextToRightAndImage1}
        titleStyle={{
          fontSize: "3rem",
          lineHeight: "3rem",
        }}
        textStyle={{
          lineHeight: "2rem",
          fontSize: "1.2rem",
          maxWidth: 430,
        }}
        useSmallImageDesktop
      />

      <TextToLeftAndImage
        {...TextToLeftAndImage2}
        textStyle={{
          fontSize: "18px",
          lineHeight: "28px",
          maxWidth: 460,
        }}
        titleStyle={{
          fontSize: "55px",
          lineHeight: "57px"
        }}
        cardStyle={{
          marginTop: 0,
          minHeight: "fit-content",
        }}
        containerStyle={{
          minHeight: "fit-content",
        }}
        imageStyle={{
          minHeight: "auto",
        }}
      />

      <TextToLeftAndImageFullBackground
        {...TextToLeftAndImageFullBackground2}
        darkContainer
        secondary
        textToRight
      />

      <TextToLeftAndImage
        {...TextToLeftAndImage4}
        titleStyle={{
          fontFamily: "ArizonaSans Bold",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "55px",
          lineHeight: "57px",
          letterSpacing: "-0.012em",
        }}
        imageStyle={{
          minHeight: "auto",
        }}
      />

      <div id="what-to-expect" style={{ height: 12 }}></div>

      <TitleAndTopicNumbers
        titleStyle={{
          fontFamily: "ArizonaSans Bold",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "55px",
          lineHeight: "57px",
          textAlign: "center",
          letterSpacing: "-0.012em",
        }}
        topicsContainerStyle={{
          justifyContent: "space-around",
        }}
        backgroundColor="#F6F6F5"
        {...TitleAndTopicNumbers2}
      />

      <div id="faq" style={{ height: 12 }}></div>

      <FrequentlyAskedQuestions {...FrequentlyAskedQuestions1} />

      <Footer />
      {!agreementAccepted && <ConfidentialityPopUp onNotNow={handleNotNowClick} onEnter={handleEnterClick}/>}
    </AppTemplate>
  );
};

export default Home;
