import styled from "styled-components";
import colors from "../../../config/colors";
import Card from "../../templates/Card";
import SectionTitle from "../../templates/SectionTitle";
import { HashLink as Link } from "react-router-hash-link";

export const InputAndroidDevice = styled.input`
  outline: none;
  border-color: #fff;
  border-radius: 0.5rem;
  padding: 0.5rem;
  min-width: 230px;
`;

export const SelectOption = styled.select`
  width: 45px;
  height: 30px;
  padding-left: 6px;
  -webkit-appearance: menulist-button;
`

export const ButtonAndroidDevice = styled.button`
  background: ${colors.disabled};
  border: none;
  font-size: 1rem;
  padding: 0.5rem;
  color: #000;
  font-weight: bold;
  border-radius: 0.5rem;
  min-width: 130px;
  cursor: pointer;
  font-family: "SimpliSans";
  margin-left: 1.5rem;
  @media (max-width: 820px) {
    margin-left: 0;
    margin-bottom: 1rem;
  }
`;

export const TextHelp = styled.p`
  text-align: center;
`;

export const ImageThanksComponent = styled.img`
  height: 398px;
  width: 465px;
  align-self: center;
`;

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const RightContentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const StrongBold = styled.strong`
  font-family: "SimpliSans Bold";
  font-weight: bold;
`;

export const Container = styled(Card)`
  background: #fff;
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  @media (max-width: 820px) {
    margin: 2rem 0;
  }
  @media (max-width: 600px) {
    margin-top: 0;
  }
`;

export const SectionContainer = styled.div`
  padding: 2rem;
  margin-top: 1rem;
  background: #f2f2f2;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 820px) {
  }

  @media (max-width: 600px) {
    background: #fff;
    padding: 0.5rem;
    margin-top: 0;
    border-radius: 0;
  }
`;

export const Title = styled(SectionTitle)`
  text-align: left;
  font-family: "ArizonaSans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 57px;
  letter-spacing: -0.012em;
  text-align: center;
`;

export const ShoppingMainTitle = styled(Title)`
  font-family: "SimpliSans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 2.5rem;
  color: #000;

  @media (max-width: 480px) {
    font-size: 30px;
  }
`;

export const ShopTitle = styled(Title)`
  padding-top: 0;
  margin: 1rem 0;
  font-family: "SimpliSans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  line-height: 3rem;

  @media (max-width: 600px) {
    margin: 0;
    margin-top: 1.5rem;
    font-size: 1.5rem;
    line-height: 2rem;
    
    &.shipping-address {
      margin-top: 3rem;
    }
  }
`;

export const ShopSubtitle = styled(ShopTitle)`
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2rem;
  margin: 0 1rem;
  padding: 0px;
  margin-bottom: 2rem;

  @media (max-width: 480px) {
    margin: 1rem;
    font-size: 16px;
    text-align: center;
  }
`;

export const ShoppingOptionsContainer = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 820px) {
    gap: 1rem;
    flex-direction: column;
  }
`;

export const ShoppingOptionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 3px solid #006fee;
  border-radius: 10px;
  flex: 1;
  flex-direction: column;
  cursor: pointer;
  min-width: 250px;
`;

export const CartIframe = styled.iframe`
  border: none;
`;

export const ShoppingOptionSecondaryContainer = styled(ShoppingOptionContainer)`
  border-color: #bcb4ae;
  &:hover {
    border: 3px solid rgb(175 206 242);
  }
  &.selected {
    border: 3px solid #006fee;
  }

  &:not(.selected) {
    border: 3px solid #bcb4ae;
    p,div {
      color: #979797;
    }
  }
`;

export const ShoppingOptionTitle = styled(Title)`
  font-size: 2rem;
  font-family: "SimpliSans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #000000;
`;

export const OutdoorKitContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;

  @media (max-width: 820px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 600px) {
    margin-bottom: 0;
    padding: 0;
    padding-top: 1rem;
  }
`;

export const OutdoorKitContent = styled.div`
  flex: 1;
  max-width: 600px;
  padding-left: 2rem;

  @media (max-width: 820px) {
    padding: 0;
    padding-top: 1rem;
  }

  @media (max-width: 600px) {
    margin: 0;
    padding: 0 1rem;
  }

  @media (max-width: 480px) {
    padding: 0 0.5rem;
  }
`;

export const OutdoorKitTitle = styled(Title)`
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 2.4rem;
  color: #000000;
  text-align: initial;
  margin-bottom: .5rem;
  padding: 0px;

  @media (max-width: 600px) {
    margin: 0;
    padding-left: 0.5rem;
  }

`;

export const OutdoorKitSubTitle = styled(OutdoorKitTitle)`
  padding: 1rem 0;
  color: ${colors.action};
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 1.2rem;
  margin: 0;
  margin-top: 2rem;

  @media (max-width: 480px) {
    margin-top: 0rem;
    padding-top: 2.5rem;
    padding-bottom: 1.5rem;
    font-size: 20px;
  }
`;

export const OutdoorKitInfo = styled(OutdoorKitTitle)`
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  margin: 0rem;
  padding: .4rem 0;
  @media (max-width: 1024px) {
    line-height: 1.4rem;
    padding-bottom: 0;
  }

  @media (max-width: 600px) {
    margin: 0;
    padding: 0;
  }
`;

export const OutdoorKitInfoText = styled(OutdoorKitInfo)`
  font-family: "SimpliSans";
  font-weight: 400;
  margin-right: 0;
  line-height: 1.5rem;
  margin-top: 1rem;
`;

export const ButtonAndPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 4rem 0px;
  @media (max-width: 480px) {
    padding: 0;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const PriceTagValue = styled.span`
  font-family: "SimpliSans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 25px;
  color: #BF4436;
  margin-left: 1rem;

  @media (max-width: 480px) {
    margin-left: 0.5rem;
  }
`;

export const PriceTagLineThrough = styled(PriceTagValue)`
  text-decoration: line-through;
  color: #000;
  margin-right: 0;
`;

export const PriceTagLineEach = styled(PriceTagValue)`
  color: #000;
  margin-left: .5rem;
`;

export const Button = styled.button`
  background: ${colors.action};
  border: none;
  font-size: 1rem;
  padding: 0.7rem 2rem 0.8rem 2rem;
  color: #fff;
  font-weight: bold;
  border-radius: 0.5rem;
  min-width: 130px;
  cursor: pointer;
  font-family: "SimpliSans";

  &:disabled {
    background: ${colors.disabled} !important;
    cursor: not-allowed;
  }

  &:hover {
    filter: brightness(1.2);
    transition: 0.5s ease-in-out;
  }

  @media (max-width: 480px) {
    margin-left: 0;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    width: 100%;
    padding: 1rem 0px;
  }
`;

export const ButtonSecureCheckout = styled(Button)`
  align-self: end;
  margin-top: 2rem;
`;

export const OutdoorKitInfoTextBold = styled.strong`
  font-family: "SimpliSans Bold";
  font-weight: bold;
`;

export const SampleImage = styled.img`
  max-height: 500px;

  @media (max-width: 1000px) {
    max-height: 400px;
  }
  @media (max-width: 900px) {
    max-height: 330px;
  }

  @media (max-width: 480px) {
    max-height: fit-content;
    width: 100%;
  }
`;

export const FixedHeightNavbar = styled.div`
  height: 68px;
`;

export const MobileMenuNavbar = styled.div`
  background: ${colors.primary};
  flex-direction: column;
  display: flex;
  padding: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  display: none;
  @media (max-width: 1200px) {
    display: flex;
  }
`;

export const ShoppingDetailsText = styled.p`
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4rem;
  color: #0F2648;
  text-align: center;
  padding: 0 2rem;
  background: #f2f2f2;
  color: ${colors.primary};

  @media (max-width: 600px) {
    padding: 0 0.5rem;
    margin-top: 1rem;
    background: #fff;
    line-height: 20px;
  }
`;

export const AppDownloadInstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShoppingDetailsContent = styled.p`
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: ${colors.primary};
  text-align: center;
  padding: 0 4rem;

  span {
    display: block;
    margin-top: 1rem;

    strong {
      font-family: "SimpliSans Bold";
    }
  }

  @media (max-width: 600px) {
    padding: 0 0.5rem;
  }
`;

export const ShoppingDetailsContentNext = styled(ShoppingDetailsContent)`
  width: 80%;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const RightContentContainerMobile = styled(RightContentContainer)`
  display: none;
  @media (max-width: 1200px) {
    display: block;
  }
`;

export const LeftContentContainerMobile = styled(RightContentContainerMobile)`
  cursor: pointer;
`;

export const AnchorsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AnchorLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: right;
  margin-right: 100px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:visited {
    text-decoration: none;
  }
`;
export const AnchorLinkMobile = styled(AnchorLink)`
  margin-bottom: 1rem;
  margin-left: 0.5rem;
`;

export const QuantityAndPriceContainer = styled.div`
  display: flex;
  @media (max-width: 480px) {
    justify-content: center;
  }
`;

export const QuantityButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  button {
    margin: 0;
    background: transparent !important;
    color: black;
    font-family: "SimpliSans Bold";
    font-style: 600;
    border: 2px solid ${colors.action};

    &.disabled {
      background: transparent !important;
      color: ${colors.disabled};
      border: 2px solid ${colors.disabled};
      cursor: pointer;
    }

    &:first-child {
      margin-right: 0.5rem;
    }
  }
`;

export const AlreadyOrdered = styled.p`
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: ${colors.primary};
  text-align: right;

  @media (max-width: 480px) {
    text-align: center;
  }
`;

export const ShippingAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 820px) {
    max-width: 100%;
    padding: 0 1rem;
  }

  @media (max-width: 480px) {
    padding: 0 0.5rem;
  }

  p {
    font-family: "SimpliSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: ${colors.primary};
    margin: 0;

    @media (max-width: 480px) {
      text-align: center;
      margin: 1rem 0rem;
      margin-bottom: 2rem;
      text-align: left;
      font-size: 16px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;

    @media (max-width: 480px) {
      margin-top: 0;
      align-items: flex-start;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;

      input {
        margin-right: 1rem;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid ${colors.primary};
        cursor: pointer;
      }

      label {
        @media (max-width: 480px) {
          max-width: 300px;
        }
      }

      span {
        font-size: 16px;
        text-align: center;
        margin: 0;
        margin-right: 1rem;

        @media (max-width: 480px) {
          margin-right: 0.5rem;
        }
      }
    }
  }
`;


export const PlaceOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 1rem;
`;

export const EarlyAccessPromotion = styled.div`
  font-family: "SimpliSans";
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  color: ${colors.action};

  div.title {
    font-style: normal;
    font-size: 13px;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
  }

  div.early-access-promotion {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin: 0;
  }
`;
