import React, { useEffect, useState } from "react";
import App from "../../templates/AppTemplate/AppTemplate";
import {
  TextContainer,
  TermsOfServiceText,
  Title,
  TermsOfServiceContainer,
} from "./styles";
import { useAuth0 } from "@auth0/auth0-react";
import useValidateInvitedEmail from "../../hooks/useValidateInvitedEmail";
import NotAuthorized from "../../templates/NotAuthorized";
import Loading from "../../templates/Loading";
import integrateQualtricsFeedbackButtonVisibility from "../../../utils/integrateQualtricsFeedbackButtonVisibility";
import ReactGA from "react-ga4";
import FullTerms from "./full-terms";
import { dispatchMetric } from "../../../services/metrics.service";
import { FixedHeightBlock } from "../../templates/Header/styles";

const TermsOfService = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const uid = user && user["http://simplisafe.com/uid"];
  const [token, setToken] = useState("");

  useEffect(() => {
    getAccessTokenSilently().then((value) => setToken(value));
  }, [getAccessTokenSilently]);

  const { isUserMailValid, isLoading } = useValidateInvitedEmail(
    user?.email || ""
  );

  useEffect(() => {
    integrateQualtricsFeedbackButtonVisibility(false);
    if (uid && token) {
      ReactGA.send({ hitType: "pageview", page: "/terms" });
      dispatchMetric({
        action: "/terms",
        uid: uid,
        token,
      });
    }
  }, [uid, token]);

  if (isLoading) {
    return <Loading />;
  }
  if (!isUserMailValid) {
    return (
      <NotAuthorized customMessage="This account does not have enough permissions, please review your credentials" />
    );
  }

  return (
    <App>
      <FixedHeightBlock />
      <TermsOfServiceContainer>
        <Title>SimpliSafe Outdoor Monitoring Participant Agreement</Title>
        <TextContainer>
          <TermsOfServiceText>
            <FullTerms />
          </TermsOfServiceText>
        </TextContainer>
      </TermsOfServiceContainer>
    </App>
  );
};

export default TermsOfService;
