import styled from "styled-components";
import colors from "../../../config/colors";

export const ListInnerContainer = styled.div`
display: flex;
align-items: center;
width: 100%;
justify-content: space-between;
`;


export const ImagesContainer = styled.div`
  display: flex;
  @media(max-width: 1200px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const ImgComponent = styled.img`
  max-height: 210px;
`;

export const Paragraph = styled.p`
  font-size: 1.2rem;
  text-align: left;
  width: 100%;
`;

export const ListItem = styled.li`
  border: 1px solid ${colors.neutral};
  flex-direction: column;
  padding: 0 2rem;
  cursor: pointer;
  list-style: none;
  width: calc(100% - 8rem);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0;

  @media (max-width: 480px) {
    padding: 0 1rem;
    width: calc(100% - 2rem);
  }
`;
