import React, { useEffect } from "react";
import integrateQualtricsFeedbackButtonVisibility from "../../../utils/integrateQualtricsFeedbackButtonVisibility";
import Text from "../Text";
import { Container, Image } from "./styles";

const Loading = () => {
  useEffect(() => {
    integrateQualtricsFeedbackButtonVisibility(false);
  }, []);
  
  return (
    <Container>
      <Image src="./favicon_512x512.png" alt="SimpliSafe loading" />
      <Text>Loading...</Text>
    </Container>
  );
};

export default Loading;
