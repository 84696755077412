import styled from "styled-components";
import SectionTitle from "../../templates/SectionTitle";
import Text from "../../templates/Text";
import Card from "../../templates/Card";
import Row from "../../templates/Row";
import colors from "../../../config/colors";
import LazyImage from "../../templates/LazyImage";
import { Link } from "react-router-dom";

export const Image = styled(LazyImage)`
  min-height: 570px;
  border-radius: 0.5rem;
  @media (max-width: 1200px) {
    max-width: 100%;
  }
  @media (max-width: 820px) {
    min-height: auto;
  }
`;

export const Container = styled.div`
  background: #fff;
  display: flex;
  margin: 3rem 4rem;
  justify-content: space-between;
  align-items: center;
  min-height: 510px;
  border-radius: 0.5rem;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    max-height: fit-content;
    margin: 3rem 1rem;
  }
  @media (max-width: 480px) {
    margin: 0.5rem 0;
    border-radius: 0;
    img {
      border-radius: 0 !important;
    }
  }
`;

export const TopicRow = styled(Row)`
  margin-bottom: 0.5rem;
  justify-content: flex-start;
  align-items: flex-start;
  align-items: center;
`;

export const TopicCard = styled(Card)`
  margin: 0;
`;

export const TextSpaced = styled(Text)`
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  max-width: 510px;
  @media (max-width: 1200px) {
    max-width: calc(100% - 3rem);
  }
  @media (max-width: 550px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const CardDiagonalLeftSide = styled(Card)`
  flex: 1;
  position: relative;
  text-align: center;
  background: #fff;
  border-radius: 8px 0 0 8px;
  min-height: 510px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
`;

export const CardDiagonalLeftSideDesktop = styled(CardDiagonalLeftSide)`
  max-width: 600px;
  text-align: inherit;
  @media (max-width: 1200px) {
    min-height: fit-content;
    min-width: fit-content;
    height: auto;
    margin: 0;
    margin-bottom: 2rem;
    border-radius: 8px;
  }
  @media (max-width: 480px) {
    margin: 1rem 1rem;
    align-items: flex-start;
  }
`;

export const CardDiagonalLeftSideWithPadding = styled(
  CardDiagonalLeftSideDesktop
)`
  @media (max-width: 480px) {
    padding: 0;
  }
`;

export const WhiteBoxRow = styled(Row)`
  background: white;
  border-radius: 1rem;

  @media (max-width: 480px) {
    margin: 0;
  }
`;

export const LeftContentTitle = styled(SectionTitle)`
  margin: 0 2rem;
  text-align: inherit;
  font-family: "ArizonaSans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 57px;
  letter-spacing: -0.012em;

  @media (max-width: 550px) {
    margin: 0;
    padding: 0;
  }
`;

export const ImgIcon = styled.img`
  color: ${colors.primary};
`;

export const MarginForIcon = styled.div`
  margin-top: 6px;
  margin-right: 1rem;
  min-width: 45px;
`;

export const ShopButton = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.action};
  border: none;
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #fff;
  border-radius: 0.5rem;
  min-width: 150px;
  cursor: pointer;
  margin-left: 1rem;
  margin-right: 2rem;
  height: 40px;
  padding: .5rem;
  @media (max-width: 820px) {
    min-width: 80px;
  }
`;

export const Button = styled.button`
  background: ${colors.action};
  border: none;
  font-size: 1rem;
  padding: 1rem;
  color: #fff;
  font-weight: bold;
  border-radius: 0.5rem;
  min-width: 150px;
  cursor: pointer;
  margin-left: 1rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-x: clip;
  overflow-y: clip;
  overflow: clip;
`;

export const ImageButton = styled(Button)`
  color: ${colors.primary};
  background: #fff;
  border 1px solid ${colors.primary};
  margin: 0.5rem 0;
  font-family: 'SimpliSans Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  padding: 1rem 2rem;
`;

export const SmallText = styled(Text)`
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  padding-top: 0;
  padding-left: 0;
  max-width: 450px;
  color: ${colors.primary};
`;

export const ContainerModal = styled.div`
  display: flex;
  max-height: calc(60vh - 30px);
  overflow-y: auto;
  @media (max-width: 820px) {
    flex-direction: column;
    max-height: calc(100vh - 7rem);
  }
`;
export const LeftSideModal = styled.div`
  width: 50%;
  @media (max-width: 820px) {
    width: 90%;
  }
`;

export const TopicTitle = styled.label`
  font-family: "SimpliSans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
`;

export const RightSideModal = styled(LeftSideModal)``;
