
import { v4 as uuidv4 } from 'uuid';
import { PARTICIPATE_API } from '../config/environments';
import { getHeaders } from './auth.service';

interface MetricPayload {
  uuid?: string;
  uid?: string;
  action: string;
  token: string;
}


function _generateNewUUID() {
  const newUUID = uuidv4();
  
  localStorage.setItem('uuid', newUUID);

  return newUUID;
}

export const dispatchMetric = async (props: MetricPayload) => {
  const localUUID = localStorage.getItem('uuid')
  
  const payload = {
    uuid: localUUID || _generateNewUUID(),
    user_id: props.uid || '',
    action: props.action,
  };
  
  console.log("useMetrics payload:", payload);

  try {
    const response = await fetch(
      `${PARTICIPATE_API}/log?` +
        new URLSearchParams(payload),
      {
        method: "POST",
        headers: getHeaders(props.token),
        mode: "cors",
      }
    );

    const { data } = await response.json();

    return data;
  } catch (error) {
    console.error(error);
  }
};
