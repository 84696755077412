import React from "react";
import Modal from "react-modal";

import "./index.css";

const defaultStyle = {
  overlay: {
    background: "rgb(0 0 0 / 60%)",
    zIndex: 999,
  },
};

Modal.setAppElement("#root");

const ResponsiveModal = ({
  children,
  isOpen,
  onAfterOpen,
  onRequestClose,
  customStyle = defaultStyle,
  contentLabel,
}: any) => {
  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      style={{ ...defaultStyle, ...customStyle }}
      contentLabel={contentLabel}
      className="pcs_responsive_modal"
    >
      {children}
    </Modal>
  );
};

export default ResponsiveModal;
