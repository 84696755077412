export const QUALTRICS_BRAND_ID =
  process.env.REACT_APP_QUALTRICS_BRAND_ID || "simplisafe";

export const QUALTRICS_PROJECT_ID =
  process.env.REACT_APP_QUALTRICS_PROJECT_ID || "ZN_e99r0zqjw1AaaUu";

export const QUALTRICS_INTERCEPT_ID =
  process.env.REACT_APP_QUALTRICS_INTERCEPT_ID || "SI_ehRPbxgv38LIZuK";

export const PARTICIPATE_API =
  process.env.REACT_APP_PARTICIPATE_API ||
  "https://api.stg.ss-pcs.com:8001/participate";

export const ACCEPT_AGREEMENT_VERSION =
  process.env.REACT_APP_ACCEPT_AGREEMENT_VERSION || "1.0.0";

export const QUALTRICS_JOIN_NOW_INTERCEPT=
  process.env.REACT_APP_JOIN_NOW_INTERCEPT || "SI_8vRSHFElQ7obusK";

export const QUALTRICS_NOT_NOW_INTERCEPT=
  process.env.REACT_APP_NOT_NOW_INTERCEPT || "SI_ehRPbxgv38LIZuK";

export const QUALTRICS_FEEDBACK_MODAL=
  process.env.REACT_APP_FEEDBACK_MODAL || "SI_1ZjBkVkCZZ0a77o";

export const S3_MEDIA_URL =
  process.env.REACT_APP_S3_MEDIA_URL || "https://media.ss-pcs.com/";

export const DEVICE_ID =
  process.env.REACT_APP_AUTH0_DEVICE_ID || "7f64b6e4-8360-4747-a4dd-54333265697d";

export const ECOMM_MICROSITE_SHOP_ODMON =
  process.env.REACT_APP_ECOMM_MICROSITE_SHOP_ODMON || "https://us-bofu-2.qa.commerce.simplisafe.com/odmon";

export const SIMPLISAFE_API = process.env.REACT_APP_SIMPLISAFE_API || "https://api.simplisafe.com";