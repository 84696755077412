import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import colors from "../../../config/colors";
import { S3_MEDIA_URL } from "../../../config/environments";
import { CloseButton, ModalTitle } from "../DetailCard/styles";
import ResponsiveModal from "../ResponsiveModal";
import {
  CardDiagonalLeftSideDesktop,
  CardDiagonalLeftSideWithPadding,
  Container,
  Image,
  LeftContentTitle,
  TextSpaced,
  WhiteBoxRow,
  TopicRow,
  TopicCard,
  MarginForIcon,
  ShopButton,
  Button,
  SmallText,
  ImageButton,
  ImageContainer,
  ContainerModal,
  TopicTitle,
  LeftSideModal,
  RightSideModal,
  ImgIcon,
} from "./styles";
import GoogleAnalyticsService from "../../../services/google-analytics.service";
import { dispatchMetric } from "../../../services/metrics.service";
import { useAuth0 } from "@auth0/auth0-react";

const TextToLeftAndImage = ({
  text,
  textBreakLine = null,
  imageUrl,
  imageDescription,
  title,
  titleBreakLine,
  topics = [],
  actionButton = null,
  secondaryButton = null,
  backgroundColor = null,
  cardStyle = {},
  containerStyle = {},
  imageStyle = {},
  titleStyle = {},
  textStyle = {},
  secondary = false,
  useMainCardPadding = false,
  imageButton = null,
  modalLeftTitle = null,
  modalLeftTopics = null,
  modalRightTitle = null,
  modalRightTopics = null,
  modalLeftTitle2 = null,
  modalLeftTopics2 = null,
  modalRightTitle2 = null,
  modalRightTopics2 = null,
  imageExtraText = null,
  textColor = null,
}: any) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const uid = user && user['http://simplisafe.com/uid'];
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [token, setToken] = useState("");
  
  useEffect(() => {
    getAccessTokenSilently().then((value) => setToken(value));
  }, [getAccessTokenSilently]);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }
  const onJoinClick = (event: any) => {
    (window as any).qualtricsNotNow = "false";
    GoogleAnalyticsService.dispatchButtonEvent("shop-now", "Shop now button");
    dispatchMetric({
      action: 'action_button_shop_now',
      uid: uid,
      token
    });
  };
  const onNotNowClick = (event: any) => {
    (window as any).window.qualtricsNotNow = "true";
    document.dispatchEvent(new CustomEvent("qualtrics_action_button_not_now"));
    GoogleAnalyticsService.dispatchButtonEvent("not-now", "Not now button");
    dispatchMetric({
      action: 'qualtrics_action_button_not_now',
      uid: uid,
      token
    });
    event.preventDefault();
  };
  const MainCard = useMainCardPadding
    ? CardDiagonalLeftSideWithPadding
    : CardDiagonalLeftSideDesktop;

  return (
    <>
      <Container
        style={{
          backgroundColor: secondary ? colors.primary : backgroundColor,
          ...containerStyle,
        }}
      >
        <MainCard
          style={{
            backgroundColor: secondary ? colors.primary : backgroundColor,
            ...cardStyle,
          }}
        >
          <LeftContentTitle
            style={{
              color: secondary ? "#fff" : textColor || colors.primary,
              paddingBottom: titleBreakLine ? 6 : 12,
              ...titleStyle,
            }}
          >
            {title}
          </LeftContentTitle>
          {!titleBreakLine ? null : (
            <LeftContentTitle
              style={{
                color: secondary ? "#fff" : textColor || colors.primary,
                paddingTop: 0,
                ...titleStyle,
              }}
            >
              {titleBreakLine}
            </LeftContentTitle>
          )}
          <TextSpaced
            style={{
              color: secondary ? "#fff" : textColor || colors.primary,
              ...textStyle,
            }}
          >
            {text}
          </TextSpaced>
          {!textBreakLine ? null : (
            <TextSpaced
              style={{
                color: secondary ? "#fff" : textColor || colors.primary,
                ...textStyle,
              }}
            >
              {textBreakLine}
            </TextSpaced>
          )}
          {!topics.length ? null : (
            <WhiteBoxRow
              style={{
                backgroundColor: secondary ? colors.primary : backgroundColor,
              }}
            >
              <TopicCard>
                {topics.map(
                  (topic: {
                    text: string;
                    description: string;
                    iconSrc: string;
                  }) => {
                    return (
                      <TopicRow
                        key={topic.text}
                        style={{
                          color: secondary ? "#fff" : colors.primary,
                        }}
                      >
                        <MarginForIcon
                          style={
                            !topic.description
                              ? {}
                              : { marginTop: -12, marginRight: "2rem" }
                          }
                        >
                          <ImgIcon
                            alt="Icon"
                            src={`${S3_MEDIA_URL}${topic.iconSrc}`}
                            style={
                              !topic.description
                                ? { width: 40, height: 40 }
                                : { width: 50, height: 50 }
                            }
                          />
                        </MarginForIcon>
                        <div>
                          <TopicTitle>{topic.text}</TopicTitle>
                          {!topic.description ? null : (
                            <SmallText>{topic.description}</SmallText>
                          )}
                        </div>
                      </TopicRow>
                    );
                  }
                )}
              </TopicCard>
            </WhiteBoxRow>
          )}
          {!actionButton ? null : (
            <div>
              <ShopButton to="/shop" onClick={onJoinClick}>{actionButton}</ShopButton>
              {!secondaryButton ? null : (
                <Button onClick={onNotNowClick}>{secondaryButton}</Button>
              )}
            </div>
          )}
        </MainCard>
        <ImageContainer>
          <Image src={imageUrl} alt={imageDescription} style={imageStyle} />
          {!imageButton ? null : (
            <div style={{ marginTop: -30 }}>
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  fontFamily: "SimpliSans Bold",
                  marginLeft: "-4rem",
                  color: "#0F2648",
                }}
              >
                {imageExtraText}
              </p>
              <ImageButton onClick={() => setIsOpen(true)}>
                {imageButton}
              </ImageButton>
            </div>
          )}
        </ImageContainer>
      </Container>
      <ResponsiveModal
        key={`detailModal_${title}`}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel={title}
      >
        <CloseButton onClick={closeModal}>
          <CloseIcon style={{ margin: "1rem 1rem 0 0" }} />
        </CloseButton>
        <ContainerModal>
          <LeftSideModal>
            <ModalTitle>{modalLeftTitle}</ModalTitle>
            <div>
              {!modalLeftTopics || !modalLeftTopics.length
                ? null
                : modalLeftTopics.map((leftTopic: string) => (
                    <p key={`${modalLeftTitle}-${leftTopic}`}>{leftTopic}</p>
                  ))}
            </div>
            <ModalTitle>{modalLeftTitle2}</ModalTitle>
            <div>
              {!modalLeftTopics2 || !modalLeftTopics2.length
                ? null
                : modalLeftTopics2.map((leftTopic: string) => (
                    <p key={`${modalLeftTitle2}-${leftTopic}`}>{leftTopic}</p>
                  ))}
            </div>
          </LeftSideModal>
          <RightSideModal>
            <ModalTitle>{modalRightTitle}</ModalTitle>
            <div>
              {!modalRightTopics || !modalRightTopics.length
                ? null
                : modalRightTopics.map((rightTopic: string) => (
                    <p key={`${modalRightTitle}-${rightTopic}`}>{rightTopic}</p>
                  ))}
            </div>
            <ModalTitle>{modalRightTitle2}</ModalTitle>
            <div>
              {!modalRightTopics2 || !modalRightTopics2.length
                ? null
                : modalRightTopics2.map((rightTopic: string) => (
                    <p key={`${modalRightTitle2}-${rightTopic}`}>
                      {rightTopic}
                    </p>
                  ))}
            </div>
          </RightSideModal>
        </ContainerModal>
      </ResponsiveModal>
    </>
  );
};

export default TextToLeftAndImage;
