import React from "react";
import { Image } from "./styles";

const LazyImage = (props: any) => (
  <Image
    loading="lazy"
    role="presentation"
    decoding="async"
    fetchpriority="high"
    {...props}
  ></Image>
);

export default LazyImage;
