import { SIMPLISAFE_API } from '../config/environments';

export type UserAddress = {
  firstName: string;
  lastName: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
};

/**
 * Fetch user address from simplisafe
 * @param token auth0 token
 * @param userId user id
 * @returns user address list
 */
export const getUserAddress = async (
  token: string,
  userId: string
): Promise<UserAddress[]> => {
  const response = await fetch(`${SIMPLISAFE_API}/v1/users/${userId}/addresses`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.ok) {
    return response.json();
  }

  throw new Error('Error fetching user address');
};
