import styled from "styled-components";
import colors from "../../../config/colors";

export const PlusIconText = styled.h3`
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 400;
  font-size: 96px;
  line-height: 25px;
  margin: 0;
`;

export const Title = styled.h3`
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 2.2rem;
  text-align: center;
  color: #000000;
  text-align: center;
  width: 100%;
  padding-bottom: 5px;
  margin-top: .8rem;
  margin-bottom: .8rem;

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const ImageIcon = styled.img`
  width: 35px;
`;

export const SeeMoreIcon = styled.img`
  height: 35px;

  @media (max-width: 480px) {
    height: 25px;
  }
`;

export const ImageContainer = styled.div<Partial<{ justifyContent: string }>>`
  display: flex;
  justify-content: ${props => props.justifyContent || "flex-start"};
  align-items: center;
  padding: 2rem 0;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Image = styled.img`
  height: 431px;
  width: 301px;
`;

export const Text = styled.p`
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  width: 50%;
  margin-right: 2rem;
  padding: 2rem;
  @media (max-width: 1200px) {
    width: 100%;
    margin: 1rem;
    padding: 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  @media (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const Container = styled.div`
  background: ${colors.neutral};
  border-radius: 10px;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;

  width: 800px;

  @media (max-width: 1200px) {
    width: 80%;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 1rem 0;
  }
`;
