import styled from "styled-components";
import colors from "../../../config/colors";
import Card from "../../templates/Card";

export const BoldText = styled.strong`
  font-family: SimpliSans Bold;
`;

export const ModalTitle = styled.h2`
  font-size: 1.2rem;
  margin-top: 2rem;
`;

export const ModalParagraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.5rem;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 0;
  border: none;
  background: none;
  font-size: 1rem;
  cursor: pointer;
`;

export const List = styled.ul`
  padding: 0 2rem;
  text-align: initial;
  width: 100%;
`;

export const ListItemTitle = styled.label`
  font-size: 2rem;
  font-family: SimpliSans Bold;
`;

export const ListItem = styled.li`
  font-size: 1rem;
  list-style: none;
  text-alight: initial;
  display: flex;
  justify-content: center;
  align-items: center;
  &:first-child {
    padding-bottom: 1rem;
  }
`;

export const DetailsCard = styled(Card)`
  background: ${colors.secondary};
  border-radius: 0.5rem;
  padding: 1rem;
  height: 100%;
  min-width: 270px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 820px) {
    margin-bottom: 1rem;
    height: fit-content;
    width: 100%;
    margin: 0;
    &:first-child {
      margin-bottom: 1rem;
    }
  }
`;
