import styled from "styled-components";
import SectionTitle from "../../templates/SectionTitle";
import Text from "../../templates/Text";
import Card from "../../templates/Card";
import Row from "../../templates/Row";
import colors from "../../../config/colors";
import LazyImage from "../../templates/LazyImage";

export const Image = styled(LazyImage)`
  display: none;
  max-width: calc(100% - 4rem);
  border-radius: 0.5rem;

  @media (max-width: 480px) {
    display: block;
    margin: 0;
    max-width: 100%;
    border-radius: 0;
  }
`;  

export const Container = styled("div")<{textToRight: boolean}>`
  position: relative;
  background-color: #002247 !important;
  background-size: cover;
  display: flex;
  margin: 3rem;
  justify-content: ${$props => $props.textToRight ? 'flex-end' : 'flex-start'};
  align-items: center;
  border-radius: 0.5rem;
  min-height: 510px;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    margin: 2rem 2rem;
  }

  @media (max-width: 820px) {
    flex-wrap: wrap;
    margin: 1rem;
  }


  @media (max-width: 480px) {
    margin: 0;
    border-radius: 0;
  }

  &.dark {
    background-color: #000000f5 !important;
    @media (max-width: 1200px) {
      background: #1d1d1f !important;
      justify-content: flex-start;
    }
  }
`;

export const TopicRow = styled(Row)`
  margin-bottom: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TopicCard = styled(Card)`
  margin: 0;
`;

export const TextSpaced = styled(Text)`
  color: ${$props => $props.secondary ? '#fff' : colors.primary};
  line-height: 1.6rem;
  max-width: 510px;

  &.breakline {
    padding-top: 0;
  }

  & > strong {
    font-family: "SimpliSans Bold";
    font-style: normal;
    font-weight: 700;
  }

  @media (max-width: 1200px) {
    max-width: calc(100% - 3rem);
  }
`;

export const CardDiagonalLeftSide = styled(Card)`
  flex: 1;
  position: relative;
  text-align: center;
  background: #fff;
  border-radius: 8px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
`;

export const CardDiagonalLeftSideDesktop = styled(CardDiagonalLeftSide)`
  background-color: #fff0 !important;
  max-width: 520px;
  text-align: inherit;
  background: none;
  padding: 2rem;
  padding-right: ${textToRight => textToRight ? '0' : '2rem'};

  @media (max-width: 820px) {
    background-color: inherit !important;
    padding: 1rem;
  }

  @media (max-width: 480px) {
    padding: 1rem 0.5rem;
    margin-bottom: 0;
  }
`;

export const WhiteBoxRow = styled(Row)`
  background: white;
  border-radius: 1rem;
`;

export const LeftContentTitle = styled(SectionTitle)`
  margin: 0;
  padding-bottom: 2rem;
  line-height: 2.8rem;
  color: ${$props => $props.secondary ? '#fff' : colors.primary};
  text-align: inherit;

  @media (max-width: 820px) {
    margin: 0;
  }
`;

export const MarginForIcon = styled.div`
  margin-top: 6px;
  margin-right: 6px;
`;

export const Button = styled.button`
  background: ${colors.action};
  border: none;
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  padding: 1rem;
  color: #fff;
  border-radius: 0.5rem;
  min-width: 120px;
  cursor: pointer;
  margin-left: 1rem;
`;

export const ActionButtonsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
`;

export const SecondaryButton = styled(Button)`
  border: none;
  background: none;
  color: #fff;
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  text-decoration-line: underline;
  border-radius: 0;
  min-width: fit-content;
  padding: 0.5rem;
  margin-left: 2rem;
`;

export const ImageButton = styled(Button)`
  color: #fff;
  background: ${colors.primary};
  border 1px solid #fff;
  margin-bottom: 1rem;
`;

export const SmallText = styled(Text)`
  font-size: 0.8rem;
  color: #fff;
  padding-left: 0;
`;

export const ContainerModal = styled.div`
  display: flex;
  max-height: calc(50vh - 30px);
  overflow-y: auto;
  @media (max-width: 820px) {
    flex-direction: column;
    max-height: calc(100vh - 7rem);
  }
`;
export const LeftSideModal = styled.div`
  width: 50%;
`;
export const RightSideModal = styled.div`
  width: 50%;
`;

export const BackgroundImage = styled(LazyImage)`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  border-radius: 0.5rem;

  @media (max-width: 1200px) {
    display: none;
  }
`;
