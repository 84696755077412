import ReactGA from "react-ga4";

const GoogleAnalyticsService = {
  dispatchButtonEvent: (action: string, label: string) => {
    ReactGA.event({
      category: "buttons",
      action: action,
      label: label, // optional
      value: 1, // optional, must be a number
      nonInteraction: false, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  },
};

export default GoogleAnalyticsService;
