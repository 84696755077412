import styled from "styled-components";
import Row from "../../templates/Row";
import Text from "../../templates/Text";
import Card from "../../templates/Card";
import { LeftContentTitle } from "../TextToLeftAndImage/styles";
import LazyImage from "../../templates/LazyImage";

export const Image = styled(LazyImage)`
  min-height: 570px;
  border-radius: 16px;
  @media (max-width: 1200px) {
    max-width: 100%;
  }
  @media (max-width: 820px) {
    min-height: auto;
  }
`;

export const ImageSmallDesktop = styled(LazyImage)`
  min-height: auto;
  max-width: 900px;
  margin-left: 4rem;
  @media (max-width: 1200px) {
    margin-left: 0;
    max-width: 100%;
  }
`;

export const Container = styled.div`
  background: #fff;
  display: flex;
  margin: 3rem 4rem;
  justify-content: space-between;
  align-items: center;
  max-height: 510px;
  border-radius: 0.5rem;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    max-height: fit-content;
    margin: 3rem 1rem;

  }
  @media (max-width: 820px) {

  }
  @media (max-width: 480px) {
    margin: 0.5rem 0;
    border-radius: 0;
    img {
      border-radius: 0 !important;
    }
  }
`;

export const TextSpaced = styled(Text)`
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  max-width: 510px;
  @media (max-width: 1200px) {
    max-width: calc(100% - 3rem);
  }
  @media (max-width: 550px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const CardDiagonalRightSide = styled(Card)`
  flex: 1;
  position: relative;
  text-align: center;
  background: #fff;
  border-radius: 8px 0 0 8px;
  height: 510px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
`;

export const CardDiagonalRightSideDesktop = styled(CardDiagonalRightSide)`
  max-width: 600px;
  text-align: inherit;
  @media (max-width: 1200px) {
    min-height: fit-content;
    min-width: fit-content;
    margin-top: 3rem;
    height: auto;
    margin-left: 0;
  }
  @media (max-width: 480px) {
    margin: 1rem;
  }
`;

export const WhiteBoxRow = styled(Row)`
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  margin: 2rem 6rem;
`;

export const RightContentTitle = styled(LeftContentTitle)`
  @media (max-width: 480px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
`;
