import React, { useEffect, useState } from "react";
import Footer from "../../custom/Footer";
import AppTemplate from "../../templates/AppTemplate/AppTemplate";
import {
  ShoppingOptionsContainer,
  ShoppingOptionSecondaryContainer,
  ShoppingOptionTitle,
  ShopTitle,
  ShopSubtitle,
  ShoppingMainTitle,
  SectionContainer,
  Container,
  AnchorsContainer,
  AnchorLink,
  AnchorLinkMobile,
  RightContentContainer,
  ShoppingDetailsText,
  ShoppingDetailsContent,
  LeftContentContainerMobile,
  MobileMenuNavbar,
  FixedHeightNavbar,
  OutdoorKitContainer,
  SampleImage,
  OutdoorKitContent,
  OutdoorKitTitle,
  OutdoorKitSubTitle,
  OutdoorKitInfo,
  OutdoorKitInfoText,
  OutdoorKitInfoTextBold,
  Button,
  ButtonAndPriceContainer,
  PriceContainer,
  PriceTagValue,
  PriceTagLineThrough,
  SelectOption,
  QuantityAndPriceContainer,
  PriceTagLineEach,
  AlreadyOrdered,
  QuantityButtonsContainer,
  ShippingAddressContainer,
  PlaceOrderContainer,
  EarlyAccessPromotion,
} from "./styles";
import { ReactComponent as MenuIcon } from "../../../assets/menu.svg";
import integrateQualtricsFeedbackButtonVisibility from "../../../utils/integrateQualtricsFeedbackButtonVisibility";
import Header from "../../templates/Header";
import useValidateInvitedEmail from "../../hooks/useValidateInvitedEmail";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../templates/Loading";
import NotAuthorized from "../../templates/NotAuthorized";
import ReactGA from "react-ga4";
import { dispatchMetric } from "../../../services/metrics.service";
import { FixedHeightBlock } from "../../templates/Header/styles";
import GoogleAnalyticsService from "../../../services/google-analytics.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { postMonitoringPlan } from "../../../services/auth.service";
import { UserAddress, getUserAddress } from "../../../services/simplisafeApi.service";

const PRODUCT_KEY_24_HOURS = "24/7";
const PRODUCT_KEY_OVERNIGHT = "overnight";

const shopImageStep2 = require("../../../assets/shop-step2.png");

type ShopProps = {
  isOrderCallback?: boolean;
};

export const SHOP_SELECTED_QUANTITY_KEY = `shop_quantity_key`;
export const SHOP_SELECTED_ADDRESS_KEY = `shop_selected_address`;
export const SIMPLISAFE_ODMON_SKU_247 = "SSODMON247";
export const SIMPLISAFE_ODMON_SKU_ON = "SSODMONON";

let hasLoadedSearchParams = "";
function tryGetSearchParamsValue(searchParams: URLSearchParams) {
  try {
    if (searchParams.get("choose") === "overnight") {
      hasLoadedSearchParams = PRODUCT_KEY_OVERNIGHT;
    } else if (searchParams.get("choose") === "24/7") {
      hasLoadedSearchParams = PRODUCT_KEY_24_HOURS;
    } else {
      hasLoadedSearchParams = PRODUCT_KEY_24_HOURS;
    }
  } catch (err) {
    hasLoadedSearchParams = PRODUCT_KEY_24_HOURS;
  }
  return hasLoadedSearchParams;
}

const Shop = ({ isOrderCallback = false }: ShopProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [optionSelected, setOptionSelected] = useState(
    tryGetSearchParamsValue(searchParams)
  );
  const [quantity, setQuantity] = useState(1);
  const { user, getAccessTokenSilently } = useAuth0();
  const uid = user && user["http://simplisafe.com/uid"];
  const [token, setToken] = useState("");
  const [userAlreadyOrdered, setUserAlreadyOrdered] = useState(false);
  const { isUserMailValid, isLoading } = useValidateInvitedEmail(user?.email || "");
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [userAddresses, setUserAddresses] = useState<UserAddress[]>([]);
  const [canPlaceOrder, setCanPlaceOrder] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<UserAddress | null>(null);
  const [isOrdering, setIsOrdering] = useState(false);

  const toggleShowMobileMenu = (event: any) => {
    setShowMobileMenu((previous) => !previous);
    window.scrollTo(window.scrollX, window.scrollY - 1);
    window.scrollTo(window.scrollX, window.scrollY + 1);
  };

  const placeOrder = async () => {
    setIsOrdering(true);
    // Refreshing token here in case the user has been logged in for a long time
    // before adding to cart
    let options;
    if (navigator.userAgent.indexOf("Chrome") !== -1) {
      options = {
        ignoreCache: true,
      };
    }

    const newToken = await getAccessTokenSilently(options);
    setToken(newToken);

    const monitoringPlanResponse = await postMonitoringPlan(
      {
        planSelection: optionSelected,
        sku: optionSelected.startsWith("24")
          ? SIMPLISAFE_ODMON_SKU_247
          : SIMPLISAFE_ODMON_SKU_ON,
      },
      newToken
    );

    setIsOrdering(false);
    if (!monitoringPlanResponse.ok) {
      if (monitoringPlanResponse.status === 409) {
        setUserAlreadyOrdered(true);
      }
      return;
    }
    const monitoringPlanResult = await monitoringPlanResponse.json();

    localStorage.setItem(SHOP_SELECTED_QUANTITY_KEY, JSON.stringify(quantity));
    localStorage.setItem(SHOP_SELECTED_ADDRESS_KEY, JSON.stringify(selectedAddress));

    GoogleAnalyticsService.dispatchButtonEvent(
      "place-order",
      `Place Order: ${optionSelected}. Amount: ${quantity}`
    );

    // awaiting here to make sure the event is sent before redirecting
    await dispatchMetric({
      action: `place-order_${optionSelected}_${quantity}`,
      uid: uid,
      token: newToken,
    });

    const total = monitoringPlanResult.sku === SIMPLISAFE_ODMON_SKU_247 ? 9.99 : 4.99;
    navigate(
      `/order-callback?oneTimeToken=${monitoringPlanResult.oneTimeCode}&total=${total}`
    );

    // (
    //   window as any
    // ).location = `${ECOMM_MICROSITE_SHOP_ODMON}?oneTimeToken=${monitoringPlanResult.oneTimeCode}&quantity=${amount}`;
  };

  useEffect(() => {
    getAccessTokenSilently().then((value) => setToken(value));
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (token && uid) {
      getUserAddress(token, uid).then((address) => {
        setUserAddresses(address);
      });
    }
  }, [token, uid]);

  useEffect(() => {
    (window as any).userID = user?.email;
  }, [user?.email]);

  useEffect(() => {
    integrateQualtricsFeedbackButtonVisibility(true);
    if (uid && token) {
      ReactGA.send({ hitType: "pageview", page: "/shop" });
      dispatchMetric({
        action: "/shop",
        uid: uid,
        token,
      });
    }
  }, [uid, token]);

  const handleProductSelection = (productKey: string) => {
    const metric = `product_selection_${productKey}`;
    GoogleAnalyticsService.dispatchButtonEvent(
      metric,
      `Product Selection: ${productKey}`
    );
    dispatchMetric({
      action: metric,
      uid: uid,
      token,
    });
    setOptionSelected(productKey);
    const offsetTop = document.getElementById("step-2")?.offsetTop;
    if (offsetTop) {
      window.scrollTo({
        top: offsetTop - 70,
        behavior: "smooth",
      });
    }
  };

  const handleQuantitySelection = (quantity: number) => {
    const metric = `quantity_selection_${quantity}`;
    GoogleAnalyticsService.dispatchButtonEvent(metric, `Quantity Selection: ${quantity}`);
    dispatchMetric({
      action: metric,
      uid: uid,
      token,
    });
    setQuantity(quantity);
    const offsetTop = document.getElementById("step-3")?.offsetTop;
    if (offsetTop) {
      window.scrollTo({
        top: offsetTop - 50,
        behavior: "smooth",
      });
    }
  };

  const handleAddressSelection = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const addressIndex = parseInt(event.currentTarget.value);
    const selectedAddress = userAddresses[addressIndex];
    setSelectedAddress(selectedAddress);
    setCanPlaceOrder(true);
  };

  const sanitizeInput = (input: string) => {
    return !input ? "": input;
  };
  
  const AddressItem = ({ address }: { address: UserAddress }) => {
    const sanitizedStreet1 = sanitizeInput(address.street1);
    const sanitizedStreet2 = sanitizeInput(address.street2);
    const sanitizedCity = sanitizeInput(address.city);
    const sanitizedState = sanitizeInput(address.state);
    const sanitizedZip = sanitizeInput(address.zip);
    const sanitizedPhone = sanitizeInput(address.phone);
  
    const parsed = [
        sanitizedStreet1,
        sanitizedStreet2,
        sanitizedCity,
        sanitizedState,
        sanitizedZip,
    ]
        .filter((item) => item !== "")
        .join(". ");
  
    return (
      <span>
        {parsed}
        {sanitizedPhone && `. Phone: ${sanitizedPhone}`}
      </span>
    );
  };

  if (isLoading) {
    return <Loading />;
  }
  if (!isUserMailValid) {
    return <NotAuthorized />;
  }

  return (
    <AppTemplate
      leftContent={
        <LeftContentContainerMobile onClick={toggleShowMobileMenu}>
          <MenuIcon />
        </LeftContentContainerMobile>
      }
      cleanRightView={
        <RightContentContainer>
          <AnchorsContainer>
            <AnchorLink
              onClick={(evt) => {
                (window as any).window.qualtricsNotNow = "false";
                dispatchMetric({ uid: uid, token, action: "#features" });
              }}
              to="/#features"
            >
              Features
            </AnchorLink>
            <AnchorLink
              onClick={(evt) => {
                (window as any).window.qualtricsNotNow = "false";
                dispatchMetric({
                  uid: uid,
                  token,
                  action: "#what-to-expect",
                });
              }}
              to="/#what-to-expect"
            >
              What to expect
            </AnchorLink>
            <AnchorLink
              onClick={(evt) => {
                (window as any).window.qualtricsNotNow = "false";
                dispatchMetric({ uid: uid, token, action: "#faq" });
              }}
              to="/#faq"
            >
              FAQ
            </AnchorLink>
          </AnchorsContainer>
        </RightContentContainer>
      }
      rightContent={
        <RightContentContainer>
          <AnchorsContainer>
            <AnchorLink
              onClick={(evt) => {
                (window as any).window.qualtricsNotNow = "false";
                dispatchMetric({ uid: uid, token, action: "#features" });
              }}
              to="/#features"
            >
              Features
            </AnchorLink>
            <AnchorLink
              onClick={(evt) => {
                (window as any).window.qualtricsNotNow = "false";
                dispatchMetric({
                  uid: uid,
                  token,
                  action: "#what-to-expect",
                });
              }}
              to="/#what-to-expect"
            >
              What to expect
            </AnchorLink>
            <AnchorLink
              onClick={(evt) => {
                (window as any).window.qualtricsNotNow = "false";
                dispatchMetric({ uid: uid, token, action: "#faq" });
              }}
              to="/#faq"
            >
              FAQ
            </AnchorLink>
          </AnchorsContainer>
        </RightContentContainer>
      }
    >
      {!showMobileMenu ? null : (
        <>
          <FixedHeightNavbar />
          <Header containerStyle={{ height: 200 }}>
            <MobileMenuNavbar>
              <AnchorLinkMobile
                to="/#features"
                onClick={(evt) => {
                  (window as any).window.qualtricsNotNow = "false";
                  toggleShowMobileMenu(null);
                  dispatchMetric({ uid: uid, token, action: "#features" });
                }}
              >
                Features
              </AnchorLinkMobile>
              <AnchorLinkMobile
                to="/#what-to-expect"
                onClick={(evt) => {
                  (window as any).window.qualtricsNotNow = "false";
                  toggleShowMobileMenu(null);
                  dispatchMetric({
                    uid: uid,
                    token,
                    action: "#what-to-expect",
                  });
                }}
              >
                What to expect
              </AnchorLinkMobile>
              <AnchorLinkMobile
                to="/#faq"
                onClick={(evt) => {
                  (window as any).window.qualtricsNotNow = "false";
                  toggleShowMobileMenu(null);
                  dispatchMetric({ uid: uid, token, action: "#faq" });
                }}
              >
                FAQ
              </AnchorLinkMobile>
            </MobileMenuNavbar>
          </Header>
        </>
      )}
      <FixedHeightBlock />

      <Container>
        <ShoppingMainTitle>
          Grab your kit to gain early access to the outdoor monitoring program today!
        </ShoppingMainTitle>

        <>
          <SectionContainer>
            <ShopTitle>Step 1: Select your outdoor monitoring plan</ShopTitle>

            <ShoppingOptionsContainer>
              <ShoppingOptionSecondaryContainer
                onClick={() => handleProductSelection(PRODUCT_KEY_OVERNIGHT)}
                className={optionSelected === PRODUCT_KEY_OVERNIGHT ? "selected" : ""}
              >
                <ShoppingOptionTitle>Overnight Monitoring</ShoppingOptionTitle>
                <EarlyAccessPromotion>
                  <div className="title">Early access promotion</div>
                  <div className="early-access-promotion">$4.99/month add’l.*</div>
                </EarlyAccessPromotion>
                <ShoppingDetailsContent>
                  Live agents help protect your home 8pm-6am when your system is armed.
                </ShoppingDetailsContent>
              </ShoppingOptionSecondaryContainer>
              <ShoppingOptionSecondaryContainer
                onClick={() => handleProductSelection(PRODUCT_KEY_24_HOURS)}
                className={optionSelected === PRODUCT_KEY_24_HOURS ? "selected" : ""}
              >
                <ShoppingOptionTitle>24/7 Monitoring</ShoppingOptionTitle>
                <EarlyAccessPromotion>
                  <div className="title">Early access promotion</div>
                  <div className="early-access-promotion">$9.99/month add’l.*</div>
                </EarlyAccessPromotion>
                <ShoppingDetailsContent>
                  Live agents help protect your home any time of day when your system is
                  armed.
                </ShoppingDetailsContent>
              </ShoppingOptionSecondaryContainer>
            </ShoppingOptionsContainer>
            <ShoppingDetailsText>
              *Requires Fast Protect™ monitoring subscription at $29.99/mo. Your outdoor
              monitoring subscription and billing starts when you activate your service in
              the Pre Crime Service mobile app. There are no long-term contracts — start,
              stop or change plans any time.
            </ShoppingDetailsText>
          </SectionContainer>
          <SectionContainer id="step-2">
            <ShopTitle>Step 2: Add your FREE Outdoor Camera Accessory Kits </ShopTitle>
            <ShopSubtitle>
              Use your existing SimpliSafe® Outdoor Cameras with our outdoor monitoring
              service! One kit is necessary for each Outdoor Camera used with the service.
            </ShopSubtitle>
            <OutdoorKitContainer>
              <SampleImage src={shopImageStep2} />
              <OutdoorKitContent>
                <ButtonAndPriceContainer>
                  <div>
                    <p>How many kits do you need?</p>
                    <QuantityButtonsContainer>
                      <Button
                        onClick={() => handleQuantitySelection(1)}
                        className={quantity !== 1 ? "disabled" : ""}
                      >
                        1 Kit
                      </Button>

                      <Button
                        onClick={() => handleQuantitySelection(2)}
                        className={quantity !== 2 ? "disabled" : ""}
                      >
                        2 Kits
                      </Button>
                    </QuantityButtonsContainer>
                    {/* <QuantityAndPriceContainer>
                      <SelectOption onChange={onAmountChange}>
                        {[1, 2].map((k) => (
                          <option key={k} value={k}>
                            {k}
                          </option>
                        ))}
                      </SelectOption>

                      <PriceContainer>
                        <PriceTagValue>$4.55</PriceTagValue>
                        <PriceTagLineThrough>$64.97</PriceTagLineThrough>
                        <PriceTagLineEach>each</PriceTagLineEach>
                      </PriceContainer>
                    </QuantityAndPriceContainer> */}
                  </div>
                </ButtonAndPriceContainer>
                <OutdoorKitSubTitle>What's included</OutdoorKitSubTitle>
                <OutdoorKitInfo>1 External Audio Enhancer</OutdoorKitInfo>
                <OutdoorKitInfo>1 25ft Power Cable and Adapter</OutdoorKitInfo>
                <OutdoorKitInfo>
                  1 Outdoor Camera Mount (Camera not included)
                </OutdoorKitInfo>
                <OutdoorKitInfoText>
                  The Audio Enhancer optimizes audio for agent communications and siren
                  activation during an event. Connecting your cameras to power with the
                  Outdoor Camera Power Cable ensures that your outdoor camera is always on
                  for fast detection and response.
                </OutdoorKitInfoText>
                <OutdoorKitInfoText>
                  <OutdoorKitInfoTextBold>
                    One Outdoor Monitoring Accessory Kit is necessary for every Outdoor
                    Camera used with the outdoor monitoring service.
                  </OutdoorKitInfoTextBold>
                </OutdoorKitInfoText>
              </OutdoorKitContent>
            </OutdoorKitContainer>
          </SectionContainer>

          <SectionContainer id="step-3">
            <ShopTitle className="shipping-address">
              Step 3: Choose a shipping address
            </ShopTitle>
            <ShippingAddressContainer>
              <p>
                Choose where you would like your Outdoor Camera Accessory Kits to be
                shipped, from the addresses we have on file for your account.
              </p>

              {userAddresses.length === 0 && (
                <p>We don't have any addresses on file for your account.</p>
              )}
              <ul>
                {userAddresses.map((address, idx) => (
                  <li key={idx}>
                    <input
                      type="radio"
                      name="address"
                      onClick={handleAddressSelection}
                      value={idx}
                    />
                    <label htmlFor={`address-${idx}`}>
                      <AddressItem address={address} />
                    </label>
                  </li>
                ))}
              </ul>
            </ShippingAddressContainer>
          </SectionContainer>
          <PlaceOrderContainer>
            <Button
              onClick={isOrdering ? () => {} : placeOrder}
              disabled={userAlreadyOrdered || !canPlaceOrder}
            >
              {isOrdering ? "Placing order..." : "Place order"}
            </Button>
            {userAlreadyOrdered && (
              <AlreadyOrdered>
                It appears you have already placed a outdoor monitoring order. If you need
                assistance, please email{" "}
                <a href="mailto:pcs@simplisafe.com.">pcs@simplisafe.com.</a>
              </AlreadyOrdered>
            )}
          </PlaceOrderContainer>
        </>
      </Container>

      <Footer />
    </AppTemplate>
  );
};

export default Shop;
