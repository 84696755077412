import React, { useEffect} from "react";
import { ActionButtons, Button, LogoContainer, Overlay, Popup, Text } from "./styles";

type Props = {
  onEnter: () => void;
  onNotNow: () => void;
};

const ConfidentialityPopUp: React.FC<Props> = ({ onEnter, onNotNow }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Overlay>
      <Popup onClick={(e) => e.stopPropagation()}>
        <LogoContainer>
          <img
            src="https://images.ctfassets.net/br4ichkdqihc/7k7wnuCFM9OHVL1XvVqelk/52998e5eaf2a3b17d706509834d1f35d/SimpliSafeLogo_r.png?w=384&h=88&q=50&fm=png"
            alt="Simplisafe Logo"
          />
        </LogoContainer>
        <Text>
          As a reminder, the details of this program are confidential. See our{" "}
          <a
            href="https://media.ss-pcs.com/v1/SimpliSafe_Outdoor_Monitoring_Participant_Agreement_v4.0.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Participant Agreement
          </a>{" "}
          for complete terms and details.
        </Text>
        <ActionButtons>
          <Button onClick={onEnter}>Enter</Button>
          <Button className="secondary" onClick={onNotNow}>
            Not Now
          </Button>
        </ActionButtons>
      </Popup>
    </Overlay>
  );
};

export default ConfidentialityPopUp;
