import React from "react";
import Home from "./pages/Home/Home";
import Shop from "./pages/Shop";
import Help from "./pages/Help";
import { Routes, Route, useNavigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./templates/Loading";
import TermsOfService from "./pages/TermsOfService";
import OrderCallback from "./pages/OrderCallback";
import LoginCallback from "./pages/LoginCallback";
import AppDownload from "./pages/AppDownload";

export const REDIRECT_AFTER_LOGIN_LOCAL_STORAGE_KEY = "redirectAfterLogin";

export const useRedirectAfterLogin = () => {
  const navigate = useNavigate();
  return {
    redirectAfterLogin: () => {
      const redirectAfterLogin = localStorage.getItem(
        REDIRECT_AFTER_LOGIN_LOCAL_STORAGE_KEY
      );
      if (redirectAfterLogin) {
        localStorage.removeItem(REDIRECT_AFTER_LOGIN_LOCAL_STORAGE_KEY);
      }
      navigate(redirectAfterLogin || "/");
    },
  };
};

const Router = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route
        path="/terms"
        element={
          <ProtectedRoute authenticated={isAuthenticated}>
            <TermsOfService />
          </ProtectedRoute>
        }
      />
      <Route
        path="/help"
        element={
          <ProtectedRoute redirectBackAfterLogin authenticated={isAuthenticated}>
            <Help />
          </ProtectedRoute>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRoute authenticated={isAuthenticated}>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/callback"
        element={
          <ProtectedRoute authenticated={isAuthenticated}>
            <LoginCallback />
          </ProtectedRoute>
        }
      />
      <Route
        path="/shop"
        element={
          <ProtectedRoute authenticated={isAuthenticated}>
            <Shop />
          </ProtectedRoute>
        }
      />
      <Route
        path="/order-callback"
        element={
          <ProtectedRoute authenticated={isAuthenticated} redirectBackAfterLogin>
            <OrderCallback />
          </ProtectedRoute>
        }
      />
      <Route
        path="/appdownload"
        element={
          <ProtectedRoute authenticated={isAuthenticated} redirectBackAfterLogin>
            <AppDownload />
          </ProtectedRoute>
        }
      />

      <Route
        path="logout"
        element={
          <ProtectedRoute authenticated={isAuthenticated}>
            <Home />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default Router;
