import styled from "styled-components";
import colors from "../../../config/colors";
import Card from "../../templates/Card";
import Text from "../../templates/Text";
import SectionTitle from "../../templates/SectionTitle";

export const Title = styled(SectionTitle)`
  text-align: initial;
  align-self: flex-start;
  margin-left: 1rem;
  font-family: "SimpliSans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 1rem;
  padding: 0;
  line-height: 30px;
  color: ${colors.primary};
  max-width: 100%;
`;

export const TextContainer = styled(Card)`
  overflow: auto;
  max-width: 100%;
  margin: 0;
  border-radius: 0.5rem;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #c8c4c4;
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.primary};
    border-radius: 1rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const TermsOfServiceContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  padding: 2rem 25vw 0 25vw;
  @media (max-width: 1200px) {
    padding: 2rem;
  }
`;

export const Container = styled(Card)`
  display: flex;
  justify-content: center;
  algin-items: center;
  width: 100%;
  margin: 0;
`;

export const TermsOfServiceText = styled(Text)`
  font-size: 0.8rem;
  padding: 0;
  line-height: 1.2rem;
  color: #2b2b2b;
  margin: 0 1rem 1rem 1rem;
`;
