import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import AppRouter from "./components/Router";
import { BrowserRouter } from "react-router-dom";
import { DEVICE_ID } from "./config/environments";
import ReactGA from "react-ga4";
ReactGA.initialize("G-92ZGPGRKNM");

const App = () => {
  // eslint-disable-next-line no-restricted-globals
  const currentDomain = location.origin;

  return (
    <Auth0Provider
      domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
      clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
      // redirectUri={`${process.env.REACT_APP_AUTH0_CALLBACK}`}
      redirectUri={`${currentDomain}/callback`}
      audience="https://api.simplisafe.com/"
      connection="ss-users"
      scope="openid email https://api.simplisafe.com/scopes/user:platform"
      device="PCS WebApp"
      device_id={`${DEVICE_ID}`}
      // This improves the login experience on Safari and other browsers that use Intelligent Tracking Prevention (ITP)
      cacheLocation="localstorage"
    >
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </Auth0Provider>
  );
};

export default App;
