import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const CleanLink = styled(Link)`
  text-decoration: none;
  display: flex;
`;

const Logo = (props: any) => {
  const onClick = () => {
    (window as any).window.qualtricsNotNow = "false";
  }
  
  return (
    <CleanLink
      to="/"
      onClick={onClick}
    >
      <img
        width="384"
        height="88"
        data-main-image=""
        style={{
          objectFit: "contain",
          objectPosition: "left center",
          opacity: 1,
          padding: "1rem",
          height: "36px",
          width: "120px",
        }}
        sizes="384px"
        decoding="async"
        loading="eager"
        src="https://images.ctfassets.net/br4ichkdqihc/7k7wnuCFM9OHVL1XvVqelk/15106bc63aa38e9d15c321ec181fa74c/SimpliSafe-wordmark-RGB_2x.png?w=384&amp;h=88&amp;q=50&amp;fm=png"
        srcSet="https://images.ctfassets.net/br4ichkdqihc/7k7wnuCFM9OHVL1XvVqelk/15106bc63aa38e9d15c321ec181fa74c/SimpliSafe-wordmark-RGB_2x.png?w=384&amp;h=88&amp;q=50&amp;fm=png 384w"
        alt=""
      ></img>
    </CleanLink>
  );
};

export default Logo;
