import { useState } from "react";
import {
  Container,
  TitleContainer,
  Title,
  PlusIconText,
  Text,
  Image,
  ImageContainer,
  ImageIcon,
  SeeMoreIcon,
} from "./styles";

const collapseImage = require('../../../assets/collapse-shop.png');
const seeMoreImage = require('../../../assets/see-more-shop.png');

type Props = {
  children: any;
  title: string;
  image?: any;
  imageWidth?: string | number;
  imageHeight?: string | number;
};

const InstructionsSection = ({
  title,
  children,
  image,
  imageWidth = 301,
  imageHeight = 431
}: Props) => {
  const [showChildren, setShowChildren] = useState(false);

  return (
    <Container>
      <TitleContainer onClick={() => setShowChildren((prev) => !prev)}>
        <Title>{title}</Title>
        {showChildren ? <ImageIcon src={collapseImage} /> : (
          <SeeMoreIcon src={seeMoreImage} />
        )}
      </TitleContainer>
      {showChildren && (
        <ImageContainer justifyContent={image ? 'space-between' : 'center'}>
          {!!image && (<Image src={image} style={{
            width: imageWidth,
            height: imageHeight,
          }} />)}
          {children}
        </ImageContainer>
      )}
    </Container>
  );
};

export default InstructionsSection;
