import React from "react";
import { S3_MEDIA_URL } from "../../../config/environments";
import {
  FirstContainer,
  BottomContainer,
  // ShopAndSupportContainer,
  // EmailContainer,
  // ExternalLink,
  SmallText,
  // Input,
  // Button,
  // FooterIconsContainer,
  FooterLinksContainer,
  // FooterIconsContainerMobile,
  FullWidthContainer,
  LogoAndLinksContainer,
  ImgLogo,
  BoldText,
  FooterLinks,
} from "./styles";
// import { ReactComponent as TwitterIcon } from "../../../assets/twitter.svg";
// import { ReactComponent as FacebookIcon } from "../../../assets/facebook.svg";
// import { ReactComponent as YoutubeIcon } from "../../../assets/youtube.svg";

const fullYear = new Date().getFullYear();

const Footer = () => {
  return (
    <FullWidthContainer>
      <FirstContainer></FirstContainer>
      <BottomContainer>
        <BoldText id="agreement" to="/terms">
          Participant Agreement
        </BoldText>
        <div id="simplisafe-copy">
          <SmallText>© {fullYear} SimpliSafe, Inc.</SmallText>
        </div>
        <LogoAndLinksContainer id="extra-links">
          <FooterLinksContainer>
            <FooterLinks
              href="https://simplisafe.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </FooterLinks>
          </FooterLinksContainer>
          <FooterLinksContainer>
            <FooterLinks
              href="https://simplisafe.com/legal"
              target="_blank"
              rel="noreferrer"
            >
              Legal
            </FooterLinks>
          </FooterLinksContainer>
          <ImgLogo alt="USA logo" src={`${S3_MEDIA_URL}icons/USA_logo.svg`} />
        </LogoAndLinksContainer>
      </BottomContainer>
    </FullWidthContainer>
  );
};

export default Footer;
