import React, { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../templates/Loading";
import { dispatchMetric } from "../../../services/metrics.service";
import { useRedirectAfterLogin } from "../../Router";
import NotAuthorized from "../../templates/NotAuthorized";
import useValidateInvitedEmail from "../../hooks/useValidateInvitedEmail";
import integrateQualtricsFeedbackButtonVisibility from "../../../utils/integrateQualtricsFeedbackButtonVisibility";

const LoginCallback: React.FC = () => {
  const { isAuthenticated, isLoading, user, getAccessTokenSilently } = useAuth0();
  const uid = user && user["http://simplisafe.com/uid"];
  const [token, setToken] = useState("");
  const { redirectAfterLogin } = useRedirectAfterLogin();
  const dispatchingMetrics = useRef(false);

  useEffect(() => {
    getAccessTokenSilently().then((value) => setToken(value));
  }, [getAccessTokenSilently]);

  const { isUserMailValid, isLoading: isLoadingEmailValidation } =
    useValidateInvitedEmail(user?.email || "");

  useEffect(() => {
    if (isAuthenticated) {
      integrateQualtricsFeedbackButtonVisibility(false);
      if (uid && token && !dispatchingMetrics.current) {
        dispatchingMetrics.current = true;
        ReactGA.send({ hitType: "pageview", page: "/login-callback" });
        dispatchMetric({
          action: "login-success",
          uid: uid,
          token,
        });
        redirectAfterLogin();
      }
    }
  }, [uid, token, isAuthenticated, redirectAfterLogin]);

  if (isLoading || isLoadingEmailValidation) {
    return <Loading />;
  }
  if (!isUserMailValid) {
    dispatchMetric({
      action: "user_logged_in_not_invited",
      uid: uid,
      token,
    });
    return (
      <NotAuthorized customMessage="This account does not have enough permissions, please review your credentials" />
    );
  }

  return null;
};

export default LoginCallback;
