import React, { useState } from "react";
import ExpandableListItem from "../ExpandableListItem";
import {
  Container,
  List,
  Title,
  Button,
  ButtonContainer,
  QuestionParagraph,
} from "./styles";

interface FAQProps {
  title?: string;
  mostAskedQuestions: {
    question: string;
    answer?: string;
    imageUrl?: string;
    imageUrls?: string[];
    imageDescription?: string;
  }[];
  allOtherQuestions: {
    question: string;
    answer: string;
  }[];
}

const FrequentlyAskedQuestions = ({
  title = "Frequently Asked Questions",
  mostAskedQuestions,
  allOtherQuestions,
}: FAQProps) => {
  const [seeMore, setSeeMore] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const toggleSeeMore = (event: any) => {
    setSeeMore((newValue) => !newValue);
    event.preventDefault();
  };

  const sampleQuestionAnswer = (
    <p>
      This is sample click{" "}
      <a
        href="/terms"
        onClick={(event: any) => {
          setShowModal(true);
          event.preventDefault();
        }}
      >
        here
      </a>
    </p>
  );
  const sampeQuestion = (
    <ExpandableListItem
      key={`fqa-test-1`}
      expandedContent={sampleQuestionAnswer}
    >
      <p>Where I see full terms?</p>
    </ExpandableListItem>
  );

  return (
    <Container>
      <Title>{title}</Title>
      <List>
        {mostAskedQuestions.map((question) =>
          question.answer ? (
            <ExpandableListItem
              key={`fqa-${question.question}`}
              expandedContent={question.answer}
              imageUrl={question.imageUrl}
              imageUrls={question.imageUrls}
              imageDescription={question.imageDescription}
            >
              <QuestionParagraph>{question.question}</QuestionParagraph>
            </ExpandableListItem>
          ) : (
            sampeQuestion
          )
        )}
        {!seeMore
          ? null
          : allOtherQuestions.map((question) => (
              <ExpandableListItem
                key={`fqa-all-${question.question}`}
                expandedContent={question.answer}
              >
                <QuestionParagraph>{question.question}</QuestionParagraph>
              </ExpandableListItem>
            ))}
      </List>

      <ButtonContainer>
        <Button onClick={toggleSeeMore}>{`See ${
          seeMore ? "less" : "more"
        }`}</Button>
      </ButtonContainer>
    </Container>
  );
};

export default FrequentlyAskedQuestions;
