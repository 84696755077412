import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { postIsInvited } from "../../services/auth.service";

const useValidateInvitedEmail = (email: string) => {
  const [isUserMailValid, setIsUserMailValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then((value) => setToken(value));
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (token && email) {
      postIsInvited({
        email,
        token,
      })
        .then((req) => req.json())
        .then((res) => {
          setIsUserMailValid(Boolean(res.success));
        })
        .catch(() => {
          setIsUserMailValid(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [token, email]);

  return {
    isUserMailValid,
    isLoading,
  };
};

export default useValidateInvitedEmail;
