import React from "react";
import LoginButton from "../LoginButton";
import Text from "../Text";
import { Container, Image } from "./styles";
import { useAuth0 } from "@auth0/auth0-react";
import { AGREEMENT_LOCAL_STORAGE_KEY } from "../../hooks/useAcceptAgreement";

const NotAuthorized = ({ customMessage }: { customMessage?: string }) => {
  const { logout } = useAuth0();

  const onClickHandler = async () => {
    await logout();
    localStorage.removeItem(AGREEMENT_LOCAL_STORAGE_KEY);
  };

  return (
    <Container>
      <Image src="./favicon_512x512.png" alt="SimpliSafe loading" />
      <Text>Not Authorized</Text>
      {customMessage 
        ? <Text>{customMessage}</Text> 
        : null}
      <LoginButton onClickCallback={onClickHandler} />
    </Container>
  );
};

export default NotAuthorized;
