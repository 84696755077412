import React, { useEffect, useState } from 'react';
import Footer from '../../custom/Footer';
import AppTemplate from '../../templates/AppTemplate/AppTemplate';
import {
    ShoppingMainTitle,
    Container,
    AnchorsContainer,
    AnchorLink,
    AnchorLinkMobile,
    RightContentContainer,
    ShoppingDetailsContentNext,
    LeftContentContainerMobile,
    MobileMenuNavbar,
    FixedHeightNavbar,
    AppDownloadInstructionsContainer,
    StrongBold,
    InstructionsText,
    ButtonAndroidStepTwo,
    CenterContainer,
    ImageThanksComponent,
    TextHelp,
    InputAndroidDevice,
    ButtonAndroidDevice,
    HorizontalCenterContainer,
    HorizontalCenterRowContainer,
    ThanksAndroidStepContainer,
} from './styles';
import { ReactComponent as MenuIcon } from '../../../assets/menu.svg';
import integrateQualtricsFeedbackButtonVisibility from '../../../utils/integrateQualtricsFeedbackButtonVisibility';
import Header from '../../templates/Header';
import useValidateInvitedEmail from '../../hooks/useValidateInvitedEmail';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../../templates/Loading';
import NotAuthorized from '../../templates/NotAuthorized';
import ReactGA from 'react-ga4';
import { dispatchMetric } from '../../../services/metrics.service';
import { FixedHeightBlock, FixedOneRemHeightBlock } from '../../templates/Header/styles';
import InstructionsSection from '../../custom/InstructionsSection';
import { postLogEmail } from '../../../services/auth.service';

const iosQRCode = require('../../../assets/ios_qr.png');
const imageThanks = require('../../../assets/download-thanks.png');
const shopImageIOS = require('../../../assets/ios-shop.png');

const AppDownload = () => {
    const { user, getAccessTokenSilently } = useAuth0();
    const uid = user && user['http://simplisafe.com/uid'];
    const [token, setToken] = useState('');
    const [iOSInstructionsCurrentStep, setIOSInstructionsCurrentStep] = useState(1);
    const [androidInstructionsCurrentStep, setAndroidInstructionsCurrentStep] = useState(1);
    const [androidEmailValue, setAndroidEmailValue] = useState('');
    const [iOSEmailValue, setiOSEmailValue] = useState('');
    const androidEmailInputRef = React.useRef<HTMLInputElement>(null);
    const iOSEmailInputRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        getAccessTokenSilently().then((value) => setToken(value));
    }, [getAccessTokenSilently]);

    const saveEmailAsync = async (email: string, iOSPlatform: boolean) => {
        const emailResponse = await postLogEmail({
            email: email,
            iOSPlatform: iOSPlatform,
            token,
        });
        const result = await emailResponse.json();
        if (result.success) {
            dispatchMetric({
                uid: uid,
                token,
                action: '@log_email_success',
            });
        }
    };

    const { isUserMailValid, isLoading } = useValidateInvitedEmail(user?.email || '');
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const toggleShowMobileMenu = (event: any) => {
        setShowMobileMenu((previous) => !previous);
        window.scrollTo(window.scrollX, window.scrollY - 1);
        window.scrollTo(window.scrollX, window.scrollY + 1);
    };
    const onIOSContinueFromStepOne = () => {
        if (iOSEmailInputRef.current?.validity.valid) {
            saveEmailAsync(iOSEmailValue, true);
            setIOSInstructionsCurrentStep(2);
        } else {
            iOSEmailInputRef.current?.reportValidity();
        }
    };
    const onIOSContinueFromStepTwo = () => {
        setIOSInstructionsCurrentStep(3);
    };
    const onAndroidContinueFromStepOne = () => {
        if (androidEmailInputRef.current?.validity.valid) {
            saveEmailAsync(androidEmailValue, false);
            setAndroidInstructionsCurrentStep(2);
        } else {
            androidEmailInputRef.current?.reportValidity();
        }
    };

    useEffect(() => {
        (window as any).userID = user?.email;
    }, [user?.email]);

    useEffect(() => {
        integrateQualtricsFeedbackButtonVisibility(true);
        if (uid && token) {
            ReactGA.send({ hitType: 'pageview', page: '/app-download' });
            dispatchMetric({
                action: '/app-download',
                uid: uid,
                token,
            });
        }
    }, [uid, token]);

    useEffect(() => {
        integrateQualtricsFeedbackButtonVisibility(true);
    }, []);

    if (isLoading) {
        return <Loading />;
    }
    if (!isUserMailValid) {
        return <NotAuthorized />;
    }

    return (
        <AppTemplate
            leftContent={
                <LeftContentContainerMobile onClick={toggleShowMobileMenu}>
                    <MenuIcon />
                </LeftContentContainerMobile>
            }
            cleanRightView={
                <RightContentContainer>
                    <AnchorsContainer>
                        <AnchorLink
                            onClick={(evt) => {
                                (window as any).window.qualtricsNotNow = 'false';
                                dispatchMetric({
                                    uid: uid,
                                    token,
                                    action: '#features',
                                });
                            }}
                            to="/#features"
                        >
                            Features
                        </AnchorLink>
                        <AnchorLink
                            onClick={(evt) => {
                                (window as any).window.qualtricsNotNow = 'false';
                                dispatchMetric({
                                    uid: uid,
                                    token,
                                    action: '#what-to-expect',
                                });
                            }}
                            to="/#what-to-expect"
                        >
                            What to expect
                        </AnchorLink>
                        <AnchorLink
                            onClick={(evt) => {
                                (window as any).window.qualtricsNotNow = 'false';
                                dispatchMetric({
                                    uid: uid,
                                    token,
                                    action: '#faq',
                                });
                            }}
                            to="/#faq"
                        >
                            FAQ
                        </AnchorLink>
                    </AnchorsContainer>
                </RightContentContainer>
            }
            rightContent={
                <RightContentContainer>
                    <AnchorsContainer>
                        <AnchorLink
                            onClick={(evt) => {
                                (window as any).window.qualtricsNotNow = 'false';
                                dispatchMetric({
                                    uid: uid,
                                    token,
                                    action: '#features',
                                });
                            }}
                            to="/#features"
                        >
                            Features
                        </AnchorLink>
                        <AnchorLink
                            onClick={(evt) => {
                                (window as any).window.qualtricsNotNow = 'false';
                                dispatchMetric({
                                    uid: uid,
                                    token,
                                    action: '#what-to-expect',
                                });
                            }}
                            to="/#what-to-expect"
                        >
                            What to expect
                        </AnchorLink>
                        <AnchorLink
                            onClick={(evt) => {
                                (window as any).window.qualtricsNotNow = 'false';
                                dispatchMetric({
                                    uid: uid,
                                    token,
                                    action: '#faq',
                                });
                            }}
                            to="/#faq"
                        >
                            FAQ
                        </AnchorLink>
                    </AnchorsContainer>
                </RightContentContainer>
            }
        >
            {!showMobileMenu ? null : (
                <>
                    <FixedHeightNavbar />
                    <Header containerStyle={{ height: 200 }}>
                        <MobileMenuNavbar>
                            <AnchorLinkMobile
                                to="/#features"
                                onClick={(evt) => {
                                    (window as any).window.qualtricsNotNow = 'false';
                                    toggleShowMobileMenu(null);
                                    dispatchMetric({
                                        uid: uid,
                                        token,
                                        action: '#features',
                                    });
                                }}
                            >
                                Features
                            </AnchorLinkMobile>
                            <AnchorLinkMobile
                                to="/#what-to-expect"
                                onClick={(evt) => {
                                    (window as any).window.qualtricsNotNow = 'false';
                                    toggleShowMobileMenu(null);
                                    dispatchMetric({
                                        uid: uid,
                                        token,
                                        action: '#what-to-expect',
                                    });
                                }}
                            >
                                What to expect
                            </AnchorLinkMobile>
                            <AnchorLinkMobile
                                to="/#faq"
                                onClick={(evt) => {
                                    (window as any).window.qualtricsNotNow = 'false';
                                    toggleShowMobileMenu(null);
                                    dispatchMetric({
                                        uid: uid,
                                        token,
                                        action: '#faq',
                                    });
                                }}
                            >
                                FAQ
                            </AnchorLinkMobile>
                        </MobileMenuNavbar>
                    </Header>
                </>
            )}
            <FixedHeightBlock />

            <Container>
                <FixedHeightBlock />
                <ImageThanksComponent src={imageThanks} />
                <FixedHeightBlock />
                <CenterContainer>
                    <ShoppingMainTitle>Download our mobile app to get started today!</ShoppingMainTitle>
                    <FixedOneRemHeightBlock />
                    <ShoppingDetailsContentNext>
                        Please follow these steps to download the Outdoor Monitoring Mobile App to activate
                        your service. Please have your mobile device handy. These instructions will also be
                        sent to your email.
                    </ShoppingDetailsContentNext>
                </CenterContainer>
                <FixedHeightBlock />
                <AppDownloadInstructionsContainer>
                    <InstructionsSection
                        image={
                            iOSInstructionsCurrentStep === 3
                                ? shopImageIOS
                                : iOSInstructionsCurrentStep === 2
                                ? iosQRCode
                                : null
                        }
                        imageWidth={iOSInstructionsCurrentStep === 2 ? 250 : 'auto'}
                        imageHeight={iOSInstructionsCurrentStep === 2 ? 250 : 'auto'}
                        title="Click here if you have an iOS device"
                    >
                        {iOSInstructionsCurrentStep === 1 ? (
                            <HorizontalCenterContainer>
                                <StrongBold>First, enter your iCloud email address.</StrongBold>

                                <div className="email-input-container">
                                    <InputAndroidDevice
                                        value={iOSEmailValue}
                                        onChange={(e) => setiOSEmailValue(e.target.value)}
                                        placeholder="enter iCloud account email"
                                        type='email'
                                        ref={iOSEmailInputRef}
                                        required
                                    />
                                    <ButtonAndroidDevice onClick={onIOSContinueFromStepOne}>
                                        Submit
                                    </ButtonAndroidDevice>
                                </div>
                            </HorizontalCenterContainer>
                        ) : iOSInstructionsCurrentStep === 2 ? (
                            <HorizontalCenterRowContainer>
                                <StrongBold>
                                    Next, you will need to download the free TestFlight app from Apple. This
                                    will allow you to download our Outdoor Monitoring iOS App.
                                </StrongBold>
                                <InstructionsText>
                                    From your phone, click this link or scan the QR Code to download
                                    TestFlight. You can also search for TestFlight in the App Store.
                                </InstructionsText>
                                <div>
                                    <ButtonAndroidStepTwo onClick={onIOSContinueFromStepTwo}>
                                        I have downloaded TestFlight
                                    </ButtonAndroidStepTwo>
                                </div>
                            </HorizontalCenterRowContainer>
                        ) : (
                            <HorizontalCenterRowContainer>
                                <StrongBold>Lastly, look out for an email from TestFlight.</StrongBold>
                                <br />
                                <InstructionsText>
                                    Click on the link in this email to begin downloading the Outdoor
                                    Monitoring Pre Crime Service App!
                                </InstructionsText>
                                <br />
                            </HorizontalCenterRowContainer>
                        )}
                    </InstructionsSection>
                    <InstructionsSection title="Click here if you have an Android device">
                        {androidInstructionsCurrentStep === 1 ? (
                            <HorizontalCenterContainer>
                                <StrongBold>First, enter your Google Account email address.</StrongBold>
                                <br />
                                <div className="email-input-container">
                                    <InputAndroidDevice
                                        value={androidEmailValue}
                                        onChange={(e) => setAndroidEmailValue(e.target.value)}
                                        placeholder="enter Google account email"
                                        type='email'
                                        ref={androidEmailInputRef}
                                        required
                                    />
                                    <ButtonAndroidDevice onClick={onAndroidContinueFromStepOne}>
                                        Submit
                                    </ButtonAndroidDevice>
                                </div>
                            </HorizontalCenterContainer>
                        ) : (
                            <ThanksAndroidStepContainer>
                                <StrongBold>
                                    Thanks! Further download details will be sent to your email within the
                                    next 24 hours.
                                </StrongBold>
                            </ThanksAndroidStepContainer>
                        )}
                    </InstructionsSection>
                </AppDownloadInstructionsContainer>
                <TextHelp>
                    Need Help? Email <a href="mailto:pcs@simplisafe.com">pcs@simplisafe.com</a>{' '}
                </TextHelp>
            </Container>

            <Footer />
        </AppTemplate>
    );
};

export default AppDownload;
